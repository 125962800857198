import React, { useEffect, useState } from "react";
import { ImCheckboxChecked } from "react-icons/im";
import { MdAdd, MdCheckBoxOutlineBlank, MdOutlineSchool } from "react-icons/md";
import SearchInput from "../Fragments/SearchInput";
import LoaderComponent from "../Loading/LoaderComponent";
import { SchoolsInterface, FC_GetAllSchools } from "../../actions";
import { BatchDefinitionInterface } from "../../containers/Assets/DistributionDashboard";
import BackButton from "../Fragments/BackButton";

interface AddSchoolsToDistributionProps {
  selectedDefinition: BatchDefinitionInterface | null;
  setSelectedDefinition: (selected: BatchDefinitionInterface) => void;
  batchDefinition: BatchDefinitionInterface[];
  setBatchDefinition: (updated: BatchDefinitionInterface[]) => void;
  setAddSchoolsWin: () => void;
}

export const AddSchoolsToDistribution: React.FC<
  AddSchoolsToDistributionProps
> = (Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [allSchools, setAllSchools] = useState<SchoolsInterface[]>([]);
  const [filteredSchools, setFilteredSchools] = useState<SchoolsInterface[]>(
    []
  );
  const [searchSchool, setSearchSchool] = useState<string>("");
  const [selectedSchools, setSelectedSchools] = useState<SchoolsInterface[]>(
    []
  );

  const getAllSchools = () => {
    FC_GetAllSchools((loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setAllSchools(res.data);
        setFilteredSchools(res.data);
      } else {
        return;
      }
    });
  };

  const AddSchoolsDefinition = () => {
    const updatedDefinition = Props.batchDefinition.map((itm) => {
      if (itm.definition_id === Props.selectedDefinition?.definition_id) {
        const updatedDef: BatchDefinitionInterface = {
          ...itm,
          schools: selectedSchools,
        };
        Props.setSelectedDefinition(updatedDef);
        return updatedDef;
      } else {
        return itm;
      }
    });

    Props.setBatchDefinition(updatedDefinition);
  };

  useEffect(() => {
    if (!Props.selectedDefinition) return;
    setSelectedSchools(Props.selectedDefinition.schools);
    if (allSchools.length === 0) {
      getAllSchools();
    } else {
      setFilteredSchools(allSchools);
    }
  }, [Props.selectedDefinition, allSchools]);

  return (
    <div className="pt-2 animate__animated animate__fadeInRight animate__faster">
      <div className="bg-white rounded-xl w-full">
        <div className="flex flex-row items-center gap-2 border-b pb-3 px-3 pt-2">
          <BackButton
            title="Back"
            onClick={() => Props.setAddSchoolsWin()}
            className="bg-primary-50 hover:bg-primary-100 text-primary-800 text-sm rounded-lg"
          />
          <div>
            <MdOutlineSchool className="text-2xl text-primary-700" />
          </div>
          <div className="text-lg font-bold">Select Schools</div>
        </div>

        <div className="flex flex-col p-4 gap-4">
          <div className="flex flex-col bg-primary-100 rounded-lg p-4">
            <h2 className="font-bold">selected definition</h2>
            <div className="rounded-lg flex flex-row items-center justify-between gap-4 w-full p-2 relative bg-white shadow-sm">
              <ul
                className={`gap-4 justify-between flex flex-row items-center cursor-pointer rounded-lg`}
              >
                <li className="truncate w-max text-base text-black">
                  {
                    Props.selectedDefinition?.selectedCategory
                      ?.assets_categories_name
                  }
                </li>
                <li className="truncate w-max text-base text-black">
                  {Props.selectedDefinition?.selectedSubCategory?.name}
                </li>
                <li className="truncate w-max text-base text-black">
                  {Props.selectedDefinition?.selectedBrand?.name}
                </li>
                {Props.selectedDefinition?.selectedAttributes.map((itm, i) => (
                  <li className="truncate w-max text-base text-black" key={i}>
                    <label className="text-gray-400 text-sm">{itm.name}-</label>
                    {itm.selected}
                  </li>
                ))}
              </ul>
              <span className="font-bold truncate w-max text-base text-white bg-primary-700 py-1 px-4 rounded-lg">
                0 of {Props.selectedDefinition?.distributionLimit}
              </span>
            </div>
          </div>
          <div className="w-full h-20 " />
          <div className="flex flex-row h-96 gap-4 w-full">
            <div className="flex flex-col border bg-white border-primary-400 shadow-sm w-1/2 p-2 h-full rounded-xl">
              {loading && <LoaderComponent />}
              {filteredSchools.length > 0 && (
                <>
                  <SearchInput
                    searchData={searchSchool}
                    onChange={(value: string) => {
                      setSearchSchool(value);
                      setFilteredSchools(
                        allSchools.filter((school) =>
                          school.school_name
                            .toLowerCase()
                            .includes(value.toLowerCase())
                        )
                      );
                    }}
                  />
                  <ul className="mt-2 flex flex-col gap-2 overflow-scroll">
                    {filteredSchools.map((school, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          selectedSchools.includes(school)
                            ? setSelectedSchools(
                                selectedSchools.filter((sch) => sch !== school)
                              )
                            : setSelectedSchools([...selectedSchools, school]);
                        }}
                        className="flex flex-row items-center gap-2 py-1 px-2 border border-gray-100 bg-white cursor-pointer shadow-sm rounded-lg"
                      >
                        {selectedSchools.includes(school) ? (
                          <ImCheckboxChecked
                            size={28}
                            className="animate__animated animate__bounceIn animate__faster font-thin cursor-pointer text-primary-700"
                          />
                        ) : (
                          <MdCheckBoxOutlineBlank
                            size={28}
                            className="font-thin cursor-pointer"
                          />
                        )}

                        <div className="flex flex-col items-start gap-1">
                          <span className="text-black text-sm">
                            {school.school_name}
                          </span>
                          <span className="text-xs text-primary-800">
                            {school.school_category}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
            <div className="flex flex-col border p-2 border-primary-400 bg-primary-50 w-1/2 h-full rounded-xl">
              <div className="flex flex-row items-center justify-between p-2">
                <h3 className="font-bold">Selected Schools</h3>
                <span className="flex flex-col items-center">
                  <span className="text-xs">Total selected</span>
                  <span className="text-lg font-bold">
                    {selectedSchools.length}
                  </span>
                </span>
              </div>
              <ul className="mt-2 flex flex-col gap-2 overflow-scroll">
                {selectedSchools.length > 0 &&
                  selectedSchools.map((school, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        setSelectedSchools(
                          selectedSchools.filter((sch) => sch !== school)
                        );
                      }}
                      className="animate__animated animate__fadeIn animate__faster  flex flex-row items-center gap-2 py-1 px-2 border border-gray-200 bg-white shadow-sm cursor-pointer rounded-lg"
                    >
                      <ImCheckboxChecked
                        size={28}
                        className="font-thin cursor-pointer text-primary-700"
                      />
                      <div className="flex flex-col items-start gap-1">
                        <span className="text-black text-sm">
                          {school.school_name}
                        </span>
                        <span className="text-xs text-primary-800">
                          {school.school_category}
                        </span>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div
            onClick={() => {
              Props.setAddSchoolsWin();
              AddSchoolsDefinition();
            }}
            className="h-14 w-full bg-primary-700 items-center text-white flex flex-row justify-center rounded-lg hover:bg-primary-800"
          >
            <MdAdd className="text-xl text-white" />
            <span>Add Schools</span>
          </div>
        </div>
      </div>
    </div>
  );
};
