import { Dispatch } from "redux";
import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";
import { errorToText } from "../utils/functions";
import { GenderEnum, UserItemInterface } from "./auth.action";

export interface GetUserInterface {
  current_page: string;
  total_pages: number;
  users: UserItemInterface[];
}

export const FC_GetAllUsersByRole = (
  role_id: string,
  page: number,
  limit: number,
  callback: (
    loading: boolean,
    res: {
      data: GetUserInterface | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<GetUserInterface>(
        `${API_URL}/user/list/${page}/${role_id}`
      );

      console.log({ usersList: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface UserInfoData {
  role_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  phone_numbers: string;
  email: string;
  username: string;
  nid: string;
  gender: GenderEnum;
  full_name: string;
  resident_district_id: string;
}

export const FC_RegisterUserAccount = (
  data: UserInfoData,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      user_id: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(`${API_URL}/user/account/create`, data);

      console.log({ usersList: res.data });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "User Account Registered Successfully!",
      });
      callback(false, {
        msg: "User Account Registered Successfully!",
        type: "SUCCESS",
        user_id: res.data.user_id,
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        user_id: "",
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface UserInfoDataFoundInterface {
  trainers_id: string;
  staff_code: string;
  staff_category_id: null | number;
  full_name: string;
  sex: GenderEnum;
  dob: null | string;
  marital_status: null | string;
  nid: string;
  email: string;
  phone_numbers: string;
  rssb_number: null | string;
  education_domain_id: null | number;
  education_sub_dommain_id: null | number;
  graduation_date: null | string;
  hired_date: null | string;
  contract_type: null | string;
  bank_account: null | string;
  nationality_id: string;
  province_code: null | string;
  district_code: null | string;
  sector_code: null | string;
  cell_code: null | string;
  village_id: null | string;
  village: null | string;
  school_code: null | string;
  school_name: null | string;
  user_id: string;
  username: string;
  password_verication_code: null | string;
  bank_id: null | string;
  disability: "true" | "false";
  resident_district_id: string;
  resident_district_name: null | string;
  disability_surpoting_document: null | string;
  resident_surpoting_document: null | string;
  photo_url: null | string;
  created_by: string;
  created_at: string;
  updated_by: null | string;
  updated_at: string;
  archive: "0" | "1";
  archive_by: null | string;
  archive_at: null | string;
  status: "1" | "0";
  first_name: string;
  middle_name: string;
  last_name: string;
  specialization_id: null | number;
  village_code: null | string;
  others: null | string;
  role_id: string;
  position_code: string | null;
  position_name: string | null;
  qualification_id: string | null;
  qualification_name: string | null;
}

export const FC_FindUserInfo = async (
  searchData: string, //Phone, Email, NID
  callBack: (
    loading: boolean,
    res: {
      data: UserInfoDataFoundInterface | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(
      `${API_URL}/user/account/profile/${searchData}`
    );
    if (res.data.length > 0) {
      callBack(false, {
        data: res.data[0],
        msg: "User found",
        type: "SUCCESS",
      });
    } else {
      callBack(false, {
        data: null,
        msg: "User is not found! Please register as new",
        type: "ERROR",
      });
    }
  } catch (error: any) {
    callBack(false, {
      data: null,
      msg: errorToText(error),
      type: "ERROR",
    });
  }
};

export interface AssignUserRoleData {
  role_id: string;
  school_code: string;
  qualification_id: string;
  district_code: string | null;
  sector_code: string | null;
  academic_year_id: string | null;
  start_date_in_the_school: string | null;
  position_code: string;
}

export const FC_AssignUserToRole = async (
  user_id: string,
  data: AssignUserRoleData,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.patch(
      `${API_URL}/user/account/assignaccess/${user_id}`,
      data
    );

    console.log({ schoolsList: res.data });
    callback(false, {
      msg: "School assigned successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_AssignUserToSubjects = async (
  data: {
    staff_code: string;
    study_hierarchy_id: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.post(`${API_URL}/studyhierarchy`, data);

    console.log({ assignSubject: res.data });
    callback(false, {
      msg: "Subject assigned successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export enum UserRoleStatus {
  TRANSFER = "TRANSFERD",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
  New = "New",
  Active = "Active",
  Disabled = "Disabled",
  Upgraded = "Upgraded",
}

export const FC_ChangeUserRoleStatus = async (
  data: {
    user_id: string;
    current_status: UserRoleStatus;
    new_status: UserRoleStatus;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.patch(
      `${API_URL}/user/account/status/${data.user_id}`,
      data
    );

    console.log({ changeRoleStatus: res.data });
    callback(false, {
      msg: "User status updated successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};
