import React, { Component } from "react";
import {
  FC_UpdateGeneralReportMarks,
  ProgressReportInterface,
} from "../../actions";
import Input from "../Fragments/Input";
import { MdEdit } from "react-icons/md";
import Alert, { AlertType } from "../Alert/Alert";
import { GeneralReportTypeEnum } from "../ElearningReport/ElearningReport";

interface UpdateGeneralReportMarksProps {
  data: {
    key: GeneralReportTypeEnum;
    value: string;
    data: ProgressReportInterface;
  };
  onBack: () => void;
  onSuccess: () => void;
}
interface UpdateGeneralReportMarksState {
  dataToEdit: string;
  loading: boolean;
  success: string;
  error: string;
}

export class UpdateGeneralReportMarks extends Component<
  UpdateGeneralReportMarksProps,
  UpdateGeneralReportMarksState
> {
  constructor(props: UpdateGeneralReportMarksProps) {
    super(props);
    this.state = {
      dataToEdit: this.returnKeyDetails(),
      error: "",
      loading: false,
      success: "",
    };
  }
  returnKeyDetails = () => {
    switch (this.props.data.key) {
      case GeneralReportTypeEnum.selfStudy:
        return this.props.data.data.selfAssesment;
      case GeneralReportTypeEnum.classroomApplication:
        return this.props.data.data.classroomApplication;
      case GeneralReportTypeEnum.coaching:
        return this.props.data.data.coaching;
      case GeneralReportTypeEnum.copMarks:
        return this.props.data.data.copMarks;
      case GeneralReportTypeEnum.reflectionNotes:
        return this.props.data.data.reflectionNotes;
      case GeneralReportTypeEnum.courseNavigation:
        return this.props.data.data.courseNavigation;
      case GeneralReportTypeEnum.selfAssesment:
        return this.props.data.data.selfAssesment;
      case GeneralReportTypeEnum.endOfCourse:
        return this.props.data.data.endOfCourse;
      case GeneralReportTypeEnum.endOfChapter:
        return this.props.data.data.endOfChapter;
      case GeneralReportTypeEnum.endOfModule:
        return this.props.data.data.endOfModule;
      default:
        return "0";
    }
  };
  returnTypeName = (key: GeneralReportTypeEnum) => {
    switch (key) {
      case GeneralReportTypeEnum.selfStudy:
        return "Self Study";
      case GeneralReportTypeEnum.classroomApplication:
        return "Classroom Application";
      case GeneralReportTypeEnum.coaching:
        return "Coaching";
      case GeneralReportTypeEnum.copMarks:
        return "CoP Marks";
      case GeneralReportTypeEnum.reflectionNotes:
        return "Reflection Notes";
      case GeneralReportTypeEnum.courseNavigation:
        return "Course Navigation";
      case GeneralReportTypeEnum.selfAssesment:
        return "Self Assessment";
      case GeneralReportTypeEnum.endOfCourse:
        return "End of Course";
      case GeneralReportTypeEnum.endOfChapter:
        return "End of Chapter";
      case GeneralReportTypeEnum.endOfModule:
        return "End of Module";
      case GeneralReportTypeEnum.teacherPractices:
        return "Teacher Practice";
    }
    return "";
  };
  returnTypeValue = (
    selectedKey: GeneralReportTypeEnum,
    selectedValue: string
  ) => {
    return this.props.data.key === selectedKey
      ? this.state.dataToEdit
      : selectedValue;
  };
  onSubmitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.loading === false) {
      if (this.state.dataToEdit === "") {
        return this.setState({ error: "Please fill marks" });
      }
      if (isNaN(parseFloat(this.state.dataToEdit))) {
        return this.setState({
          error: "Please fill numeric value",
        });
      }
      // action
      this.setState({ loading: true });
      FC_UpdateGeneralReportMarks(
        this.props.data.data.generalReportId,
        {
          chapterId: this.props.data.data.chapterId,
          classroomApplication: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.classroomApplication,
              this.props.data.data.classroomApplication
            )
          ),
          coaching: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.coaching,
              this.props.data.data.coaching
            )
          ),
          cohortId: this.props.data.data.cohortId,
          copMarks: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.copMarks,
              this.props.data.data.copMarks
            )
          ),
          courseNavigation: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.courseNavigation,
              this.props.data.data.courseNavigation
            )
          ),
          endOfChapter: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.endOfChapter,
              this.props.data.data.endOfChapter
            )
          ),
          endOfCourse: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.endOfCourse,
              this.props.data.data.endOfCourse
            )
          ),
          endOfModule: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.endOfModule,
              this.props.data.data.endOfModule
            )
          ),
          moduleId: this.props.data.data.moduleId,
          reflectionNotes: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.reflectionNotes,
              this.props.data.data.reflectionNotes
            )
          ),
          selfAssesment: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.selfAssesment,
              this.props.data.data.selfAssesment
            )
          ),
          selfStudy: parseFloat(
            this.returnTypeValue(
              GeneralReportTypeEnum.selfStudy,
              this.props.data.data.selfStudy
            )
          ),
          staff_code: this.props.data.data.staff_code,
        },
        (
          loading: boolean,
          res: {
            msg: string;
            type: "SUCCESS" | "ERROR";
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({ error: "", success: res.msg, loading: true });
            setTimeout(() => {
              this.props.onSuccess();
            }, 900);
          }
          if (res?.type === "ERROR") {
            this.setState({ error: res.msg, loading: false, success: "" });
          }
        }
      );
    }
  };
  componentDidMount(): void {}
  render() {
    return (
      <form onSubmit={this.onSubmitData}>
        <div className="font-bold text-primary-800 mb-3 uppercase">
          {this.returnTypeName(this.props.data.key)} OF{" "}
          {this.props.data.data.chapterName}
        </div>
        <Input
          type="text"
          title=""
          value={this.state.dataToEdit}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            this.setState({ dataToEdit: e.target.value })
          }
          disabled={this.state.loading}
          error={this.state.error}
        />
        <div className="pt-3">
          {this.state.success !== "" && (
            <div className="py-2">
              <Alert
                alertType={AlertType.SUCCESS}
                title={this.state.success}
                close={() => this.setState({ error: "", success: "" })}
              />
            </div>
          )}
          <button
            type="submit"
            disabled={this.state.loading}
            className="flex flex-row items-center justify-center gap-2 rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer px-3 py-2 text-sm"
          >
            <div>{<MdEdit className="text-xl" />}</div>
            {this.state.loading === false ? (
              <span className="truncate">Update Marks</span>
            ) : (
              <span className="animate-pulse italic">Saving changes</span>
            )}
          </button>
        </div>
      </form>
    );
  }
}
