import React, { Component, Fragment } from "react";
import { NoResultFound } from "../Fragments/NoResultFound";
import {
  DistrictLocationItem,
  FC_AssignUserToRole,
  FC_GetSchoolsList,
  LocationAPI,
  PositionInterface,
  SchoolInterface,
  SectorLocationItem,
  USER_QUALIFICATIONS,
} from "../../actions";
import LoaderComponent from "../Loading/LoaderComponent";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import SelectCustom from "../SelectCustom/SelectCustom";
import {
  MdClose,
  MdOutlineEdit,
  MdOutlineTransferWithinAStation,
} from "react-icons/md";
import { SCHOOL_LEVEL_ROLES } from "./RegisterUserAccountPage";
import Alert, { AlertType } from "../Alert/Alert";

interface UserSchoolInfoProps {
  selectedSchool: { school_code: string; school_name: string } | null;
  user_id: string;
  locations: LocationAPI | null;
  selected_user_role_id: string;
  selectedUserQualification: {
    qualification_id: string;
    qualification_name: string;
  } | null;
  FC_GetAllPositions: (callback: (loading: boolean) => void) => void;
  positions: PositionInterface[] | null;
  selectedUserPosition: { position_code: string; position_name: string } | null;
  isTransfer: boolean;
  onClose: () => void;
  onSuccess: () => void;
}
interface UserSchoolInfoState {
  loading: boolean;
  openChooseDistrict: boolean;
  selectedDistrict: DistrictLocationItem | null;
  openChooseSector: boolean;
  selectedSector: SectorLocationItem | null;
  selectedSchool: { school_code: string; school_name: string } | null;
  schoolsList: SchoolInterface[] | null;
  error: string;
  loadingSchools: boolean;
  openChooseSchool: boolean;
  success: string;
  selectedQualification: {
    qualification_id: string;
    qualification_name: string;
  } | null;
  openSelectQualification: boolean;
  openSelectPosition: boolean;
  selectedPosition: { position_code: string; position_name: string } | null;
  openEditSchoolContainer: boolean;
}

export class UserSchoolInfo extends Component<
  UserSchoolInfoProps,
  UserSchoolInfoState
> {
  constructor(props: UserSchoolInfoProps) {
    super(props);
    this.state = {
      loading: false,
      openChooseDistrict: false,
      selectedDistrict: null,
      openChooseSector: false,
      selectedSector: null,
      selectedSchool: this.props.selectedSchool,
      schoolsList: null,
      error: "",
      loadingSchools: false,
      openChooseSchool: false,
      success: "",
      selectedQualification: this.props.selectedUserQualification,
      openSelectQualification: false,
      selectedPosition:
        this.props.selectedUserPosition === null
          ? null
          : this.props.selectedUserPosition.position_code === null ||
            this.props.selectedUserPosition.position_code === "null"
          ? null
          : this.props.selectedUserPosition,
      openSelectPosition: false,
      openEditSchoolContainer: this.props.isTransfer,
    };
  }

  getSchoolsByDistrict = (district_code: string) => {
    this.setState({ loadingSchools: true });
    FC_GetSchoolsList(
      {
        province_code: district_code.slice(0, 1),
        district_code: district_code,
        sector_code: null,
        cell_code: null,
      },
      (
        loading: boolean,
        res: {
          msg: string;
          type: "SUCCESS" | "ERROR";
          data: SchoolInterface[];
        } | null
      ) => {
        this.setState({ loadingSchools: loading });
        if (res?.type === "SUCCESS") {
          this.setState({
            schoolsList: res.data,
            loadingSchools: false,
            error: "",
          });
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: res.msg,
            schoolsList: null,
            loadingSchools: false,
          });
        }
      }
    );
  };
  onAssignUserToSchool = () => {
    if (this.state.selectedQualification === null) {
      return this.setState({ error: "Please choose qualification" });
    }
    if (this.state.selectedPosition === null) {
      return this.setState({ error: "Please choose position" });
    }
    if (this.state.selectedSchool !== null) {
      this.setState({ loading: true });
      FC_AssignUserToRole(
        this.props.user_id,
        {
          academic_year_id: null,
          district_code: null,
          qualification_id: this.state.selectedQualification.qualification_id,
          role_id: this.props.selected_user_role_id,
          school_code: this.state.selectedSchool.school_code,
          sector_code: null,
          start_date_in_the_school: null,
          position_code: this.state.selectedPosition.position_code,
        },
        (
          loading: boolean,
          res: {
            msg: string;
            type: "SUCCESS" | "ERROR";
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({ success: res.msg, loading: false, error: "" });
            if (this.props.isTransfer === true) {
              this.setState({ loading: true });
              setTimeout(() => {
                this.props.onSuccess();
              }, 1000);
            }
          }
          if (res?.type === "ERROR") {
            this.setState({ loading: false, success: "", error: res.msg });
          }
        }
      );
    }
  };

  getPositions = () => {
    if (this.props.positions === null) {
      this.setState({ loading: true });
      this.props.FC_GetAllPositions((loading: boolean) =>
        this.setState({ loading: loading })
      );
    }
  };

  componentDidMount(): void {
    if (this.props.selectedSchool !== null && this.props.locations !== null) {
      const selectedDistrict = this.props.locations.districts.find(
        (itm) =>
          itm.district_code.toString() ===
          this.props.selectedSchool?.school_code.toString().slice(0, 2)
      );
      const selectedSector = this.props.locations.sectors.find(
        (itm) =>
          itm.sector_code.toString() ===
          this.props.selectedSchool?.school_code.toString().slice(0, 4)
      );
      if (selectedDistrict !== undefined) {
        this.setState({ selectedDistrict: selectedDistrict });
      }
      if (selectedSector !== undefined) {
        this.setState({ selectedSector: selectedSector });
      }
    }
    if (this.props.selectedSchool !== null) {
      this.getSchoolsByDistrict(
        this.props.selectedSchool.school_code.toString().slice(0, 2)
      );
    }
    if (this.props.selectedSchool === null) {
      if (
        SCHOOL_LEVEL_ROLES.find(
          (itm) =>
            itm.toString() === this.props.selected_user_role_id.toString()
        ) !== undefined
      ) {
        this.setState({ openChooseDistrict: true });
      }
    }
    this.getPositions();
  }

  getPositionName = (position_code: string) => {
    const selected = this.props.positions?.find(
      (itm) => itm.position_code.toString() === position_code.toString()
    );
    return selected ?? null;
  };

  render() {
    return (
      <Fragment>
        <div className="p-3 pt-2">
          <div
            className={`${
              this.props.selectedSchool === null
                ? "bg-gray-100"
                : "bg-primary-50"
            } rounded-md p-3`}
          >
            <div
              className={`flex flex-row items-start justify-between w-full ${
                this.props.isTransfer === true ? "gap-32" : "gap-2"
              }`}
            >
              <div
                className={`${
                  this.props.isTransfer === true
                    ? "font-light -mt-1"
                    : "font-bold"
                } mb-2 text-sm`}
              >
                {this.props.isTransfer === true && (
                  <div className="text-lg font-bold text-primary-800">
                    School Transfer
                  </div>
                )}
                <div
                  className={
                    this.state.openEditSchoolContainer === true ? "" : ""
                  }
                >
                  Current School{" "}
                  {this.props.selectedSchool !== null ? (
                    <span className="text-accent-900">{`(${this.props.selectedSchool?.school_code} - 
            ${this.props.selectedSchool?.school_name})`}</span>
                  ) : (
                    this.state.openEditSchoolContainer === false && (
                      <div className="text-xs text-gray-600 font-light -mb-2">
                        No details found
                      </div>
                    )
                  )}
                </div>
              </div>
              <div>
                {this.props.isTransfer === false ? (
                  <div
                    onClick={() =>
                      this.setState({
                        openEditSchoolContainer:
                          !this.state.openEditSchoolContainer,
                      })
                    }
                    className="flex items-center justify-center h-8 w-8 rounded-full bg-white border border-accent-800 hover:bg-primary-100 hover:border-primary-700"
                  >
                    {this.state.openEditSchoolContainer === false ? (
                      <IoIosArrowDown className="text-lg cursor-pointer hover:text-primary-800" />
                    ) : (
                      <IoIosArrowUp className="text-lg cursor-pointer hover:text-primary-800" />
                    )}
                  </div>
                ) : (
                  <div
                    onClick={this.props.onClose}
                    className="flex items-center justify-center h-9 w-9 rounded-full bg-white border border-red-500 hover:bg-red-50 hover:text-red-700 cursor-pointer"
                  >
                    <MdClose className="text-xl" />
                  </div>
                )}
              </div>
            </div>
            {this.state.openEditSchoolContainer === true && (
              <div className="pt-3">
                {this.props.selectedSchool === null &&
                  this.state.selectedSchool === null && (
                    <div className="bg-gray-100 text-accent-900 rounded-md px-3 py-2 -mt-2">
                      No school info found, please add user school
                    </div>
                  )}
                {this.props.user_id === "" ? (
                  <NoResultFound
                    title="No school info found"
                    description="There are no info about user school information"
                  />
                ) : (
                  <div>
                    {this.props.locations === null ? (
                      <div>
                        <LoaderComponent />
                      </div>
                    ) : (
                      <div>
                        <div className="flex flex-row items-center justify-between gap-2">
                          {/* District Component */}
                          <div className="w-full relative mb-3">
                            <div
                              onClick={() =>
                                this.setState({
                                  openChooseDistrict:
                                    !this.state.openChooseDistrict,
                                  openChooseSector: false,
                                  openChooseSchool: false,
                                  openSelectQualification: false,
                                  openSelectPosition: false,
                                })
                              }
                              className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                            >
                              {this.state.selectedDistrict === null ? (
                                <span>Choose District</span>
                              ) : (
                                <span className="text-primary-800 group-hover:text-black">
                                  {this.state.selectedDistrict.district_name}
                                </span>
                              )}
                              <div>
                                <IoIosArrowDown className="text-lg" />
                              </div>
                            </div>
                            {this.state.openChooseDistrict === true && (
                              <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                                <SelectCustom
                                  loading={this.state.loading}
                                  id={"district_code"}
                                  title={"district_name"}
                                  close={() =>
                                    this.setState({ openChooseDistrict: false })
                                  }
                                  data={
                                    this.props.locations === null
                                      ? []
                                      : this.props.locations.districts
                                  }
                                  click={(data: DistrictLocationItem) => {
                                    this.setState({
                                      selectedDistrict: data,
                                      openChooseDistrict: false,
                                      selectedSector: null,
                                      selectedSchool: null,
                                    });
                                    this.getSchoolsByDistrict(
                                      data.district_code
                                    );
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          {/* ----------------- */}
                          {/* Sector Component */}
                          {this.state.selectedDistrict !== null && (
                            <div className="w-full relative mb-3">
                              <div
                                onClick={() =>
                                  this.setState({
                                    openChooseSector:
                                      !this.state.openChooseSector,
                                    openChooseDistrict: false,
                                    openChooseSchool: false,
                                    openSelectQualification: false,
                                    openSelectPosition: false,
                                  })
                                }
                                className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                              >
                                {this.state.selectedSector === null ? (
                                  <span>Choose Sector</span>
                                ) : (
                                  <span className="text-primary-800 group-hover:text-black">
                                    {this.state.selectedSector.sector_name}
                                  </span>
                                )}
                                <div>
                                  <IoIosArrowDown className="text-lg" />
                                </div>
                              </div>
                              {this.state.openChooseSector === true && (
                                <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                                  <SelectCustom
                                    loading={this.state.loading}
                                    id={"sector_code"}
                                    title={"sector_name"}
                                    close={() =>
                                      this.setState({ openChooseSector: false })
                                    }
                                    data={
                                      this.props.locations === null
                                        ? []
                                        : this.props.locations.sectors.filter(
                                            (itm) =>
                                              this.state.selectedDistrict !==
                                                null &&
                                              itm.sector_code
                                                .toString()
                                                .slice(0, 2) ===
                                                this.state.selectedDistrict.district_code.toString()
                                          )
                                    }
                                    click={(data: SectorLocationItem) => {
                                      this.setState({
                                        selectedSector: data,
                                        openChooseSector: false,
                                        selectedSchool: null,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                        {/* ----------------- */}
                        {/* School Component - Continue here */}
                        {this.state.selectedDistrict !== null && (
                          <div className="w-full relative mb-3">
                            <div
                              onClick={() =>
                                this.setState({
                                  openChooseSchool:
                                    !this.state.openChooseSchool,
                                  openChooseDistrict: false,
                                  openChooseSector: false,
                                  openSelectQualification: false,
                                  openSelectPosition: false,
                                })
                              }
                              className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                            >
                              {this.state.selectedSchool === null ? (
                                <span>Choose School</span>
                              ) : (
                                <span className="text-primary-800 group-hover:text-black">
                                  {this.state.selectedSchool.school_name}
                                </span>
                              )}
                              <div>
                                <IoIosArrowDown className="text-lg" />
                              </div>
                            </div>
                            {this.state.openChooseSchool === true && (
                              <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                                <SelectCustom
                                  loading={this.state.loading}
                                  id={"school_code"}
                                  title={"school_name"}
                                  close={() =>
                                    this.setState({ openChooseSchool: false })
                                  }
                                  data={
                                    this.state.schoolsList === null
                                      ? []
                                      : this.state.schoolsList.filter(
                                          (itm) =>
                                            this.state.selectedSector ===
                                              null ||
                                            itm.school_code
                                              .toString()
                                              .slice(0, 4) ===
                                              this.state.selectedSector.sector_code.toString()
                                        )
                                  }
                                  click={(data: SchoolInterface) => {
                                    this.setState({
                                      selectedSchool: data,
                                      openChooseSchool: false,
                                      openChooseDistrict: false,
                                    });
                                    if (this.props.locations !== null) {
                                      const selectedSector =
                                        this.props.locations.sectors.find(
                                          (itm) =>
                                            itm.sector_code.toString() ===
                                            data.school_code
                                              .toString()
                                              .slice(0, 4)
                                        );
                                      if (selectedSector !== undefined) {
                                        this.setState({
                                          selectedSector: selectedSector,
                                        });
                                      }
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        )}

                        {this.state.selectedSchool !== null && (
                          <div className="w-full relative mb-3">
                            <div
                              onClick={() =>
                                this.setState({
                                  openSelectQualification:
                                    !this.state.openSelectQualification,
                                  openChooseDistrict: false,
                                  openChooseSector: false,
                                  openChooseSchool: false,
                                  openSelectPosition: false,
                                })
                              }
                              className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                            >
                              {this.state.selectedQualification === null ? (
                                <span>Choose Qualification</span>
                              ) : (
                                <span className="text-primary-800 group-hover:text-black">
                                  {
                                    this.state.selectedQualification
                                      .qualification_name
                                  }
                                </span>
                              )}
                              <div>
                                <IoIosArrowDown className="text-lg" />
                              </div>
                            </div>
                            {this.state.openSelectQualification === true && (
                              <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                                <SelectCustom
                                  loading={this.state.loading}
                                  id={"qualification_id"}
                                  title={"qualification_name"}
                                  close={() =>
                                    this.setState({
                                      openSelectQualification: false,
                                    })
                                  }
                                  data={USER_QUALIFICATIONS}
                                  click={(data: {
                                    qualification_id: string;
                                    qualification_name: string;
                                  }) =>
                                    this.setState({
                                      selectedQualification: data,
                                      openSelectQualification: false,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.selectedSchool !== null && (
                          <div className="w-full relative mb-3">
                            <div
                              onClick={() =>
                                this.setState({
                                  openSelectPosition:
                                    !this.state.openSelectPosition,
                                  openChooseDistrict: false,
                                  openChooseSector: false,
                                  openChooseSchool: false,
                                  openSelectQualification: false,
                                })
                              }
                              className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                            >
                              {this.state.selectedPosition === null ? (
                                <span>Choose Position</span>
                              ) : (
                                <span className="text-primary-800 group-hover:text-black">
                                  {
                                    this.getPositionName(
                                      this.state.selectedPosition.position_code
                                    )?.position_name
                                  }
                                </span>
                              )}
                              <div>
                                <IoIosArrowDown className="text-lg" />
                              </div>
                            </div>
                            {this.state.openSelectPosition === true && (
                              <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                                <SelectCustom
                                  loading={this.state.loading}
                                  id={"position_code"}
                                  title={"position_name"}
                                  close={() =>
                                    this.setState({ openSelectPosition: false })
                                  }
                                  data={
                                    this.props.positions
                                      ?.filter(
                                        (itm) =>
                                          this.state.selectedQualification ===
                                            null ||
                                          itm.qualification_id.toString() ===
                                            this.state.selectedQualification.qualification_id.toString()
                                      )
                                      .map((itm) => ({
                                        ...itm,
                                        position_name: `${itm.position_name} (${itm.qualification_name})`,
                                      })) ?? []
                                  }
                                  click={(data: {
                                    position_code: string;
                                    position_name: string;
                                  }) =>
                                    this.setState({
                                      selectedPosition: data,
                                      openSelectPosition: false,
                                    })
                                  }
                                />
                              </div>
                            )}
                          </div>
                        )}
                        {this.state.success !== "" && (
                          <div className="py-2">
                            <Alert
                              alertType={AlertType.SUCCESS}
                              title={"Action Succeeded"}
                              description={this.state.success}
                              close={() =>
                                this.setState({ success: "", error: "" })
                              }
                            />
                          </div>
                        )}
                        {this.state.error !== "" && (
                          <div className="py-2">
                            <Alert
                              alertType={AlertType.DANGER}
                              title={"Action Failed"}
                              description={this.state.error}
                              close={() =>
                                this.setState({ success: "", error: "" })
                              }
                            />
                          </div>
                        )}
                        {/* ----------------- */}
                        {this.state.selectedSchool !== null &&
                          this.state.selectedQualification !== null &&
                          this.state.selectedPosition !== null &&
                          (this.props.selectedSchool?.school_code !==
                            this.state.selectedSchool.school_code ||
                            this.state.selectedQualification?.qualification_id.toString() !==
                              this.props.selectedUserQualification?.qualification_id.toString() ||
                            this.state.selectedPosition.position_code !==
                              this.props.selectedUserPosition
                                ?.position_code) && (
                            <div className="">
                              <div
                                onClick={() => this.onAssignUserToSchool()}
                                className="flex flex-row items-center justify-center text-sm hover:text-white gap-2 text-accent-900 bg-white px-3 py-2 w-max rounded-md white border border-accent-800 hover:border-white hover:bg-primary-700 cursor-pointer"
                              >
                                {console.log(
                                  "SelectedPosition: ",
                                  this.state.selectedPosition
                                )}
                                <div>
                                  {this.props.selectedSchool?.school_code !==
                                  this.state.selectedSchool.school_code ? (
                                    <MdOutlineTransferWithinAStation className="text-lg" />
                                  ) : (
                                    <MdOutlineEdit className="text-lg" />
                                  )}
                                </div>
                                {this.props.selectedSchool?.school_code !==
                                this.state.selectedSchool.school_code ? (
                                  <span>
                                    Transfer to{" "}
                                    <span className="font-bold">
                                      {this.state.selectedSchool.school_name}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="font-light">
                                    Update User /{" "}
                                    <span className="font-bold">
                                      {this.state.selectedSchool.school_name}
                                    </span>
                                    {" - "}
                                    <span className="font-bold">
                                      {
                                        this.state.selectedQualification
                                          .qualification_name
                                      }
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
