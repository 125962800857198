import React, { Component, Fragment, ReactNode } from "react";
import {
  AiFillCheckCircle,
  AiOutlineAppstoreAdd,
  AiOutlineClear,
} from "react-icons/ai";
import { IoIosAdd } from "react-icons/io";
import {
  BatchInterface,
  BatchStatus,
  DistributeAssetInterface,
  FC_DistributeAssets,
  FC_GetBatchSchools,
  FC_GetCurrentSchoolAssets,
  FC_UpdateBatchStatus,
  SchoolAssetsInterface,
  SchoolDataInterface,
  SchoolsInterface,
  UpdateBatchStatus,
} from "../../actions";
import { BatchDefinitionInterface } from "./DistributionDashboard";
import { MdSchool } from "react-icons/md";
import {
  BsCheck2Circle,
  BsChevronDown,
  BsFillDatabaseFill,
} from "react-icons/bs";
import { AddSchoolsToDistribution } from "../../components/DistributionBatch/AddSchoolsToDistribution";
import { GoArrowRight } from "react-icons/go";
import { NoResultFound } from "../../components/Fragments/NoResultFound";
import { BiCategoryAlt } from "react-icons/bi";
import { GrNext } from "react-icons/gr";

interface CreateDistributionProps {
  openModal: () => void;
  currentBatch: BatchInterface | null;
  setSelectedDefinition: (value: BatchDefinitionInterface) => void;
  setCreateDistribution: (value: boolean) => void;
  setBatchDefinition: (updated: BatchDefinitionInterface[]) => void;
  selectedDefinition: BatchDefinitionInterface | null;
  batchDefinition: BatchDefinitionInterface[];
  getAllBatches: () => void;
}

export interface valuesInterface {
  school_code: number;
  school_current_assets: number;
  new_assets: number;
  total_assets: number;
}

interface CreateDistributionState {
  loadingSchoolData: boolean;
  loading: boolean;
  current_step: number;
  allSchools: SchoolsInterface[];
  selectedDefinition: BatchDefinitionInterface | null;
  openSelectDefinition: boolean;
  formError: any;
  values: valuesInterface[];
  completedSteps: number[];
  processedBatches: string[];
  openSelectSchools: boolean;
}

enum Steps {
  Definition = "Definition",
  SelectSchools = "SelectSchools",
  DistributeAssets = "DistributeAssets",
  Complete = "Complete",
}

export interface BatchStatuses {
  Caption: string;
  Key: BatchStatus;
}

interface StepsInterface {
  order: number;
  caption: string;
  key: Steps;
  icon?: ReactNode;
}

export class CreateDistribution extends Component<
  CreateDistributionProps,
  CreateDistributionState
> {
  constructor(props: CreateDistributionProps) {
    super(props);
    this.state = {
      loadingSchoolData: false,
      loading: true,
      current_step: 1,
      completedSteps: [],
      allSchools: [],
      openSelectDefinition: false,
      selectedDefinition: this.props.selectedDefinition,
      openSelectSchools: false,
      values: [],
      processedBatches: [],
      formError: [],
    };
  }

  componentDidMount(): void {
    if (!this.props.currentBatch) return;

    const batchDetails: BatchDefinitionInterface[] =
      this.props.currentBatch.batch_details.map((batch) => {
        if (batch.id) {
          if (!this.state.processedBatches.includes(batch.id)) {
            this.getBatchSchools(batch.id);
            this.setState({
              processedBatches: [...this.state.processedBatches, batch.id],
            });
          }
        }

        const allAttributes = Object.keys(batch.specification).map(
          (key: string) => ({
            name: key,
            selected: batch.specification[key] || "",
          })
        );

        return {
          batch_id: batch.batch_id || "",
          definition_id: batch.id || "",
          selectedCategory: {
            assets_categories_id: parseInt(batch.assets_categories_id || ""),
            assets_categories_name: batch.assets_categories_name || "",
          },
          selectedSubCategory: {
            name: batch.assets_sub_categories_name || "",
            assets_categories_id: parseInt(batch.assets_categories_id || ""),
            id: batch.assets_sub_categories_id || "",
          },
          selectedBrand: {
            id: batch.brand_id || "",
            name: batch.brand_name || "",
          },
          selectedAttributes: allAttributes,
          distributionLimit: batch.assets_number_limit || 0,
          schools: [],
          bookedAssets: 0,
        };
      });

    this.props.setBatchDefinition(batchDetails);

    this.setState({ selectedDefinition: this.props.selectedDefinition });
    if (this.props.batchDefinition.length > 0)
      this.setState({ completedSteps: [...this.state.completedSteps, 1] });
    const allMeetLimit = this.props.batchDefinition.every((definition) => {
      return definition.bookedAssets === definition.distributionLimit;
    });
    if (this.props.batchDefinition.length > 0 && allMeetLimit)
      this.setState({ completedSteps: [...this.state.completedSteps, 2] });
  }

  componentDidUpdate(prevProps: Readonly<CreateDistributionProps>): void {
    if (prevProps.selectedDefinition !== this.props.selectedDefinition) {
      this.setState({ selectedDefinition: this.props.selectedDefinition });
    }

    if (this.props.batchDefinition !== prevProps.batchDefinition) {
      console.log("batch definition", this.props.batchDefinition);
    }

    if (
      this.props.batchDefinition.length > 0 &&
      !this.state.completedSteps.includes(1)
    ) {
      this.setState({ completedSteps: [...this.state.completedSteps, 1] });
    }
  }

  UpdateStatus = (newStatus: BatchStatus) => {
    if (!this.props.currentBatch) return;
    const data: UpdateBatchStatus = {
      title: this.props.currentBatch.title,
      batch_status: newStatus,
      status: 1,
    };
    const batch_id = this.props.currentBatch.id;

    FC_UpdateBatchStatus(batch_id, data, (loading, res) => {
      this.setState({ loadingSchoolData: loading });
      if (res?.type === "SUCCESS") {
        console.log("success");
        this.props.setCreateDistribution(false);
      } else {
        console.log(res?.msg);
      }
    });
  };

  getCurrentAssets = () => {
    if (!this.state.selectedDefinition) return;

    const selectedSchool: SchoolDataInterface[] =
      this.state.selectedDefinition?.schools?.map((itm) => ({
        school_code: itm.school_code + "",
        level_code: itm.level_code || "1",
        assets_categories_id:
          this.state.selectedDefinition?.selectedCategory
            ?.assets_categories_id + "" || "",
        assets_sub_categories_id:
          this.state.selectedDefinition?.selectedSubCategory?.id || "",
        brand_id: this.state.selectedDefinition?.selectedBrand?.id || "",
      })) || [];

    const data = {
      schools: selectedSchool,
    };

    FC_GetCurrentSchoolAssets(data, (loading, res) => {
      this.setState({ loadingSchoolData: loading });
      if (res?.type === "SUCCESS") {
        const values: valuesInterface[] =
          res?.data?.map((itm: any) => ({
            school_code: itm.school_code,
            school_current_assets: itm.school_current_assets,
            new_assets: 0,
            total_assets: ~~itm.school_current_assets + ~~itm.new_assets,
          })) || [];
        this.setState({ values: values });
      } else {
        return;
      }
    });
  };

  distributeAssets = () => {
    if (!this.state.selectedDefinition) return;

    const selectedSchool: SchoolAssetsInterface[] =
      this.state.selectedDefinition?.schools?.map((itm) => ({
        batch_id: this.state.selectedDefinition?.batch_id || "",
        level_code: itm.level_code || "1",
        school_code: itm.school_code + "" || "",
        batch_details_id: this.state.selectedDefinition?.definition_id || "",
        assets_number_limit:
          this.state.values.find(
            (value) => value.school_code + "" === itm.school_code + ""
          )?.new_assets || 0,
      })) || [];

    const data: DistributeAssetInterface = {
      school_distribution: selectedSchool,
    };

    FC_DistributeAssets(data, (loading, res) => {
      this.setState({ loadingSchoolData: loading });
      if (res?.type === "SUCCESS") {
        const booked = this.state.values.reduce((total, itm) => {
          return total + itm.new_assets;
        }, 0);

        if (!this.state.selectedDefinition) return;
        this.setState({
          selectedDefinition: {
            ...this.state.selectedDefinition,
            bookedAssets: booked,
          },
        });

        const updatedBatchDefinition = this.props.batchDefinition.map((def) => {
          if (
            def.definition_id === this.state.selectedDefinition?.definition_id
          ) {
            return {
              ...this.state.selectedDefinition,
              bookedAssets: booked,
            };
          } else {
            return def;
          }
        });

        this.props.setBatchDefinition(updatedBatchDefinition);

        const allMeetLimit = this.props.batchDefinition.every((definition) => {
          return definition.bookedAssets === definition.distributionLimit;
        });

        if (allMeetLimit) {
          this.setState({ current_step: 3 });
          this.setState({ completedSteps: [...this.state.completedSteps, 2] });
        } else {
          this.setState({ current_step: 1 });
        }

        console.log("allMeetLimit?", allMeetLimit);
      } else {
        return;
      }
    });
  };

  updateValues = (code: string, value: number) => {
    const booked = this.state.values.reduce((total, itm) => {
      if (itm.school_code.toString() !== code) {
        return total + itm.new_assets;
      }
      return total;
    }, 0);

    if (!this.state.selectedDefinition) return;

    const remainingAssets =
      this.state.selectedDefinition.distributionLimit - booked;

    if (remainingAssets - value >= 0) {
      this.setState((prevState) => ({
        values: prevState.values.map((itm) =>
          itm.school_code.toString() === code
            ? {
                ...itm,
                new_assets: value,
                total_assets: ~~itm.school_current_assets + value,
              }
            : itm
        ),
      }));
    } else {
      console.log("exceeds the limit");
    }
  };

  getBatchSchools = (batch_id: string) => {
    FC_GetBatchSchools(batch_id, (loading, res) => {
      this.setState({ loadingSchoolData: loading });
      if (res?.type === "SUCCESS") {
        res.data.forEach((school) => {
          if (!school.assets_number_limit) return;
          this.setState({
            values: [
              ...this.state.values,
              {
                school_code: school.school_code,
                school_current_assets: 0,
                new_assets: school.assets_number_limit,
                total_assets: 0 + school.assets_number_limit,
              },
            ],
          });
        });

        const updatedDefinition = this.props.batchDefinition.map((itm) => {
          if (
            itm.definition_id === this.props.selectedDefinition?.definition_id
          ) {
            const updatedDef: BatchDefinitionInterface = {
              ...itm,
              schools: res.data,
              bookedAssets: res.data.reduce(
                (total, itm) => total + (itm.assets_number_limit || 0),
                0
              ),
            };
            this.setState({ selectedDefinition: updatedDef });
            return updatedDef;
          } else {
            return itm;
          }
        });

        this.props.setBatchDefinition(updatedDefinition);
      } else {
        return;
      }
    });
  };

  render() {
    const steps: StepsInterface[] = [
      {
        order: 1,
        caption: "Define asset categories",
        key: Steps.Definition,
        icon: <BiCategoryAlt size={24} />,
      },
      {
        order: 2,
        caption: "Distribute Assets",
        key: Steps.DistributeAssets,
        icon: <AiOutlineAppstoreAdd size={24} />,
      },
      {
        order: 3,
        caption: "Complete Distribution",
        key: Steps.Complete,
        icon: <GrNext size={24} />,
      },
    ];

    return (
      <Fragment>
        {!this.state.openSelectSchools && (
          <div className="h-full w-full gap-4 p-4 flex flex-col animate-fade-left animate__animated animate__fadeInRight animate__faster">
            <div className="flex flex-row justify-between w-full h-18 bg-white items-start gap-2 p-4 align-middle rounded-md">
              <span className="flex gap-3 justify-between items-center h-18">
                <IoIosAdd size={40} className="text-primary-700" />
                <h2 className="text-xl font-bold">
                  {this.props.currentBatch?.title} distribution batch
                </h2>
              </span>

              <div className="absolute right-4 gap-2 flex justify-end p-2  rounded-md align-middle">
                <span className="rounded-full text-sm flex flex-row w-max items-center py-1 justify-center gap-2 text-accent-950 cursor-pointer">
                  Change Status
                </span>

                <select
                  value={
                    this.state.loading
                      ? "loading..."
                      : this.props.currentBatch?.batch_status
                  }
                  onChange={(e) =>
                    this.UpdateStatus(e.target.value as BatchStatus)
                  }
                  className="text-sm border-2 border-accent-800 rounded-md px-4 py-2 font-bold"
                >
                  <option
                    value={this.props.currentBatch?.batch_status}
                    selected
                  >
                    {this.props.currentBatch?.batch_status}
                  </option>
                </select>
              </div>
            </div>
            <div className="flex flex-col w-full bg-gray-100 h-full rounded-md relative">
              <ul className="flex flex-row items-center bg-white justify-between w-full p-4 rounded-lg">
                {steps.map((step) => (
                  <li
                    key={step.order}
                    onClick={() => {
                      if (
                        this.props.currentBatch?.batch_status !==
                          BatchStatus.approved &&
                        step.order !== 1 &&
                        !this.state.completedSteps.includes(step.order - 1)
                      )
                        return;
                      if (step.order === 2 && !this.state.selectedDefinition) {
                        return;
                      }
                      this.setState({ current_step: step.order });
                    }}
                    className={`flex flex-row gap-2 text-black w-1/3 cursor-pointer hover:font-bold ${
                      this.state.current_step === step.order ? "font-bold" : ""
                    }`}
                  >
                    {this.state.completedSteps.includes(step.order) ||
                    this.props.currentBatch?.batch_status ===
                      BatchStatus.approved ? (
                      <div className="flex flex-row items-center gap-2">
                        <AiFillCheckCircle
                          size={40}
                          className="text-accent-800"
                        />
                        <div className="flex flex-col">
                          <span className="text-xs ">Step {step.order}</span>
                          <span className="text-base">{step.caption}</span>
                        </div>
                      </div>
                    ) : !this.state.completedSteps.includes(step.order) &&
                      this.state.current_step === step.order ? (
                      <div className="flex flex-row items-center gap-2">
                        <span className="bg-primary-400 text-white rounded-full w-10 h-10 flex items-center justify-center">
                          {step.icon}
                        </span>
                        <div className="flex flex-col">
                          <span className="text-xs font-bold">
                            Step {step.order}
                          </span>
                          <span className="text-base font-bold">
                            {step.caption}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-row items-center gap-2">
                        <span className="border-2 border-primary-400 text-primary-800 font-bold rounded-full w-10 h-10 flex items-center justify-center">
                          {step.icon}
                        </span>
                        <div className="flex flex-col">
                          <span className="text-xs ">Step {step.order}</span>
                          <span className="text-base">{step.caption}</span>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
              {this.state.current_step === 1 && (
                <div className="flex flex-col w-full p-4 relative h-full gap-4">
                  {this.props.batchDefinition.length > 0 ? (
                    <>
                      <div className="flex flex-row justify-between w-full items-end">
                        <span className="text-lg font-bold">
                          Batch definition
                        </span>
                        <span className="bg-white border-primary-700 border-2 h-10 flex flex-row items-center gap-2 justify-center rounded-lg px-2 shadow-sm hover:bg-primary-800 hover:text-white cursor-pointer">
                          <IoIosAdd size={20} />
                          <span
                            onClick={this.props.openModal}
                            className="text-sm font-bold"
                          >
                            Add new Definition
                          </span>
                        </span>
                      </div>

                      <table className="w-full text-md text-left">
                        <thead>
                          <tr className="bg-gray-200">
                            <th className="p-3 border w-1 truncate">#</th>
                            <th className="p-3 border w-1 truncate">
                              Category
                            </th>
                            <th className="p-3 border w-1 truncate">
                              Sub category
                            </th>
                            <th className="p-3 border w-1 truncate">Brand</th>
                            <th className="p-3 border w-1 truncate">
                              Specifications
                            </th>
                            <th className="p-3 border w-1 truncate">Booked</th>
                            <th className="p-3 border w-1 truncate">Actions</th>
                          </tr>
                        </thead>

                        <tbody className="text-xs">
                          {this.props.batchDefinition.map((def, index) => (
                            <tr
                              key={index}
                              title="Click to view"
                              onClick={() => {
                                this.props.setSelectedDefinition(def);
                                this.getBatchSchools(def.definition_id);
                                this.setState({
                                  current_step: this.state.current_step + 1,
                                });
                              }}
                              className={`hover:bg-accent-200 cursor-pointer hover:text-accent-900 p-2 ${
                                index % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }`}
                            >
                              <td className="p-3 border text-base truncate w-1 text-left">
                                {index + 1}
                              </td>
                              <td className="p-3 border text-base truncate w-1 text-left">
                                {def.selectedCategory?.assets_categories_name}
                              </td>
                              <td className="p-3 border text-base truncate w-1 text-left">
                                {def.selectedSubCategory?.name}
                              </td>
                              <td className="p-3 border text-base truncate w-1 text-left">
                                {def.selectedBrand?.name}
                              </td>
                              <td className="p-3 border text-base truncate w-1 text-left">
                                <div className="truncate w-full h-full flex flex-row gap-1">
                                  {def.selectedAttributes.map((itm, i) => (
                                    <div
                                      className=" text-base text-black px-2"
                                      key={i}
                                    >
                                      <label className="text-gray-600 text-sm">
                                        {itm.name}-
                                      </label>
                                      <span className=" text-base text-black">
                                        {itm.selected}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </td>
                              <td className="p-3 border text-base truncate w-1 text-left">
                                {def.bookedAssets} of {def.distributionLimit}
                              </td>
                              <td className="p-3 border text-xs truncate w-1 text-center">
                                {def.bookedAssets === def.distributionLimit ? (
                                  <AiFillCheckCircle
                                    size={20}
                                    className="text-accent-800"
                                  />
                                ) : (
                                  <GoArrowRight size={20} />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <NoResultFound
                      title="No Batch added yet!"
                      description=""
                      button={
                        <div
                          onClick={this.props.openModal}
                          className="bg-primary-700 text-white rounded-md cursor-pointer hover:bg-primary-800 flex flex-row items-center justify-center gap-2 mt-2 px-4 py-2 pl-3 font-bold"
                        >
                          <div>
                            <IoIosAdd className="text-xl" />
                          </div>
                          <span>Add new Batch definition</span>
                        </div>
                      }
                    />
                  )}
                </div>
              )}

              {this.state.current_step === 2 && (
                <div className="flex flex-col w-full p-4 gap-4 animate__animated animate__fadeInRight animate__faster">
                  <div className="flex flex-col bg-primary-100 rounded-lg p-4">
                    <h2 className="font-bold">Distribute assets on schools</h2>
                    <div className="relative">
                      <div
                        onClick={() =>
                          this.setState({ openSelectDefinition: true })
                        }
                        className="rounded-lg flex flex-row items-center justify-between gap-4 w-full bg-white shadow-sm"
                      >
                        <ul
                          className={`gap-4 justify-between flex flex-row items-center cursor-pointer rounded-lg p-2`}
                        >
                          <li className="truncate w-max text-base text-black">
                            {
                              this.props.selectedDefinition?.selectedCategory
                                ?.assets_categories_name
                            }
                          </li>
                          <li className="truncate w-max text-base text-black">
                            {
                              this.props.selectedDefinition?.selectedSubCategory
                                ?.name
                            }
                          </li>
                          <li className="truncate w-max text-base text-black">
                            {this.props.selectedDefinition?.selectedBrand?.name}
                          </li>
                          {this.props.selectedDefinition?.selectedAttributes.map(
                            (itm, i) => (
                              <li
                                className="truncate w-max text-base text-black"
                                key={i}
                              >
                                <label className="text-gray-400 text-sm">
                                  {itm.name}-
                                </label>
                                {itm.selected}
                              </li>
                            )
                          )}
                        </ul>
                        <div className="flex flex-row items-center gap-4">
                          <span className="font-bold truncate w-max text-base text-white bg-primary-700 py-1 px-4 rounded-lg">
                            {this.state.selectedDefinition?.bookedAssets} of{" "}
                            {this.props.selectedDefinition?.distributionLimit}
                          </span>
                          <BsChevronDown
                            className="text-primary-700"
                            size={30}
                          />
                        </div>
                      </div>

                      {this.state.openSelectDefinition && (
                        <div
                          onClick={() =>
                            this.setState({ openSelectDefinition: false })
                          }
                          className="absolute w-full flex flex-col gap-3 p-4 top-14 border border-gray-300 rounded-lg z-10 h-max bg-white animated animate_animated animate__fadeInDown animate_faster"
                        >
                          {this.props.batchDefinition.map((itm, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                this.setState({
                                  openSelectDefinition: false,
                                  selectedDefinition: itm,
                                });
                                this.props.setSelectedDefinition(itm);
                              }}
                              className={`rounded-lg hover:bg-gray-100 flex flex-row items-center justify-between gap-4 w-full p-2 bg-primary-100 shadow-sm cursor-pointer`}
                            >
                              <ul
                                className={`gap-4 justify-between flex flex-row items-center`}
                              >
                                <li className="truncate w-max text-base text-black">
                                  {itm.selectedCategory?.assets_categories_name}
                                </li>
                                <li className="truncate w-max text-base text-black">
                                  {itm.selectedSubCategory?.name}
                                </li>
                                <li className="truncate w-max text-base text-black">
                                  {itm.selectedBrand?.name}
                                </li>
                                {itm.selectedAttributes.map((item, i) => (
                                  <li
                                    className="truncate w-max text-base text-black"
                                    key={i}
                                  >
                                    <label className="text-gray-400 text-sm">
                                      {item.name}-
                                    </label>
                                    {item.selected}
                                  </li>
                                ))}
                              </ul>
                              <div className="flex flex-row items-end gap-4">
                                {itm.bookedAssets === itm.distributionLimit ? (
                                  <span className="font-bold truncate w-max text-base text-white bg-primary-700 py-1 px-4 rounded-lg">
                                    {itm.bookedAssets} of{" "}
                                    {itm.distributionLimit}
                                  </span>
                                ) : (
                                  <AiFillCheckCircle
                                    size={20}
                                    className="text-accent-800"
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {this.state.selectedDefinition &&
                  this.state.selectedDefinition.schools.length > 0 ? (
                    <>
                      <div className="flex flex-row justify-between w-full items-end">
                        <span className="text-lg font-bold">Schools</span>
                        {this.state.selectedDefinition?.bookedAssets !==
                          this.state.selectedDefinition?.distributionLimit && (
                          <span className="flex flex-row gap-3">
                            {this.state.selectedDefinition &&
                              this.state.selectedDefinition.schools.length >
                                0 && (
                                <span className="bg-white border-primary-700 border-2 h-10 flex flex-row items-center gap-2 justify-center rounded-lg px-2 shadow-sm hover:bg-primary-800 hover:text-white cursor-pointer">
                                  <BsFillDatabaseFill
                                    size={20}
                                    className=" text-primary-700"
                                  />
                                  <span
                                    onClick={() => this.getCurrentAssets()}
                                    className="text-sm font-bold"
                                  >
                                    Get current asset
                                  </span>
                                </span>
                              )}
                            <span className="bg-white border-primary-700 border-2 h-10 flex flex-row items-center gap-2 justify-center rounded-lg px-2 shadow-sm hover:bg-primary-800 hover:text-white cursor-pointer">
                              <IoIosAdd size={20} />
                              <span
                                onClick={() => {
                                  this.setState({ openSelectSchools: true });
                                }}
                                className="text-sm font-bold"
                              >
                                Add schools
                              </span>
                            </span>
                          </span>
                        )}
                      </div>
                      <div className="w-full text-md text-left rounded-lg">
                        <ul className="text-sm bg-gray-200 flex flex-row w-full items-center">
                          <li className="p-2 border w-2/6 truncate">
                            School name
                          </li>
                          <li className="p-2 border w-1/6 truncate">level</li>
                          <li className="p-2 border w-1/6 truncate">
                            Current at school
                          </li>
                          <li className="p-2 border w-1/6 truncate">
                            New Asset
                          </li>
                          <li className="p-2 border w-1/6 truncate">
                            Total Assets
                          </li>
                        </ul>

                        <ul className="text-xs overflow-scroll h-80 flex flex-col w-full items-center">
                          {this.state.selectedDefinition?.schools.map(
                            (school) => {
                              const value = this.state.values.filter(
                                (value) =>
                                  value.school_code.toString() ===
                                  school.school_code.toString()
                              )[0];
                              return (
                                <li
                                  key={school.school_code}
                                  title="Click to view"
                                  className={`hover:bg-accent-200 border cursor-pointer hover:text-accent-900 bg-gray-50 flex flex-row w-full items-center`}
                                >
                                  <span className="p-3 text-base gap-4 truncate w-2/6 text-left flex flex-row items-center">
                                    <MdSchool
                                      size={28}
                                      className="font-thin cursor-pointer text-primary-700"
                                    />
                                    {school.school_name}
                                  </span>
                                  <span className="p-1 border text-base truncate w-1/6 text-left">
                                    <select
                                      value={school.level_code}
                                      placeholder="Level"
                                      className="p-2 w-full h-full"
                                    >
                                      <option value="1" selected>
                                        Level
                                      </option>
                                      <option value="1">Primary</option>
                                      <option value="2">Secondary</option>
                                    </select>
                                  </span>
                                  <span className="p-1 border text-base truncate w-1/6 text-left">
                                    {this.state.loadingSchoolData ? (
                                      <div className="animate-pulse text-base">
                                        ...
                                      </div>
                                    ) : (
                                      <input
                                        value={
                                          value?.school_current_assets || "0"
                                        }
                                        className="p-2 w-full h-full"
                                        disabled
                                      />
                                    )}
                                  </span>
                                  <span className="p-1 border text-base truncate w-1/6 text-left">
                                    {this.state.loadingSchoolData ? (
                                      <div className="animate-pulse text-base">
                                        ...
                                      </div>
                                    ) : (
                                      <input
                                        value={value?.new_assets || "0"}
                                        className="p-2 w-full h-full"
                                        type="number"
                                        min={0}
                                        onChange={(e) => {
                                          this.updateValues(
                                            school.school_code + "",
                                            ~~e.target.value
                                          );
                                        }}
                                      />
                                    )}
                                  </span>
                                  <span className="p-1 border text-base truncate w-1/6 text-left">
                                    {this.state.loadingSchoolData ? (
                                      <div className="animate-pulse text-base">
                                        ...
                                      </div>
                                    ) : (
                                      <input
                                        value={value?.total_assets || "0"}
                                        className="p-2 w-full h-full"
                                        disabled
                                      />
                                    )}
                                  </span>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </>
                  ) : (
                    <NoResultFound
                      title="No school selected!"
                      description=""
                      button={
                        <div
                          onClick={() =>
                            this.setState({ openSelectSchools: true })
                          }
                          className="bg-primary-700 text-white rounded-md cursor-pointer hover:bg-primary-800 flex flex-row items-center justify-center gap-2 mt-2 px-4 py-2 pl-3 font-bold"
                        >
                          <div>
                            <IoIosAdd className="text-xl" />
                          </div>
                          <span>Add schools</span>
                        </div>
                      }
                    />
                  )}

                  {this.state.selectedDefinition &&
                    this.state.selectedDefinition.schools.length > 0 &&
                    this.state.selectedDefinition.bookedAssets !==
                      this.state.selectedDefinition.distributionLimit && (
                      <div className="flex flex-row items-center self-end gap-4 w-max">
                        <button
                          onClick={(e) => {
                            this.setState({ values: [] });
                          }}
                          className="w-50 bg-gray-200 text-black px-4 py-3 rounded-md text-sm flex flex-row items-center justify-center gap-2 hover:bg-gray-300 cursor-pointer"
                        >
                          <AiOutlineClear size={20} />
                          <span>Clear form</span>
                        </button>

                        <div
                          onClick={() => this.distributeAssets()}
                          className="bg-primary-700 text-white px-4 py-3 rounded-md text-sm flex flex-row items-center justify-center gap-2 hover:bg-primary-800 cursor-pointer "
                        >
                          <BsCheck2Circle className="text-xl " />
                          <span>Distribute</span>
                        </div>
                      </div>
                    )}
                </div>
              )}
              {this.state.current_step === 3 && (
                <div className="w-full h-full p-12 gap-6 flex flex-row items-center justify-center animate__animated animate__fadeInRight animate__faster">
                  <div className="bg-white h-80 gap-4 flex flex-col w-full rounded-lg items-center justify-center text-center p-4">
                    <AiFillCheckCircle size={50} className="text-accent-800" />
                    <span className="font-bold text-xl">
                      Batch Created successfully
                    </span>
                    <span
                      onClick={() => {
                        this.props.getAllBatches();
                        this.UpdateStatus(BatchStatus.approved);
                      }}
                      className="text-base bg-primary-700 text-white flex flex-row items-center justify-center hover:bg-primary-800 px-4 py-2 rounded-xl"
                    >
                      <BsCheck2Circle className="text-xl " />
                      Approve
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.openSelectSchools && (
          <AddSchoolsToDistribution
            selectedDefinition={this.state.selectedDefinition}
            setSelectedDefinition={(value: BatchDefinitionInterface) =>
              this.setState({ selectedDefinition: value })
            }
            batchDefinition={this.props.batchDefinition}
            setBatchDefinition={(updated: BatchDefinitionInterface[]) =>
              this.props.setBatchDefinition(updated)
            }
            setAddSchoolsWin={() => this.setState({ openSelectSchools: false })}
          />
        )}
      </Fragment>
    );
  }
}
// function FC_GetSchools(arg0: (loading: any, res: any) => void) {
//     throw new Error("Function not implemented.");
// }
