import React, { Component, Fragment } from "react";
import { StoreState } from "../../reducers";
import {
  Auth,
  CohortInterface,
  FC_FindUserInfo,
  FC_GetAllPositions,
  FC_GetLocationsDetails,
  FC_GetRoles,
  FC_GetSchoolHierarchy,
  LocationAPI,
  System,
  USER_QUALIFICATIONS,
  UserInfoDataFoundInterface,
  UserRoleInterface,
} from "../../actions";
import { connect } from "react-redux";
import { HiMiniUser, HiOutlineUser } from "react-icons/hi2";
import MainContainer from "../MainContainer/MainContainer";
import { MdKeyboardArrowDown, MdList } from "react-icons/md";
import { RegisterForm } from "./RegisterForm";
import Loading from "../Loading/Loading";
import SelectCustom from "../SelectCustom/SelectCustom";
import { RiSearchLine } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoMdArrowBack } from "react-icons/io";
import { UserSchoolInfo } from "./UserSchoolInfo";
import { NoResultFound } from "../Fragments/NoResultFound";
import { UserSubjects } from "./UserSubjects";
import Alert, { AlertType } from "../Alert/Alert";
import { IoPersonAddOutline } from "react-icons/io5";

export const SCHOOL_LEVEL_ROLES = [
  "1",
  "2",
  "5",
  "6",
  "7",
  "8",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
];

interface RegisterUserAccountProps {
  auth: Auth;
  system: System;
  FC_GetRoles: (
    callback: (
      loading: boolean,
      res: {
        type: "SUCCESS" | "ERROR";
        msg: string;
      } | null
    ) => void
  ) => void;
  onSuccess: (data: {
    staff_code: string;
    user_id: string;
    role_id: string;
  }) => void;
  cohort: CohortInterface;
  FC_GetSchoolHierarchy: (callback: (loading: boolean) => void) => void;
  FC_GetAllPositions: (callback: (loading: boolean) => void) => void;
  selectedUser: UserInfoDataFoundInterface | null;
}
interface RegisterUserAccountState {
  loading: boolean;
  openChooseRole: boolean;
  selectedRole: UserRoleInterface | null;
  error: string;
  searchUserData: string;
  searching: boolean;
  searchError: string;
  currentData: UserInfoDataFoundInterface | null;
  showSearchBar: boolean;
  searchProcessCompleted: boolean;
  user_id: string;
  locations: LocationAPI | null;
  showInfoAfterRegister: boolean;
  success: string;
}

class _RegisterUserAccount extends Component<
  RegisterUserAccountProps,
  RegisterUserAccountState
> {
  constructor(props: RegisterUserAccountProps) {
    super(props);
    this.state = {
      loading: false,
      openChooseRole: false,
      selectedRole: null,
      error: "",
      searchUserData:
        this.props.selectedUser !== null
          ? this.props.selectedUser.staff_code
          : "",
      searching: false,
      searchError: "",
      currentData: null,
      showSearchBar: true,
      searchProcessCompleted: false,
      user_id: "",
      locations: null,
      showInfoAfterRegister: false,
      success: "",
    };
  }
  getRoles = () => {
    this.setState({ loading: true });
    this.props.FC_GetRoles(
      (
        loading: boolean,
        res: {
          type: "SUCCESS" | "ERROR";
          msg: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "ERROR") {
          this.setState({ error: res.msg, loading: false });
        }
        if (res?.type === "SUCCESS") {
          this.setState({ loading: false });
        }
      }
    );
  };
  searchUserData = (value: string) => {
    if (value === "") {
      return this.setState({ searchError: "Invalid input" });
    }
    this.setState({ searching: true });
    FC_FindUserInfo(
      value, //Phone, Email, NID
      (
        loading: boolean,
        res: {
          data: UserInfoDataFoundInterface | null;
          msg: string;
          type: "SUCCESS" | "ERROR";
        } | null
      ) => {
        this.setState({ searching: loading });
        if (res?.type === "SUCCESS") {
          const selectedRole = this.props.system.roles?.find(
            (itm) => itm.role_id === res.data?.role_id
          );
          this.setState({
            currentData: res.data,
            searching: false,
            searchError: "",
            searchUserData: "",
            selectedRole: selectedRole !== undefined ? selectedRole : null,
            searchProcessCompleted: true,
            showSearchBar: false,
            user_id: res.data === null ? "" : res.data.user_id,
          });
          // this.GetLocations();
          this.getSchoolHierarchy();
        }
        if (res?.type === "ERROR") {
          this.setState({
            currentData: null,
            searchError: res.msg,
            searching: false,
            searchUserData: "",
            showSearchBar: false,
          });
        }
      }
    );
  };
  GetLocations = () => {
    if (this.state.locations === null) {
      this.setState({ loading: true });
      FC_GetLocationsDetails(
        (
          loading: boolean,
          res: {
            data: LocationAPI | null;
            type: "Error" | "Success";
            msg: string;
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "Success") {
            this.setState({ locations: res.data, loading: false });
          }
        }
      );
    }
  };
  getSchoolHierarchy = () => {
    if (this.props.system.school_hierarchy === null) {
      this.setState({ loading: true });
      this.props.FC_GetSchoolHierarchy((loading: boolean) =>
        this.setState({ loading: loading })
      );
    }
    this.GetLocations();
  };
  getUserQualification = (qualification_id: string) => {
    const response = USER_QUALIFICATIONS.find(
      (itm) => itm.qualification_id.toString() === qualification_id.toString()
    );
    if (response !== undefined) {
      return response;
    }
    return null;
  };
  componentDidMount(): void {
    if (this.state.locations === null) {
      this.GetLocations();
    }
    this.props.system.roles === null && this.getRoles();
    if (this.props.selectedUser !== null) {
      this.searchUserData(this.props.selectedUser.staff_code);
    }
  }
  render() {
    return (
      <Fragment>
        <div
          className="bg-gray-100 py-3 overflow-y-auto"
          style={{ height: "calc(100vh - 200px)" }}
        >
          <div className="mx-3">
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12 lg:col-span-6">
                <div className="w-full h-full rounded-md bg-white">
                  <MainContainer className="rounded-md">
                    <div className="flex flex-row items-center justify-between gap-3 bg-white">
                      <div className="flex flex-row items-center gap-2 pl-1">
                        <div>
                          <HiMiniUser className="text-3xl text-gray-400" />
                        </div>
                        <div>
                          <div className="text-normal font-bold">
                            Personal Info
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <div className="flex flex-row items-center justify-between w-full gap-2 bg-white rounded-lg pt-3">
                        {this.state.searchProcessCompleted === true &&
                          this.props.selectedUser === null && (
                            <div
                              onClick={() =>
                                this.setState({
                                  searchProcessCompleted: false,
                                  currentData: null,
                                  openChooseRole: false,
                                  showSearchBar: true,
                                  selectedRole: null,
                                })
                              }
                              className="bg-white border hover:border-white text-gray-400 hover:bg-primary-50 hover:text-primary-800 rounded-md flex items-center justify-center h-9 w-10 cursor-pointer"
                            >
                              <IoMdArrowBack className="text-xl" />
                            </div>
                          )}
                        {this.state.showSearchBar === true &&
                          this.state.searchProcessCompleted === false && (
                            <div className="w-full relative">
                              <input
                                title={"Search by NID, Email, or Phone Number"}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) =>
                                  this.setState({
                                    searchUserData: e.target.value,
                                  })
                                }
                                disabled={this.state.searching}
                                value={this.state.searchUserData}
                                type={"text"}
                                placeholder="Search by NID, Email, or Phone Number"
                                className={`border border-white bg-gray-50 text-primary-800 ${
                                  this.state.searchUserData === ""
                                    ? ""
                                    : "font-bold"
                                } text-sm w-full px-3 py-2 pl-10 rounded-md focus:outline-none focus:border-black focus:bg-white`}
                                autoFocus={true}
                              />
                              <div
                                className="absolute top-0 bottom-0 pl-3"
                                style={{ paddingTop: "0.6rem" }}
                              >
                                <RiSearchLine className="text-lg" />
                              </div>
                            </div>
                          )}
                        {/* {this.state.searchUserData === "" &&
                          this.state.showSearchBar === true &&
                          this.state.searchProcessCompleted === false && (
                            <div>or</div>
                          )} */}
                        {this.state.searchUserData === "" &&
                        this.state.showSearchBar === false &&
                        this.state.searchError === "" ? (
                          <div className="w-full relative">
                            <div
                              onClick={() =>
                                this.setState({
                                  openChooseRole: !this.state.openChooseRole,
                                })
                              }
                              className={`flex flex-row items-center justify-between rounded-md ${
                                this.state.selectedRole === null
                                  ? "border border-accent-800 hover:border-white hover:bg-accent-200 hover:text-accent-900"
                                  : "border border-white bg-accent-200 text-accent-900 hover:border-accent-800"
                              } hover:bg-white px-3 py-2 cursor-pointer text-sm`}
                            >
                              {this.state.selectedRole === null ? (
                                <span>Register as new user</span>
                              ) : (
                                <span className="">
                                  {this.state.selectedRole.role}
                                </span>
                              )}
                              <div>
                                <MdKeyboardArrowDown className="text-xl" />
                              </div>
                            </div>
                            {this.state.openChooseRole === true && (
                              <div className="absolute left-0 right-0 z-50 pt-1">
                                <SelectCustom
                                  loading={this.state.loading}
                                  id={"role_id"}
                                  title={"role"}
                                  close={() =>
                                    this.setState({ openChooseRole: false })
                                  }
                                  data={
                                    this.props.system.roles?.filter(
                                      (itm) =>
                                        SCHOOL_LEVEL_ROLES.find(
                                          (r) => r === itm.role_id.toString()
                                        ) !== undefined
                                    ) ?? []
                                  }
                                  click={(data: UserRoleInterface) => {
                                    this.setState({
                                      selectedRole: data,
                                      openChooseRole: false,
                                      searchProcessCompleted: true,
                                      showSearchBar: false,
                                      searchError: "",
                                    });
                                    this.getSchoolHierarchy();
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          this.state.searchProcessCompleted === false &&
                          this.state.searchUserData !== "" && (
                            <div
                              onClick={() =>
                                this.state.searching === false &&
                                this.searchUserData(this.state.searchUserData)
                              }
                              className="bg-primary-700 text-white px-6 py-2 hover:bg-primary-800 rounded-md flex flex-row items-center justify-center gap-2 w-max cursor-pointer"
                            >
                              {this.state.searching === true ? (
                                <div>
                                  <AiOutlineLoading3Quarters className="text-xl text-yellow-300 animate-spin" />
                                </div>
                              ) : (
                                <div>
                                  <HiOutlineUser className="text-xl" />
                                </div>
                              )}
                              <div className="flex flex-row items-center gap-2">
                                {this.state.searching === false ? (
                                  <span>Search</span>
                                ) : (
                                  <span className="animate__animated animate__fadeIn animate__infinite">
                                    Searching...
                                  </span>
                                )}
                                <span className="font-bold text-yellow-300">
                                  ({this.state.searchUserData})
                                </span>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="mt-6 rounded-md">
                      {(this.state.loading === true ||
                        this.props.system.roles === null) && (
                        <div className="pt-3">
                          <Loading />
                        </div>
                      )}
                      {this.state.searchError !== "" && (
                        <div className="py-2 -mt-6">
                          <div className="bg-primary-50 border border-primary-300 text-black rounded-md p-3">
                            <div className="text-accent-900">
                              {this.state.searchError}
                            </div>
                            <div
                              onClick={() =>
                                this.setState({
                                  searchProcessCompleted: true,
                                  searchError: "",
                                  openChooseRole: true,
                                })
                              }
                              className="bg-green-600 text-white text-sm mt-3 hover:bg-green-700 cursor-pointer px-6 py-2 pl-3 rounded-md w-max flex flex-row items-center justify-center gap-2"
                            >
                              <div>
                                <IoPersonAddOutline className="text-lg" />
                              </div>
                              <span>Click to register as new user</span>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.selectedRole !== null &&
                        this.props.auth.user !== null &&
                        this.props.auth.user.school !== null && (
                          <div className="">
                            <RegisterForm
                              onSuccess={(data: {
                                user_id: string;
                                info: UserInfoDataFoundInterface;
                              }) => {
                                this.setState({
                                  currentData: data.info,
                                  user_id: data.user_id,
                                  success: `${data.info.full_name} has registered successfully!, please fill school and subjects info`,
                                  error: "",
                                });
                              }}
                              role={this.state.selectedRole}
                              currentData={this.state.currentData}
                              cohort={this.props.cohort}
                              cohortconditionId={
                                this.props.cohort.cohorts_condition[0]
                                  .cohortconditionId
                              }
                              training_id={this.props.cohort.trainingId}
                              cohortSchoolCode={
                                this.props.auth.user.school.school_code
                              }
                              addToTraining={true}
                            />
                          </div>
                        )}
                    </div>
                  </MainContainer>
                </div>
              </div>
              <div className="col-span-12 lg:col-span-6">
                <div className="w-full h-full rounded-md bg-white">
                  <div className="flex flex-row items-center gap-2 p-3">
                    <div>
                      <MdList className="text-2xl text-gray-400" />
                    </div>
                    <div>
                      <div className="text-normal font-bold">
                        Other School and subjects info
                      </div>
                    </div>
                  </div>
                  {this.state.success !== "" && (
                    <div className="py-2 px-3">
                      <Alert
                        alertType={AlertType.SUCCESS}
                        title={"Action Succeeded"}
                        description={this.state.success}
                        close={() => this.setState({ success: "", error: "" })}
                      />
                    </div>
                  )}
                  {this.state.currentData === null ||
                  this.state.user_id === "" ? (
                    <div className="p-3">
                      <NoResultFound
                        title="No other info found"
                        description="Please choose options to view details info"
                      />
                    </div>
                  ) : (
                    <>
                      {this.state.showInfoAfterRegister === true && (
                        <Alert
                          alertType={AlertType.WARNING}
                          title={"Please add missing info"}
                          description={
                            "You can add other info like school and subjects for " +
                            this.state.currentData.full_name
                          }
                          close={() =>
                            this.setState({ showInfoAfterRegister: false })
                          }
                        />
                      )}
                      {this.state.locations !== null && (
                        <UserSchoolInfo
                          selectedSchool={
                            this.state.currentData === null ||
                            this.state.currentData.school_code === null ||
                            this.state.currentData.school_name === null ||
                            this.state.currentData.school_code === "" ||
                            this.state.currentData.school_name === ""
                              ? null
                              : {
                                  school_code:
                                    this.state.currentData.school_code,
                                  school_name:
                                    this.state.currentData.school_name,
                                }
                          }
                          user_id={this.state.user_id}
                          locations={this.state.locations}
                          selected_user_role_id={this.state.currentData.role_id}
                          selectedUserQualification={this.getUserQualification(
                            this.state.currentData.qualification_id === null
                              ? ""
                              : this.state.currentData.qualification_id.toString()
                          )}
                          FC_GetAllPositions={this.props.FC_GetAllPositions}
                          positions={this.props.system.positions}
                          selectedUserPosition={
                            this.state.currentData.position_code === null ||
                            this.state.currentData.position_name === null
                              ? null
                              : {
                                  position_code:
                                    this.state.currentData.position_code,
                                  position_name:
                                    this.state.currentData.position_name,
                                }
                          }
                          isTransfer={false}
                          onClose={() => {}}
                          onSuccess={() => {}}
                        />
                      )}
                      {this.props.system.school_hierarchy !== null && (
                        <UserSubjects
                          subjects={this.props.system.school_hierarchy}
                          user_id={this.state.user_id}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({
  auth,
  system,
}: StoreState): {
  auth: Auth;
  system: System;
} => {
  return {
    auth,
    system,
  };
};

export const RegisterUserAccountPage = connect(mapStateToProps, {
  FC_GetRoles,
  FC_GetSchoolHierarchy,
  FC_GetAllPositions,
})(_RegisterUserAccount);
