import React, { Component } from "react";
import {
  FC_AssignUserToSubjects,
  FC_GetUserSubjects,
  SchoolHierarchyInterface,
  UserCourseInterface,
} from "../../actions";
import { IoIosArrowDown, IoMdAdd } from "react-icons/io";
import SelectCustom from "../SelectCustom/SelectCustom";
import Loading from "../Loading/Loading";
import { NoResultFound } from "../Fragments/NoResultFound";
import { MdAdd } from "react-icons/md";
import { IoReaderOutline } from "react-icons/io5";
import Alert, { AlertType } from "../Alert/Alert";

interface UserSubjectsProps {
  subjects: SchoolHierarchyInterface[];
  user_id: string;
}

interface UserSubjectsState {
  loading: boolean;
  selectedCombination: {
    combination_code: string;
    combination_name: string;
  } | null;
  openSelectCombination: boolean;
  selectedGrade: { grade_code: string; grade_name: string } | null;
  openSelectGrade: boolean;
  selectedSubject: { course_name: string; study_hierarchy_id: string } | null;
  openSelectSubject: boolean;
  subjects: UserCourseInterface[] | null;
  error: string;
  success: string;
  addingSubjects: boolean;
}

export class UserSubjects extends Component<
  UserSubjectsProps,
  UserSubjectsState
> {
  constructor(props: UserSubjectsProps) {
    super(props);
    this.state = {
      loading: false,
      selectedCombination: null,
      openSelectCombination: false,
      selectedGrade: null,
      openSelectGrade: false,
      selectedSubject: null,
      openSelectSubject: false,
      subjects: null,
      error: "",
      success: "",
      addingSubjects: false,
    };
  }
  getCombinations = () => {
    const response: { combination_code: string; combination_name: string }[] =
      [];
    for (const item of this.props.subjects) {
      if (
        response.find(
          (itm) => itm.combination_code === item.combination_code
        ) === undefined
      ) {
        response.push({
          combination_code: item.combination_code,
          combination_name: item.combination_name,
        });
      }
    }
    return response;
  };
  getGradesByCombination = (combination_code: string) => {
    const response: { grade_code: string; grade_name: string }[] = [];
    for (const item of this.props.subjects.filter(
      (itm) => itm.combination_code === combination_code
    )) {
      if (
        response.find((itm) => itm.grade_code === item.grade_code) === undefined
      ) {
        response.push({
          grade_code: item.grade_code,
          grade_name: item.grade_name,
        });
      }
    }
    return response;
  };

  getSubjectsByGrade = (grade_code: string) => {
    const response: { study_hierarchy_id: string; course_name: string }[] = [];
    for (const item of this.props.subjects.filter(
      (itm) => itm.grade_code === grade_code
    )) {
      if (
        response.find(
          (itm) => itm.study_hierarchy_id === item.studyhierarchyid
        ) === undefined
      ) {
        response.push({
          study_hierarchy_id: item.studyhierarchyid,
          course_name: item.course_name,
        });
      }
    }
    return response;
  };
  getUserSubjects = () => {
    if (this.props.user_id !== "") {
      FC_GetUserSubjects(
        this.props.user_id,
        (
          loading: boolean,
          res: {
            type: "SUCCESS" | "ERROR";
            msg: string;
            data: UserCourseInterface[];
          } | null
        ) => {
          this.setState({ loading: loading });
          if (res?.type === "SUCCESS") {
            this.setState({
              error: "",
              subjects: res.data,
              loading: false,
            });
          }
          if (res?.type === "ERROR") {
            this.setState({
              error: res.msg,
              loading: false,
              subjects: [],
            });
          }
        }
      );
    }
  };
  onAssignUserSubject = (study_hierarchy_id: string) => {
    this.props.user_id !== "" &&
      FC_AssignUserToSubjects(
        {
          staff_code: this.props.user_id,
          study_hierarchy_id: study_hierarchy_id,
        },
        (
          loading: boolean,
          res: {
            msg: string;
            type: "SUCCESS" | "ERROR";
          } | null
        ) => {
          this.setState({ addingSubjects: loading });
          if (res?.type === "SUCCESS") {
            this.setState({
              addingSubjects: true,
              success: res.msg,
              error: "",
              selectedCombination: null,
              selectedGrade: null,
              selectedSubject: null,
            });
            this.getUserSubjects();
          }
          if (res?.type === "ERROR") {
            this.setState({
              success: "",
              error: res.msg,
              addingSubjects: false,
            });
          }
        }
      );
  };
  componentDidMount(): void {
    this.getUserSubjects();
  }
  render() {
    return (
      <div className="p-3 pt-2">
        <div
          className={`p-3 rounded-md ${
            this.state.subjects === null || this.state.subjects.length === 0
              ? "bg-gray-100"
              : "bg-primary-50"
          } `}
        >
          <div className="font-bold text-sm mb-3">Grades & Subjects</div>
          <div className="my-2">
            {this.state.subjects === null ? (
              <Loading />
            ) : this.state.subjects.length === 0 ? (
              <NoResultFound
                title="No subjects assigned"
                description="Choose the following fields to assign subject"
              />
            ) : (
              <div className="grid grid-cols-12 gap-2 w-full mb-2">
                {this.state.subjects.map((subject, s) => (
                  <div
                    className="col-span-12 rounded-md bg-white p-2 flex flex-row items-center gap-2"
                    key={s + 1}
                  >
                    <div>
                      <IoReaderOutline className="text-2xl text-primary-700" />
                    </div>
                    <div className="">
                      <div className="text-xs text-gray-500">
                        {subject.grade_name}
                      </div>
                      <div className="font-bold text-sm">
                        {subject.course_code} - {subject.course_name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {this.state.loading === true ? (
            <Loading />
          ) : (
            <div>
              <div>
                <div className="flex flex-row items-center gap-2 mb-2 mt-3">
                  <div>
                    <MdAdd className="text-primary-700 text-xl" />
                  </div>
                  <span className="text-primary-800 text-sm uppercase">
                    Add subject
                  </span>
                </div>
                <div className="flex flex-row items-center justify-between gap-2">
                  <div className="w-full relative mb-3">
                    <div
                      onClick={() =>
                        this.setState({
                          openSelectCombination:
                            !this.state.openSelectCombination,
                          openSelectGrade: false,
                          openSelectSubject: false,
                        })
                      }
                      className={`flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border ${
                        this.state.selectedCombination !== null
                          ? "border-white hover:border-accent-800"
                          : "border-accent-800"
                      } group pr-1`}
                    >
                      {this.state.selectedCombination === null ? (
                        <span>Choose Combination</span>
                      ) : (
                        <span className="text-primary-800 group-hover:text-black">
                          {this.state.selectedCombination.combination_name}
                        </span>
                      )}
                      <div>
                        <IoIosArrowDown className="text-lg" />
                      </div>
                    </div>
                    {this.state.openSelectCombination === true && (
                      <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                        <SelectCustom
                          loading={this.state.loading}
                          id={"combination_code"}
                          title={"combination_name"}
                          close={() =>
                            this.setState({ openSelectCombination: false })
                          }
                          data={this.getCombinations()}
                          click={(data: {
                            combination_code: string;
                            combination_name: string;
                          }) => {
                            this.setState({
                              selectedCombination: data,
                              openSelectCombination: false,
                              selectedGrade: null,
                              selectedSubject: null,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  {this.state.selectedCombination !== null && (
                    <div className="w-full relative mb-3">
                      <div
                        onClick={() =>
                          this.setState({
                            openSelectGrade: !this.state.openSelectGrade,
                            openSelectCombination: false,
                            openSelectSubject: false,
                          })
                        }
                        className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                      >
                        {this.state.selectedGrade === null ? (
                          <span>Choose Grade</span>
                        ) : (
                          <span className="text-primary-800 group-hover:text-black">
                            {this.state.selectedGrade.grade_name}
                          </span>
                        )}
                        <div>
                          <IoIosArrowDown className="text-lg" />
                        </div>
                      </div>
                      {this.state.openSelectGrade === true && (
                        <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                          <SelectCustom
                            loading={this.state.loading}
                            id={"grade_code"}
                            title={"grade_name"}
                            close={() =>
                              this.setState({ openSelectGrade: false })
                            }
                            data={this.getGradesByCombination(
                              this.state.selectedCombination.combination_code
                            )}
                            click={(data: {
                              grade_code: string;
                              grade_name: string;
                            }) => {
                              this.setState({
                                selectedGrade: data,
                                openSelectGrade: false,
                                selectedSubject: null,
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {this.state.selectedGrade !== null && (
                <div className="w-full relative mb-3">
                  <div
                    onClick={() =>
                      this.setState({
                        openSelectGrade: false,
                        openSelectCombination: false,
                        openSelectSubject: !this.state.openSelectSubject,
                      })
                    }
                    className="flex flex-row text-sm bg-white items-center gap-2 justify-between w-full px-3 py-2 rounded-md cursor-pointer border border-white hover:border-accent-800 group pr-1"
                  >
                    {this.state.selectedSubject === null ? (
                      <span>Choose Subject</span>
                    ) : (
                      <span className="text-primary-800 group-hover:text-black">
                        {this.state.selectedSubject.course_name}
                      </span>
                    )}
                    <div>
                      <IoIosArrowDown className="text-lg" />
                    </div>
                  </div>
                  {this.state.openSelectSubject === true && (
                    <div className="absolute left-0 right-0 pt-1 animate__animated animate__fadeInUp animate__faster z-10">
                      <SelectCustom
                        loading={this.state.loading}
                        id={"study_hierarchy_id"}
                        title={"course_name"}
                        close={() =>
                          this.setState({ openSelectSubject: false })
                        }
                        data={this.getSubjectsByGrade(
                          this.state.selectedGrade.grade_code
                        )}
                        click={(data: {
                          study_hierarchy_id: string;
                          course_name: string;
                        }) => {
                          this.setState({
                            selectedSubject: data,
                            openSelectSubject: false,
                          });
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {this.state.success !== "" && (
                <div className="py-2">
                  <Alert
                    alertType={AlertType.SUCCESS}
                    title={"Action succeeded"}
                    description={this.state.success}
                    close={() => this.setState({ success: "", error: "" })}
                  />
                </div>
              )}
              {this.state.error !== "" && (
                <div className="py-2">
                  <Alert
                    alertType={AlertType.DANGER}
                    title={"Action failed"}
                    description={this.state.error}
                    close={() => this.setState({ success: "", error: "" })}
                  />
                </div>
              )}
              {this.state.selectedSubject !== null && (
                <div className="">
                  <div
                    onClick={() =>
                      this.state.selectedSubject !== null &&
                      this.onAssignUserSubject(
                        this.state.selectedSubject.study_hierarchy_id
                      )
                    }
                    className="flex flex-row items-center justify-center text-sm hover:text-white gap-2 text-accent-900 bg-white px-3 py-2 w-max rounded-md white border border-accent-800 hover:border-white hover:bg-primary-700 cursor-pointer"
                  >
                    <div>
                      <IoMdAdd className="text-lg" />
                    </div>
                    <span>
                      Assign{" "}
                      <span className="font-bold">
                        {this.state.selectedSubject.course_name}
                      </span>{" "}
                      Subject
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
