import React, { useEffect, useState } from "react";
import {
  AiOutlineClear,
  AiOutlineLoading3Quarters,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { commaFy } from "../../utils/functions";
import {
  BatchInterface,
  FC_AddBatchDefinition,
  FC_CreateBatch,
  FC_GetAllBatches,
  FC_GetRemainingAssets,
  SchoolsInterface,
} from "../../actions/batch.action";
import { MdAdd, MdComputer, MdKeyboardArrowDown } from "react-icons/md";
import {
  BsCheck2Circle,
  BsCheckCircleFill,
  BsFillDatabaseFill,
} from "react-icons/bs";
import { CreateDistribution } from "./CreateDistribution";
import Alert, { AlertType } from "../../components/Alert/Alert";
import BackButton from "../../components/Fragments/BackButton";
import Modal, { Themes, ModalSize } from "../../components/Modal/Modal";
import SelectCustom from "../../components/SelectCustom/SelectCustom";
import {
  BrandInterface,
  CategoryInterface,
  FC_GetAllBrands,
  FC_GetAllCategories,
  FC_GetAllSubCategories,
  SubCategoryInterface,
  BatchStatus,
} from "../../actions";
import { BiMinus } from "react-icons/bi";
import { IoMdAdd } from "react-icons/io";

export interface loadingProps {
  Loading: boolean;
}

export interface BatchStatuses {
  Caption: string;
  Key: BatchStatus;
}

export interface BatchDefinitionInterface {
  batch_id: string;
  definition_id: string;
  id?: string;
  selectedCategory: CategoryInterface | null;
  selectedSubCategory: SubCategoryInterface | null;
  selectedBrand: BrandInterface | null;
  selectedAttributes: AttributesInterface[];
  schools: SchoolsInterface[];
  distributionLimit: number;
  bookedAssets: number;
  assets_categories_id?: string;
  assets_categories_name?: string;
  assets_number_limit?: number;
  assets_sub_categories_id?: string;
  assets_sub_categories_name?: string;
  brand_id?: string;
  brand_name?: string;
  specification?: any;
}

interface AttributesInterface {
  name: string;
  values?: string[];
  selected: string | null;
  open?: boolean;
}

interface FormErrorInterface {
  target: string;
  msg: string | undefined;
}

export interface AssetDistributionInterface {
  definition_id: string;
  schools: SchoolsInterface[];
}

export interface AssetResultInterface {
  available: number;
  assigned: number;
  booked: number;
}

export const DistributionDashboard: React.FC = () => {
  const [selectedStatus, setSelectedStatus] = useState<BatchStatus | null>(
    null
  );
  const [allBatches, setAllBatches] = useState<BatchInterface[]>([]);
  const [filteredBatches, setFilteredBatches] = useState<BatchInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [createDistribution, setCreateDistribution] = useState<boolean>(false);
  const [createDefWin, setCreateDefWin] = useState<boolean>(false);
  const [openSelectCategory, setOpenSelectCategory] = useState<boolean>(false);
  const [openSelectSubCategory, setOpenSelectSubCategory] =
    useState<boolean>(false);
  const [openSelectBrand, setOpenSelectBrand] = useState<boolean>(false);
  const [categories, setCategories] = useState<CategoryInterface[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategoryInterface[]>(
    []
  );
  const [brands, setBrands] = useState<BrandInterface[]>([]);
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryInterface | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] =
    useState<SubCategoryInterface | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<BrandInterface | null>(
    null
  );
  const [formError, setFormError] = useState<FormErrorInterface | null>(null);
  const [attributes, setAttributes] = useState<AttributesInterface[]>([]);
  const [checkingStock, setCheckingStock] = useState<boolean>(false);
  const [assetResults, setAssetResults] = useState<
    AssetResultInterface | undefined
  >(undefined);
  const [bookedAsset, setBookedAsset] = useState<number>(0);
  const [batchDefinition, setBatchDefinition] = useState<
    BatchDefinitionInterface[]
  >([]);
  const [selectedDefinition, setSelectedDefinition] =
    useState<BatchDefinitionInterface | null>(null);
  const [distributionName, setDistributionName] = useState<string>("");
  const [createBatchWin, setCreateBatchWin] = useState<boolean>(false);
  const [currentBatch, setCurrentBatch] = useState<BatchInterface | null>(null);
  const [assetDistribution, setAssetDistribution] = useState<
    AssetDistributionInterface[]
  >([]);
  const [loadingSchoolAssets, setLoadingSchoolAssets] =
    useState<boolean>(false);

  const batchStatuses: BatchStatuses[] = [
    {
      Caption: "Approved",
      Key: BatchStatus.approved,
    },
    {
      Caption: "Draft",
      Key: BatchStatus.draft,
    },
    {
      Caption: "Rejected",
      Key: BatchStatus.rejected,
    },
    {
      Caption: "Completed",
      Key: BatchStatus.completed,
    },
    {
      Caption: "Pending",
      Key: BatchStatus.pending,
    },
    {
      Caption: "Distribution",
      Key: BatchStatus.distribution,
    },
  ];

  const addBatchDefinition = () => {
    if (selectedCategory === null) {
      setFormError({
        target: "category",
        msg: "please add category",
      });
      return;
    }
    if (selectedSubCategory === null) {
      setFormError({
        target: "sub category",
        msg: "please add sub category",
      });
      return;
    }
    if (selectedBrand === null) {
      setFormError({
        target: "brand",
        msg: "please select a brand",
      });
      return;
    }
    if (bookedAsset === 0) {
      setFormError({
        target: "limit",
        msg: "please asset limit",
      });
      return;
    }

    const specifications: any = {};

    attributes.forEach((atr) => {
      specifications[atr.name] = atr.selected;
    });

    if (currentBatch === null) return;
    const data = {
      batch_id: currentBatch.id,
      assets_categories_id: selectedCategory?.assets_categories_id + "",
      assets_number_limit: bookedAsset,
      assets_sub_categories_id: selectedSubCategory?.id,
      brand_id: selectedBrand?.id,
      specification: specifications,
    };

    FC_AddBatchDefinition(data, (loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setCreateDefWin(false);
        setBatchDefinition([
          ...batchDefinition,
          {
            batch_id: currentBatch.id,
            definition_id: res.data?.id || "",
            selectedCategory: selectedCategory,
            selectedSubCategory: selectedSubCategory,
            selectedBrand: selectedBrand,
            selectedAttributes: attributes,
            distributionLimit: bookedAsset,
            schools: [],
            bookedAssets: 0,
          },
        ]);

        setAssetDistribution([
          ...assetDistribution,
          {
            definition_id: res.data?.id || "",
            schools: [],
          },
        ]);

        setSelectedCategory(null);
        setSelectedBrand(null);
        setSelectedSubCategory(null);
        setAttributes([]);
        setAssetResults(undefined);
        setBookedAsset(0);
      } else {
        return;
      }
    });
  };

  const getAllBatches = () => {
    FC_GetAllBatches((loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setAllBatches(res.data);
        setFilteredBatches(res.data);
      } else {
        return;
      }
    });
  };

  const getSubCategories = (category_id: number) => {
    if (!selectedCategory) return;
    FC_GetAllSubCategories(category_id, (loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setSubCategories(res.data);
        setOpenSelectSubCategory(true);
      } else {
        return;
      }
    });
  };

  const createNewBatch = () => {
    if (currentBatch !== null) return;
    FC_CreateBatch(distributionName, (loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setCurrentBatch(res.data);
        setCreateDistribution(true);
      } else {
        setCreateBatchWin(false);
        return;
      }
    });
  };

  const CheckStock = () => {
    if (selectedCategory === null) {
      setFormError({
        target: "category",
        msg: "please add category",
      });
      return;
    }
    if (selectedSubCategory === null) {
      setFormError({
        target: "sub category",
        msg: "please add sub category",
      });
      return;
    }
    if (selectedBrand === null) {
      setFormError({
        target: "brand",
        msg: "please select a brand",
      });
      return;
    }

    const data = {
      assets_categories_id: selectedCategory.assets_categories_id + "",
      assets_sub_categories_id: selectedSubCategory.id,
      brand_id: selectedBrand.id,
    };
    FC_GetRemainingAssets(data, (loading, res) => {
      setCheckingStock(loading);
      if (res?.type === "SUCCESS") {
        if (!res.data) return;
        setAssetResults(res.data);
      } else {
        if (!res?.msg) return;
        setFormError({
          target: res.msg,
          msg: res?.msg,
        });
      }
    });
  };

  useEffect(() => {
    FC_GetAllBatches((loading, res) => {
      setLoading(loading);
      if (res?.type === "SUCCESS") {
        setAllBatches(res.data);
        setFilteredBatches(res.data);
      } else {
        return;
      }
    });
    if (brands.length === 0) {
      FC_GetAllBrands((loading, res) => {
        setLoading(loading);
        if (res?.type === "SUCCESS") {
          setBrands(res.data);
        } else {
          return;
        }
      });
    }
    if (categories.length === 0) {
      FC_GetAllCategories((loading, res) => {
        setLoading(loading);
        if (res?.type === "SUCCESS") {
          setCategories(res.data);
        } else {
          return;
        }
      });
    }
  }, [categories, brands]);

  useEffect(() => {
    if (selectedCategory && selectedCategory.attributes) {
      const allAttributes = selectedCategory.attributes.map((attr) => ({
        ...attr,
        selected: null,
        open: false,
      }));
      setAttributes(allAttributes);
    }
    if (selectedCategory) {
      FC_GetAllSubCategories(
        selectedCategory.assets_categories_id,
        (loading, res) => {
          setLoading(loading);
          if (res?.type === "SUCCESS") {
            setSubCategories(res.data);
            setOpenSelectSubCategory(true);
          } else {
            return;
          }
        }
      );
    }
    setAssetResults(undefined);
  }, [selectedCategory]);

  useEffect(() => {
    setTimeout(() => {
      setSelectedDefinition(selectedDefinition);
      setLoadingSchoolAssets(false);
    }, 1000);
  }, [loadingSchoolAssets, selectedDefinition]);

  if (createDistribution) {
    return (
      <>
        <CreateDistribution
          setBatchDefinition={(updated: BatchDefinitionInterface[]) =>
            setBatchDefinition(updated)
          }
          openModal={() => setCreateDefWin(true)}
          batchDefinition={batchDefinition}
          selectedDefinition={selectedDefinition}
          setSelectedDefinition={(value: BatchDefinitionInterface) =>
            setSelectedDefinition(value)
          }
          currentBatch={currentBatch}
          setCreateDistribution={(value: boolean) =>
            setCreateDistribution(value)
          }
          getAllBatches={() => getAllBatches()}
        />
        {createDefWin && (
          <Modal
            backDrop={true}
            theme={Themes.default}
            close={() => setCreateDefWin(false)}
            backDropClose={true}
            widthSizeClass={ModalSize.large}
            displayClose={false}
            padding={{
              title: undefined,
              body: undefined,
              footer: undefined,
            }}
          >
            <div className="p-0 relative">
              <div className="pb-3 mb-3 border-b p-3">
                <div className="flex flex-row items-center gap-2 text-lg font-bold">
                  <BackButton
                    onClick={() => setCreateDefWin(false)}
                    title="Back"
                    className="bg-primary-50 hover:bg-primary-100 text-primary-800 text-sm font-normal rounded-md"
                  />
                  <div>Create batch definition</div>
                </div>
              </div>
              <div className="col-span-12 p-2">
                <div className="mb-3 flex flex-col items-center justify-between gap-4">
                  <div className="relative w-full">
                    <div
                      onClick={() => {
                        setAssetResults(undefined);
                        setOpenSelectCategory(!openSelectCategory);
                        setOpenSelectSubCategory(false);
                        setOpenSelectBrand(false);
                        const updatedAttributes = attributes.map((attr) => ({
                          ...attr,
                          open: false,
                        }));
                        setAttributes(updatedAttributes);
                      }}
                      className="flex flex-row items-center justify-between w-full border rounded-lg p-2 pl-3 cursor-pointer text-gray-400 hover:border-primary-400 hover:text-primary-800"
                    >
                      {selectedCategory === null ? (
                        <div className="text-sm font-bold">Choose category</div>
                      ) : (
                        <div className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircleFill className="text-xl text-green-600" />
                          </div>
                          <div className="font-bold text-sm text-black">
                            <label className="text-gray-400">Category - </label>
                            {selectedCategory.assets_categories_name}
                          </div>
                        </div>
                      )}
                      <div>
                        <MdKeyboardArrowDown className="text-xl" />
                      </div>
                    </div>
                    {openSelectCategory === true && (
                      <div className="absolute left-0 right-0 z-10 pt-1 animate__animated animate__fadeInUp animate__faster">
                        <SelectCustom
                          loading={loading}
                          id={"assets_categories_id"}
                          title={"assets_categories_name"}
                          close={() => setOpenSelectCategory(false)}
                          data={categories ?? []}
                          click={(data: {
                            assets_categories_id: string;
                            assets_categories_name: string;
                          }) => {
                            const selected = categories?.find(
                              (itm) =>
                                itm.assets_categories_id.toString() ===
                                data.assets_categories_id.toString()
                            );
                            if (selected !== undefined) {
                              setOpenSelectCategory(false);
                              setAssetResults(undefined);
                              setSelectedCategory(selected);
                              setSelectedSubCategory(null);
                              getSubCategories(selected.assets_categories_id);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="relative w-full">
                    <div
                      onClick={() => {
                        setAssetResults(undefined);
                        setOpenSelectSubCategory(!openSelectSubCategory);
                        setOpenSelectCategory(false);
                        setOpenSelectBrand(false);
                        const updatedAttributes = attributes.map((attr) => ({
                          ...attr,
                          open: false,
                        }));
                        setAttributes(updatedAttributes);
                      }}
                      className="flex flex-row items-center justify-between w-full border rounded-lg p-2 pl-3 cursor-pointer text-gray-400 hover:border-primary-400 hover:text-primary-800"
                    >
                      {loading === true ? (
                        <div>Loading, please wait...</div>
                      ) : selectedSubCategory === null ? (
                        <div className="text-sm font-bold">
                          Choose subcategory
                        </div>
                      ) : (
                        <div className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircleFill className="text-xl text-green-600" />
                          </div>
                          <div className="font-bold text-sm text-black">
                            <label className="text-gray-400">
                              Subcategory -{" "}
                            </label>
                            {selectedSubCategory.name}
                          </div>
                        </div>
                      )}
                      <div>
                        <MdKeyboardArrowDown className="text-xl" />
                      </div>
                    </div>
                    {openSelectSubCategory === true && (
                      <div className="absolute left-0 right-0 z-10 pt-1  animate__animated animate__fadeInUp animate__faster">
                        <SelectCustom
                          loading={loading}
                          id={"id"}
                          title={"name"}
                          close={() => setOpenSelectSubCategory(false)}
                          data={subCategories ?? []}
                          click={(data: { id: string; name: string }) => {
                            const selected = subCategories?.find(
                              (itm) => itm.id === data.id
                            );
                            if (selected !== undefined) {
                              setOpenSelectSubCategory(false);
                              setSelectedSubCategory(selected);
                              setFormError(null);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="relative w-full">
                    <div
                      onClick={() => {
                        setOpenSelectCategory(false);
                        setOpenSelectSubCategory(false);
                        setOpenSelectBrand(!openSelectBrand);
                        const updatedAttributes = attributes.map((attr) => ({
                          ...attr,
                          open: false,
                        }));
                        setAttributes(updatedAttributes);
                      }}
                      className="flex flex-row items-center justify-between w-full border rounded-lg p-2 pl-3 cursor-pointer text-gray-400 hover:border-primary-400 hover:text-primary-800"
                    >
                      {selectedBrand === null ? (
                        <div className="text-sm font-bold">Choose brand</div>
                      ) : (
                        <div className="flex flex-row items-center gap-2">
                          <div>
                            <BsCheckCircleFill className="text-xl text-green-600" />
                          </div>
                          <div className="font-bold text-sm text-black">
                            <label className="text-gray-400">Brand - </label>
                            {selectedBrand.name}
                          </div>
                        </div>
                      )}
                      <div>
                        <MdKeyboardArrowDown className="text-xl" />
                      </div>
                    </div>
                    {openSelectBrand === true && (
                      <div className="absolute left-0 right-0 z-10 pt-1  animate__animated animate__fadeInUp animate__faster">
                        <SelectCustom
                          loading={loading}
                          id={"id"}
                          title={"name"}
                          close={() => setOpenSelectBrand(false)}
                          data={brands ?? []}
                          click={(data: { id: string; name: string }) => {
                            const selected = brands?.find(
                              (itm) => itm.id === data.id
                            );
                            if (selected !== undefined) {
                              setOpenSelectBrand(false);
                              setSelectedBrand(selected);
                              setFormError(null);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="h-80 w-full">
                  {attributes.length > 0 && (
                    <div className="flex flex-col gap-3 animate__animated animate__fadeIn animate__slow">
                      {attributes.map((attribute, index) => (
                        <div key={index} className="relative w-full">
                          <div
                            onClick={() => {
                              setOpenSelectBrand(false);
                              setOpenSelectCategory(false);
                              setOpenSelectSubCategory(false);
                              const updates = attributes.map((itm) => {
                                if (itm.name === attribute.name) {
                                  return { ...itm, open: true };
                                } else {
                                  return { ...itm, open: false };
                                }
                              });
                              setAttributes(updates);
                            }}
                            className="flex flex-row items-center justify-between w-full border rounded-lg p-2 pl-3 cursor-pointer text-gray-400 hover:border-primary-400 hover:text-primary-800"
                          >
                            {attribute.selected === null ? (
                              <div className="text-sm font-bold">
                                Choose {attribute.name}
                              </div>
                            ) : (
                              <div className="flex flex-row items-center gap-2">
                                <div>
                                  <BsCheckCircleFill className="text-xl text-green-600" />
                                </div>
                                <div className="font-bold text-sm text-black">
                                  <label className="text-gray-400">
                                    {attribute.name} -{" "}
                                  </label>
                                  {attribute.selected}
                                </div>
                              </div>
                            )}
                            <div>
                              <MdKeyboardArrowDown className="text-xl" />
                            </div>
                          </div>
                          {attribute.open === true && (
                            <div className="absolute left-0 right-0 z-10 pt-1 animate__animated animate__fadeInUp animate__faster">
                              <SelectCustom
                                loading={loading}
                                id={"id"}
                                title={"name"}
                                height={44}
                                close={() => {
                                  const updates = attributes.map((itm) => {
                                    if (itm.name === attribute.name) {
                                      return { ...itm, open: false };
                                    } else {
                                      return { ...itm, open: false };
                                    }
                                  });
                                  setAttributes(updates);
                                }}
                                data={
                                  attribute.values?.map((itm) => ({
                                    id: itm,
                                    name: itm,
                                  })) ?? []
                                }
                                click={(data: { id: string; name: string }) => {
                                  const selected = attribute.values?.find(
                                    (itm) => itm === data.name
                                  );
                                  if (selected !== undefined) {
                                    const updates = attributes.map((itm) => {
                                      if (itm.name === attribute.name) {
                                        return {
                                          ...itm,
                                          selected: selected,
                                          open: false,
                                        };
                                      } else {
                                        return { ...itm, open: false };
                                      }
                                    });
                                    setAttributes(updates);
                                    setFormError(null);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {selectedCategory && !assetResults && (
                    <div className="flex flex-row items-center gap-4 w-max absolute bottom-4 right-4">
                      <button
                        onClick={(e) => {
                          setOpenSelectCategory(false);
                          setOpenSelectSubCategory(false);
                          setOpenSelectBrand(false);
                          const updatedAttributes = attributes.map((attr) => ({
                            ...attr,
                            selected: null,
                            open: false,
                          }));
                          setAttributes(updatedAttributes);
                          setSelectedBrand(null);
                          setSelectedCategory(null);
                          setSelectedSubCategory(null);
                          setFormError(null);
                        }}
                        disabled={checkingStock}
                        className="w-50 bg-gray-200 text-black px-4 py-3 rounded-md text-sm flex flex-row items-center justify-center gap-2 hover:bg-gray-300"
                      >
                        <AiOutlineClear size={20} />
                        <span>Clear form</span>
                      </button>

                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (!selectedCategory) {
                            setFormError({
                              target: "Category",
                              msg: "Please Select Category",
                            });
                          } else {
                            CheckStock();
                          }
                        }}
                        disabled={checkingStock}
                        className="w-50 bg-primary-700 text-white px-4 py-3 rounded-md text-sm flex flex-row items-center justify-center gap-2 hover:bg-primary-800"
                      >
                        {checkingStock ? (
                          <>
                            <AiOutlineLoading3Quarters className="animate-spin text-xl text-yellow-400" />
                            <span className="animate__animated animate__fadeIn animate__infinite animate__faster">
                              Please wait...
                            </span>
                          </>
                        ) : (
                          <>
                            <BsFillDatabaseFill size={20} />
                            <span>Check in the store</span>
                          </>
                        )}
                      </button>
                    </div>
                  )}

                  {formError !== null && (
                    <div className="px-1 py-3">
                      <Alert
                        alertType={AlertType.DANGER}
                        title={formError.target}
                        close={() => setFormError(null)}
                      />
                    </div>
                  )}

                  {assetResults && (
                    <div className="flex flex-col w-full gap-4 items-right text-right mt-4">
                      <div className="flex flex-row w-full justify-between gap-4 m-0 mt-4 items-end h-20 p-4 pr-16">
                        <div className="flex flex-row gap-4 items-center  w-max">
                          <span className="flex flex-col items-center w-24">
                            <span className="flex flex-row items-end justify-center">
                              <span className="text-center text-2xl font-bold w-full">
                                {assetResults?.available}
                              </span>
                              <BsFillDatabaseFill
                                size={40}
                                className="text-primary-400 text-xl"
                              />
                            </span>
                            <label className="text-xs">Available assets</label>
                          </span>
                          <span className="flex flex-col items-center w-24">
                            <span className="flex flex-row items-end justify-center">
                              <span className="text-center text-2xl font-bold w-full">
                                {assetResults?.booked}
                              </span>
                              <BsFillDatabaseFill
                                size={40}
                                className="text-gray-500 text-xl"
                              />
                            </span>
                            <label className="text-xs">Booked assets</label>
                          </span>
                          <span className="flex flex-col items-center w-24">
                            <span className="flex flex-row items-end justify-center">
                              <span className="text-center text-2xl font-bold w-full">
                                {assetResults?.assigned}
                              </span>
                              <BsFillDatabaseFill
                                size={40}
                                className="text-accent-500 text-xl"
                              />
                            </span>
                            <label className="text-xs">Assigned assets</label>
                          </span>
                        </div>
                        <span className="flex flex-col items-center w-24">
                          <div className="flex flex-row items-center justify-between h-10 gap-1">
                            <span
                              className="text-center cursor-pointer flex flex-row items-center align-middle justify-center w-max rounded-lg  bg-gray-300 px-4 py-2 hover:bg-gray-400 text-black"
                              onClick={() => {
                                if (bookedAsset > 0) {
                                  setBookedAsset(bookedAsset - 1);
                                }
                              }}
                            >
                              <BiMinus size={20} className="text-center" />
                            </span>
                            <input
                              value={bookedAsset}
                              onChange={(e) => setBookedAsset(~~e.target.value)}
                              className="text-center border border-primary-400 w-24 text-xl font-bold rounded-lg bg-gray-50 px-4 py-1"
                            />
                            <span
                              className="flex cursor-pointer flex-row justify-center border-b w-max bg-primary-300 rounded-lg font-bold px-4 py-2 hover:bg-primary-400 text-black"
                              onClick={() => {
                                if (
                                  assetResults &&
                                  bookedAsset < assetResults.available
                                ) {
                                  setBookedAsset(bookedAsset + 1);
                                } else {
                                  return;
                                }
                              }}
                            >
                              <IoMdAdd size={20} />
                            </span>
                          </div>
                          <label className="text-xs">Distribution limit</label>
                        </span>
                      </div>

                      <span
                        onClick={() => addBatchDefinition()}
                        className="bg-primary-700 cursor-pointer py-3 w-full text-center p-4 text-white text-sm rounded-lg hover:bg-primary-800"
                      >
                        Confirm
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }

  return (
    <div className="h-full w-full gap-4 p-4 flex flex-col overflow-scroll">
      <div className="flex flex-row justify-between w-full h-18 bg-white items-start gap-2 p-4 align-middle rounded-md">
        <span className="flex gap-3 justify-between items-center h-18">
          <AiOutlineUnorderedList size={40} className="text-primary-700" />
          <h2 className="text-xl font-bold">Asset Batches</h2>
        </span>
        <div className="flex flex-col align-middle gap-1 text-right">
          <span className="text-xs">Total batches</span>
          <span className="text-lg font-bold">06</span>
        </div>
      </div>

      <div className="flex flex-col w-full bg-white h-full rounded-md p-4">
        <ul className="flex flex-row items-center w-full text-sm border-b border-gray-400">
          <li
            onClick={() => {
              setFilteredBatches(allBatches);
              setSelectedStatus(null);
            }}
            className={`${
              selectedStatus === null
                ? "border-b-2 border-primary-700 text-black cursor-pointer font-bold"
                : "cursor-pointer hover:text-primary-800 text-gray-700"
            } px-4 py-2 flex flex-row items-center gap-1`}
          >
            <span>All</span>
            <span className="bg-primary-100 p-1 text-xs rounded-full">
              {commaFy(allBatches.length)}
            </span>
          </li>

          {batchStatuses.map((status, index) => (
            <li
              key={index}
              onClick={() => {
                setFilteredBatches(
                  allBatches.filter(
                    (batch) => batch.batch_status === status.Key
                  )
                );
                setSelectedStatus(status.Key);
              }}
              className={`${
                selectedStatus === status.Key
                  ? "border-b-2 border-primary-700 text-black cursor-pointer font-bold"
                  : "cursor-pointer hover:text-primary-800 text-gray-700"
              } px-4 py-2 flex flex-row items-center gap-1`}
            >
              <span>{status.Caption}</span>
              <span className="bg-primary-100 p-1 text-xs rounded-full">
                {commaFy(
                  allBatches.filter(
                    (batch) => batch.batch_status === status.Key
                  ).length
                )}
              </span>
            </li>
          ))}
        </ul>

        <div className="mt-4 grid grid-cols-3 gap-4 rounded-lg overflow-scroll">
          {filteredBatches.length > 0 &&
            filteredBatches.map((batch, index) => (
              <div
                key={index}
                onClick={() => {
                  setCurrentBatch(batch);
                  setCreateDistribution(true);
                  addBatchDefinition();
                }}
                className="animate__animated animate__bounceIn animate__faster flex flex-col gap-4 border border-accent-800 rounded-lg p-4 w-full cursor-pointer hover:bg-green-50"
              >
                <span className="flex flex-row mb-2 items-center justify-between">
                  <MdComputer size={32} className="text-gray-500" />
                  {batch.batch_status === BatchStatus.approved ? (
                    <span className="text-xs text-accent-900 p-2 bg-accent-200 rounded-lg flex flex-row gap-2 items-center">
                      <BsCheck2Circle size={12} />
                      Approved
                    </span>
                  ) : batch.batch_status === BatchStatus.draft ? (
                    <span className="text-xs p-2 bg-accent-500 rounded-md">
                      Draft
                    </span>
                  ) : (
                    <span className="text-xs p-2 bg-accent-500 rounded-md">
                      Rejected
                    </span>
                  )}
                </span>
                <h3 className="text-primary-800 text-lg font-bold w-full">
                  {batch.title}
                </h3>
                <div className="flex flex-row justify-between items-center">
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-thin">{"computer"}</span>
                    <span className="text-2xl font-bold">{"O"}</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-thin">{"Computers"}</span>
                    <span className="text-2xl font-bold">{"0"}</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-sm font-thin">{"Computers"}</span>
                    <span className="text-2xl font-bold">{"0"}</span>
                  </div>
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-sm font-thin">
                    Distribution progress
                  </span>
                  <span className="h-4 rounded-lg bg-accent-200 w-full relative">
                    <span className="h-4 rounded-lg bg-accent-500 w-1/2 absolute left-0" />
                    <span className="text-xs text-black absolute right-1 h-4">
                      40%
                    </span>
                  </span>
                </div>
              </div>
            ))}
        </div>
        {
          <div
            title="Create a new training"
            className="fixed bottom-8 right-8 h-16 w-16 flex items-center justify-center rounded-full bg-primary-800 text-white cursor-pointer hover:bg-primary-900 shadow-lg animate__animated animate__bounceIn"
            onClick={(e) => {
              e.preventDefault();
              setCreateBatchWin(true);
            }}
          >
            <MdAdd className="text-4xl" />
          </div>
        }
      </div>

      {createBatchWin && (
        <Modal
          backDrop={true}
          theme={Themes.default}
          close={() => setCreateBatchWin(false)}
          backDropClose={true}
          widthSizeClass={ModalSize.large}
          displayClose={false}
          padding={{
            title: undefined,
            body: undefined,
            footer: undefined,
          }}
        >
          <div className="flex flex-col gap-6 p-3">
            <div className="border-b border-gray-300 flex flex-row items-center p-4 gap-2 text-lg font-bold">
              <BackButton
                onClick={() => setCreateBatchWin(false)}
                title="Back"
                className="bg-primary-50 hover:bg-primary-100 text-primary-800 text-sm font-normal rounded-md"
              />
              <div>Create Batch distribution</div>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setCreateBatchWin(false);
                createNewBatch();
              }}
              className="flex flex-col gap-3 w-full text-right items-end p-3"
            >
              <label className="text-sm w-full text-left">
                Enter Batch Name:
              </label>
              <input
                placeholder="Enter batch name..."
                value={distributionName}
                onChange={(e) => setDistributionName(e.target.value)}
                className="w-full h-10 p-3 border border-blue-300 rounded-lg shadow-sm bg-gray-50"
              />
              <button
                type="submit"
                className="bg-primary-700 text-white px-4 py-2 w-max rounded-lg"
              >
                Confirm & continue
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
};
