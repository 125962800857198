import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL } from "../utils/api";
import { setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import { UserAccessList } from "../config/userAccess";

/**
 * * ****************************** INTERFACES *****************************
 */

export interface UserRoleInterface {
  access: UserAccessList[];
  role_id: string;
  role: string;
}

export interface BehaviorItemInterface {
  behavior_id: string;
  behavior_name: string;
}

export interface DivisionItem {
  division_id: string;
  division_name: string;
}

export interface TrainingOfferModeItem {
  training_offer_mode_id: string;
  offer_mode: string;
}

export interface BasicInfo {
  behavior: BehaviorItemInterface[];
  competency_classification: {
    competency_classification_id: string;
    classification_name: string;
  }[];
  competency_function: {
    competency_function_id: string;
    competency_function: string;
  }[];
  competency_type: {
    competency_type_id: string;
    competency_type: string;
  }[];
  domain: {
    competency_type_id: string;
    competency_type: string;
  }[];
  job_family: {
    job_family_id: string;
    job_family: string;
  }[];
  proficiency_level: {
    proficiency_level_id: string;
    proficiency_level: string;
  }[];
  division: DivisionItem[];
  training_offer_modes: TrainingOfferModeItem[];
}

export interface AccessListDetails {
  access_key: UserAccessList;
  access_name: string;
}

export interface SpecializationItemInterface {
  specialization_id: string;
  specialization_name: string;
}

export interface CombinationItemInterface {
  combination_code: string;
  combination_name: string;
}
export interface GradeItemInterface {
  grade_code: string;
  grade_name: string;
}
export interface CourseItemInterface {
  course_code: string;
  course_name: string;
}

export interface SchoolHierarchyInterface {
  studyhierarchyid: string;
  level_code: string;
  level_name: string;
  combination_code: string;
  combination_name: string;
  grade_code: string;
  grade_name: string;
  course_code: string;
  course_name: string;
}

export interface System {
  side_nav: boolean;
  basic_info: BasicInfo | null;
  access_details: AccessListDetails[] | null;
  error: string;
  success: string;
  roles: UserRoleInterface[] | null;
  school_hierarchy: SchoolHierarchyInterface[] | null;
  positions: PositionInterface[] | null;
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface GetSystemInfoAction {
  type: ActionTypes.GET_SYSTEM_BASIC_INFO;
  payload: BasicInfo;
}

export interface SetSystemErrorMessageAction {
  type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE;
  payload: string;
}
export interface SetSystemSuccessMessageAction {
  type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE;
  payload: string;
}

export interface GetSystemRolesAction {
  type: ActionTypes.GET_SYSTEM_ROLES;
  payload: UserRoleInterface[];
}

export interface GetSchoolHierarchyAction {
  type: ActionTypes.GET_SCHOOL_HIERARCHY;
  payload: SchoolHierarchyInterface[];
}

export interface GetAllPositionsAction {
  type: ActionTypes.GET_ALL_POSITIONS;
  payload: PositionInterface[];
}

export const USER_QUALIFICATIONS: {
  qualification_id: string;
  qualification_name: string;
}[] = [
  {
    qualification_id: "1",
    qualification_name: "A0",
  },
  {
    qualification_id: "2",
    qualification_name: "A1",
  },
  {
    qualification_id: "3",
    qualification_name: "A2",
  },
];

/**
 * * ****************************** ACTIONS *****************************
 */
/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */
export const FC_GetSystemInfo = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<BasicInfo>(`${API_URL}/data/basic`);

      console.log({ system_basic_info: res.data });

      dispatch<GetSystemInfoAction>({
        type: ActionTypes.GET_SYSTEM_BASIC_INFO,
        payload: res.data,
      });
      callback(false);
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false);
      console.log("err: ", { ...error });
    }
  };
};

export const FC_SetError = (msg: string) => {
  return async (dispatch: Dispatch) => {
    dispatch<SetSystemErrorMessageAction>({
      type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
      payload: msg,
    });
  };
};
export const FC_SetSuccess = (msg: string) => {
  return async (dispatch: Dispatch) => {
    dispatch<SetSystemSuccessMessageAction>({
      type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
      payload: msg,
    });
  };
};

export const FC_GetRoles = (
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<UserRoleInterface[]>(`${API_URL}/role`);
      console.log({ roles: res.data });
      dispatch<GetSystemRolesAction>({
        type: ActionTypes.GET_SYSTEM_ROLES,
        payload: res.data,
      });
      callback(false, {
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        msg: errorToText(error),
        type: "ERROR",
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetSchoolHierarchy = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<SchoolHierarchyInterface[]>(
        `${API_URL}/cohortcondition/studyhierarchy`
      );
      console.log({ school_hierarchy: res.data });
      dispatch<GetSchoolHierarchyAction>({
        type: ActionTypes.GET_SCHOOL_HIERARCHY,
        payload: res.data,
      });
      callback(false);
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false);
      console.log("err: ", { ...error });
    }
  };
};

export interface PositionInterface {
  position_id: string;
  position_code: string;
  position_name: string;
  school_level_name: string;
  qualification_id: string;
  qualification_name: string;
}

export const FC_GetAllPositions = (callback: (loading: boolean) => void) => {
  return async (dispatch: Dispatch) => {
    callback(true);
    setAxiosToken();
    try {
      const res = await axios.get<PositionInterface[]>(`${API_URL}/positions`);
      console.log({ positions: res.data });
      dispatch<GetAllPositionsAction>({
        type: ActionTypes.GET_ALL_POSITIONS,
        payload: res.data,
      });
      callback(false);
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false);
      console.log("err: ", { ...error });
    }
  };
};

export interface UserCourseInterface {
  combination_code: string;
  combination_name: string;
  course_code: string;
  course_name: string;
  created_date: string;
  grade_code: string;
  grade_group: string | null;
  grade_name: string;
  level_code: string;
  level_name: string;
  status: 1 | 0;
  study_hierarchy_id: string;
  studyhierarchyid: string;
  teacher_code: string;
  teacher_study_hierarchy_id: string;
}

export const FC_GetUserSubjects = async (
  user_id: string,
  callback: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: UserCourseInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<UserCourseInterface[]>(
      `${API_URL}/studyhierarchy/teacher/${user_id}`
    );
    console.log({ subjects: res.data });
    callback(false, {
      data: res.data,
      msg: "",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      data: [],
      msg: errorToText(error),
      type: "ERROR",
    });
    console.log("err: ", { ...error });
  }
};
