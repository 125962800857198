import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";
import { BatchDefinitionInterface } from "../containers/Assets/DistributionDashboard";

export enum BatchStatus {
  draft = "draft",
  pending = "pending",
  approved = "approved",
  rejected = "rejected",
  distribution = "distribution",
  completed = "completed"
}

export interface BatchInterface {
    id: string;
    title: string;
    batch_details: BatchDefinitionInterface[],
    batch_status: BatchStatus,
}

export interface SchoolsInterface {
    level_code?: string,
    school_id: number,
    school_code: number,
    school_name: string,
    school_category?: string,
    school_status: string,
    region_code?: number,
    assets_number_limit?: number,
    current_assets?: number
}

export interface AddBatchDefinitonInterface {
  id?: string,
  batch_id: string,
  assets_categories_id: string,
  assets_number_limit: number,
  assets_sub_categories_id: string,
  brand_id: string,
  specification: any
}

export interface RemainingAssetsInterface {
    assets_categories_id: string,
    assets_sub_categories_id: string,
    brand_id: string
}

export interface RemainingAssetsResInterface {
    booked: number,
    available: number,
    assigned: number
}

export interface SchoolDataInterface {
  school_code: string;
  level_code: string;
  assets_categories_id: string;
  assets_sub_categories_id: string;
  brand_id: string;
}

interface GetSchoolDataInterface {
  schools: SchoolDataInterface[];
}

export interface SchoolAssetsInterface {
  batch_id: string,
  level_code: string,
  school_code: string,
  batch_details_id: string,
  assets_number_limit: number
}

export interface DistributeAssetInterface {
  school_distribution: SchoolAssetsInterface[]
}

export interface UpdateBatchStatus {
    title: string,
    batch_status: BatchStatus,
    status: number
}

export const FC_GetRemainingAssets = async (
  data: RemainingAssetsInterface,
  callBack: (
    loading: boolean,
    res: {
      data: RemainingAssetsResInterface | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/batchdistribution/assets`, {
      assets_categories_id: data.assets_categories_id,
      assets_sub_categories_id: data.assets_sub_categories_id,
      brand_id: data.brand_id
    });
    callBack(false, {
      data: res.data,
      msg: `Success ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      data: null,
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_GetAllBatches = async (
    callBack: (
      loading: boolean,
      res: {
        data: BatchInterface[];
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.get(`${API_URL}/batchdistribution `);
      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: [],
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_GetAllSchools = async (
    callBack: (
      loading: boolean,
      res: {
        data: SchoolsInterface[];
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.get(`${API_URL}/locations/schools`);
      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: [],
        msg: error.message,
        type: "ERROR",
      });
    }
  };

export const FC_CheckStock = async (
    batch_id: string,
    assets_categories_id: string,
    callBack: (
      loading: boolean,
      res: {
        data: SchoolsInterface[];
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.get(`${API_URL}/locations/schools`);
      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: [],
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_CreateBatch = async (
    title: string,
    callBack: (
      loading: boolean,
      res: {
        data: BatchInterface | null,
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.post(`${API_URL}/batchdistribution`, {
        title: title,
        batch_details: [],
      });
      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: null,
        msg: error.message,
        type: "ERROR",
      });
    }
  };

export const FC_AddBatchDefinition = async (
    data: AddBatchDefinitonInterface,
    callBack: (
      loading: boolean,
      res: {
        data: AddBatchDefinitonInterface | null,
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.post(`${API_URL}/batchdistribution/adddefinition/${data.batch_id}`, {
          assets_categories_id: data.assets_categories_id,
          assets_number_limit: data.assets_number_limit,
          assets_sub_categories_id: data.assets_sub_categories_id,
          brand_id: data.brand_id,
          specification: data.specification
      });
      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: null,
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_GetCurrentSchoolAssets = async (
    schools: GetSchoolDataInterface,
    // data: SchoolDataInterface[],
    callBack: (
      loading: boolean,
      res: {
        data: { school_code: string; school_current_assets: number }[] | null;
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.post(`${API_URL}/assets/schoolassets`,
        schools
      );
      
      const extractedData = res.data.map((item: any) => ({
        school_code: item.school_code,
        school_current_assets: item.school_current_assets,
      }));

      callBack(false, {
        data: extractedData,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: null,
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_DistributeAssets = async (
     data: DistributeAssetInterface,
    callBack: (
      loading: boolean,
      res: {
        data: { school_code: string; school_current_assets: number }[] | null;
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.post(`${API_URL}/batchdistribution/school`,
        data
      );
      

      callBack(false, {
        data: res.data,
        msg: `Success ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: null,
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_UpdateBatchStatus = async (
    batch_id: string,
    data: UpdateBatchStatus,
   callBack: (
     loading: boolean,
     res: {
       msg: string;
       type: "SUCCESS" | "ERROR";
     } | null
   ) => void
 ) => {
   callBack(true, null);
   setAxiosToken();
 
   try {
     const res = await axios.put(`${API_URL}/batchdistribution/${batch_id}`,
       data
     );
     

     callBack(false, {
       msg: `Success ${res.data}`,
       type: "SUCCESS",
     });
   } catch (error: any) {
     callBack(false, {
       msg: error.message,
       type: "ERROR",
     });
   }
 };
 
 export const FC_GetBatchSchools = async (
  batch_id: string,
 callBack: (
   loading: boolean,
   res: {
     data: SchoolsInterface[];
     msg: string;
     type: "SUCCESS" | "ERROR";
   } | null
 ) => void
) => {
 callBack(true, null);
 setAxiosToken();

 try {
   const res = await axios.get(`${API_URL}/batchdistribution/school/${batch_id}`);
    const schools: SchoolsInterface[] = res.data.map((school: any)=>({
      level_code: school.level_code,
      school_id: school.school_code,
      school_code: school.school_code,
      school_name: school.school_name,
      school_status: school.status,
      assets_number_limit: school.assets_number_limit
    }))

   callBack(false, {
     data: schools,
     msg: `Success ${res.data}`,
     type: "SUCCESS",
   });
 } catch (error: any) {
   callBack(false, {
     data: [],
     msg: error.message,
     type: "ERROR",
   });
 }
};
