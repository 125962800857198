import { Dispatch } from "redux";
import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";
import {
  SetSystemErrorMessageAction,
  SetSystemSuccessMessageAction,
} from "./system.action";
import { ActionTypes } from "./types";
import { errorToText } from "../utils/functions";
import {
  TrainingInterface,
  TrainingOfferModeInterface,
} from "../components/TrainingItem/TrainingItem";
import { TrainingStatus } from "../containers/TrainingsList/TrainingsList";
import {
  GenderEnum,
  MartialStatusEnum,
  SchoolInterface,
  TrainingProviderInterface,
} from "./auth.action";
import FileSaver from "file-saver";
import { UserAccessList } from "../config/userAccess";
import { UploadUserInterface } from "../components/UploadTeachers/UploadedDataValidation";
import { UserRoleStatus } from "./users.action";
import { GeneralReportTypeEnum } from "../components/ElearningReport/ElearningReport";

export interface CohortInterface {
  cohortId: string;
  cohortName: string;
  cohortStart: string;
  cohortEnd: string;
  trainingId: string;
  cohorts_condition: CohortConditionsInterface[];
  courseLink: string;
}

// export interface TrainingProviderInterface {
//   address: string;
//   archive: string;
//   archivedBy: string | null;
//   archivedDate: string | null;
//   createdBy: string;
//   createdDate: string;
//   description: string;
//   email: string;
//   phone_number: string;
//   supporting_documents: string;
//   trainingProviderId: string;
//   trainingProviderName: string;
//   updatedBy: string | null;
//   updatedDate: string | null;
// }

export interface TrainingCategory {
  created_at: string;
  created_by: string;
  description: string;
  status: string;
  training_type_id: string;
  training_type_name: string;
  updated_at: string;
  updated_by: string;
}

export interface AssignTraineesDataInterface {
  teachers: {
    user_id: string;
    full_name: string;
    trainingId: string;
    cohortId: string;
    cohortconditionId: string;
    school_code: string;
    school_name: string;
    traineePhone: string;
  }[];
}

export enum InvitationType {
  DISTRICTS = "DDE",
  // SECTORS = "DDE",
  SCHOOL_LEADERS = "Head Teacher",
  TEACHERS = "Class Teacher",
}

export interface TrainingInvitationInterface {
  title: string;
  body: string;
  trainingId: string;
  status: string;
  letter_type: InvitationType; // letter_type is to discribe the letter for "DDE, Head Teacher or Class Teacher"
}

export interface TrainingStore {
  trainingProviders: TrainingProviderInterface[] | null;
  trainingCategories: TrainingCategory[] | null;
}

export interface GetAllTrainingProvidersAction {
  type: ActionTypes.GET_ALL_TRAINING_PROVIDERS;
  payload: TrainingProviderInterface[];
}

export interface GetTrainingCategoriesAction {
  type: ActionTypes.GET_TRAINING_CATEGORIES;
  payload: TrainingCategory[];
}

export const FC_GetAllTrainingsByDates = (
  data: { startDate: string; endDate: string },
  callback: (
    loading: boolean,
    res: {
      data: TrainingInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<TrainingInterface[]>(
        `${API_URL}/trainings/getall`
      );

      console.log({ trainingsList: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface CreateTrainingDataInterface {
  trainingName: string;
  trainingDescription: string;
  trainingProviderId: string;
  startDate: string;
  endDate: string;
  offerMode: TrainingOfferModeInterface;
  training_type_id: string;
}

export const FC_CreateTraining = (
  data: CreateTrainingDataInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post<TrainingInterface[]>(
        `${API_URL}/trainings/create`,
        data
      );

      console.log({ createTraining: res.data });

      callback(false, {
        msg: "Training created successfully!",
        type: "SUCCESS",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Training created successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface CreateTrainingCohortDataInterface {
  cohortName: string;
  cohortStart: string;
  cohortEnd: string;
}

export const FC_CreateTrainingCohort = (
  data: {
    data: CreateTrainingCohortDataInterface;
    trainingId: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post<TrainingInterface[]>(
        `${API_URL}/cohorts/create/${data.trainingId}`,
        data.data
      );

      console.log({ createTrainingCohort: res.data });

      callback(false, {
        msg: "Cohort added successfully!",
        type: "SUCCESS",
      });
      dispatch<SetSystemSuccessMessageAction>({
        type: ActionTypes.SET_SYSTEM_SUCCESS_MESSAGE,
        payload: "Cohort added successfully!",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllTrainingCohorts = (
  trainingId: string,
  callback: (
    loading: boolean,
    res: {
      data: CohortInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<CohortInterface[]>(
        `${API_URL}/cohorts/getall/${trainingId}`
      );

      console.log({ trainingCohorts: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface totalsByProvince {
  total: {
    available_teachers: string;
  };
  locations: {
    districtcode: string;
    namedistrict: string;
    provincecode: string;
  }[];
}

export interface totalsByDistrict {
  total: {
    available_teachers: string;
  };
  locations: {
    districtcode: string;
    namesector: string;
    sectorcode: string;
  }[];
}

export interface totalsBySector {
  total: {
    available_teachers: string;
  };
  locations: {
    sectorcode: string;
    nameCell: string;
    codecell: string;
  }[];
}

export interface totalsByCell {
  total: {
    available_teachers: string;
  };
  locations: {
    CodeVillage: string;
    VillageName: string;
    codecell: string;
  }[];
}

export interface totalsByVillage {
  total: {
    available_teachers: string;
  };
  locations: {
    school_id: string;
    school_code: string;
    school_name: string;
    school_category: string;
    school_status: string;
    region_code: string;
  }[];
}

export interface totalsBySchool {
  total: {
    available_teachers: string;
  };
  locations: {
    user_id: string;
    first_name: string;
    last_name: string;
  }[];
}

export interface TrainerData {
  trainers_id: string;
  staff_code: string;
  staff_category_id: null | number;
  full_name: string;
  sex: string;
  dob: null | string;
  marital_status: null | string;
  nid: string;
  email: string;
  phone_numbers: string;
  rssb_number: null | string;
  education_domain_id: null | number;
  education_sub_dommain_id: null | number;
  graduation_date: null | string;
  highest_qualification_id: null | number;
  hired_date: null | string;
  contract_type: null | string;
  bank_account: null | string;
  nationality_id: string;
  province_code: null | string;
  district_code: null | string;
  sector_code: null | string;
  cell_code: null | string;
  village_id: null | string;
  village: null | string;
  school_code: null | string;
  school_name: null | string;
  user_id: string;
  username: string;
  password_verication_code: null | string;
  bank_id: null | string;
  disability: "true" | "false";
  resident_district_id: string;
  resident_district_name: null | string;
  disability_surpoting_document: null | string;
  resident_surpoting_document: null | string;
  photo_url: null | string;
  created_by: string;
  created_at: string;
  updated_by: null | string;
  updated_at: string;
  archive: "0" | "1";
  archive_by: null | string;
  archive_at: null | string;
  status: "1";
  first_name: string;
  middle_name: string;
  last_name: string;
  specialization_id: null | number;
  village_code: null | string;
  others: null | string;
}

export const FC_GetTotalTeachersByProvince = (
  province_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsByProvince | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsByProvince>(
        `${API_URL}/locations/getprovince/${province_code}`
      );

      console.log({ teachersByProvince: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalTeachersByDistrict = (
  district_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsByDistrict | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsByDistrict>(
        `${API_URL}/locations/getdistricts/${district_code}`
      );

      console.log({ teachersByDistrict: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalTeachersBySector = (
  sector_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsBySector | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsBySector>(
        `${API_URL}/locations/getsectors/${sector_code}`
      );

      console.log({ teachersBySector: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalTeachersByCell = (
  cell_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsByCell | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsByCell>(
        `${API_URL}/locations/getcells/${cell_code}`
      );

      console.log({ teachersByCell: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalTeachersByVillage = (
  village_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsByVillage | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsByVillage>(
        `${API_URL}/locations/getvillages/${village_code}`
      );

      console.log({ teachersByVillage: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTotalTeachersBySchool = (
  school_code: string,
  callback: (
    loading: boolean,
    res: {
      data: totalsBySchool | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<totalsBySchool>(
        `${API_URL}/locations/getperschool/${school_code}`
      );

      console.log({ teachersBySchool: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: null,
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface CohortConditionsInterface {
  cohortconditionId: string;
  cohortId: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string | null;
  updatedDate: string | null;
  archive: "0" | "1";
  archivedBy: string | null;
  archivedDate: string | null;
  provincecode: string | null;
  district_code: string | null;
  sector_code: string | null;
  school_code: string | null;
  combination_code: string | null;
  grade_code: string | null;
  course_name: string | null;
  comfirmed: "1" | "0";
  capacity: string;
  approval_role_id: string;
  providedTrainees: string;
  provincename: string;
  district_name: string;
  sector_name: string;
  school_name: string;
  combination_name: string;
  grade_name: string;
}

export interface TrainingCenter {
  training_centers_name: string;
  district_code: number;
  district_name: string;
  training_centers_id: string;
}

export const FC_GetCohortConditions = (
  cohort_id: string,
  callback: (
    loading: boolean,
    res: {
      data: CohortConditionsInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<CohortConditionsInterface[]>(
        `${API_URL}/cohortcondition/getall/${cohort_id}`
      );

      console.log({ cohortConditions: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface SubmitConditionData {
  conditions: string;
  location: [
    { provinceCode: string; provinceName: string },
    { districtCode: string; districtName: string },
    { sectorCode: string; sectorName: string },
    { cellCode: string; cellName: string },
    { villageCode: string; villageName: string },
    { schoolCode: string; schoolName: string }
  ];
  availabletrainees: string;
  limit: string;
  trainingId: string;
}

export const FC_AddCohortConditions = (
  cohort_id: string,
  data: SubmitConditionData,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(
        `${API_URL}/cohortcondition/create/${cohort_id}`,
        data
      );

      console.log({ addCohortConditions: res.data });

      callback(false, {
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export enum TraineeStatus {
  Shortlisted = "Shortlisted",
  Approved = "Approved",
  Invited = "Invited",
  Rejected = "Rejected",
}

export interface TraineeInterface {
  combination_name: string;
  course_name: string;
  district_code: string;
  full_name: string;
  phone_numbers: string;
  grade_name: string;
  school_code: string;
  school_name: string;
  sector_code: string | null;
  staff_code: string;
  status: string;
  teacher_study_hierarchy_id: string;
  teacher_code: string;
  user_id: string;
  dob: string;
  marital_status: MartialStatusEnum;
  nid: string;
  sex: GenderEnum;
  trained: boolean;
}

export interface AssignedTraineeInterface {
  cohortId: string;
  conditionId: string;
  district_code: string;
  district_name: string;
  dob: string;
  marital_status: MartialStatusEnum;
  nid: string;
  school_code: string;
  school_name: string;
  sector_code: string;
  sector_name: string;
  sex: GenderEnum;
  course_id: string | null;
  traineeName: string;
  traineePhone: string;
  traineesId: string;
  trainingId: string;
  userId: string;
  staff_code: string;
  role_id: string;
  qualification_id: string;
  position_code: string;
  status: UserRoleStatus;
  email: string;
}

export const FC_GetTrainingParticipantsByCohortId = (
  cohort_id: string,
  callback: (
    loading: boolean,
    res: {
      data: AssignedTraineeInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<AssignedTraineeInterface[]>(
        `${API_URL}/cohortcondition/gettrainees/${cohort_id}`
      );

      console.log({ cohortParticipants: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_AssignTraineesToTrainingCohort = (
  cohort_id: string,
  data: AssignTraineesDataInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post(
        `${API_URL}/cohortcondition/approveselected/${cohort_id}`,
        data
      );

      console.log({ assignTrainees: res.data });

      callback(false, {
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_UpdateTrainingStatus = async (
  trainingId: string,
  newStatus: TrainingStatus,
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/trainings/comfirm/${trainingId}`, {
      status: newStatus,
    });
    callBack(false, { msg: `Updated ${res.data}`, type: "SUCCESS" });
  } catch (error: any) {
    callBack(false, { msg: error.message, type: "ERROR" });
  }
};

export const FC_FindTrainers = async (
  trainerInput: string,
  callBack: (
    loading: boolean,
    res: {
      data: TrainerData | null;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  const res = await axios.get(
    `${API_URL}/user/account/profile/${trainerInput}`
  );
  if (res.data.length > 0) {
    callBack(false, {
      data: res.data[0],
      msg: "trainer found",
      type: "SUCCESS",
    });
  } else {
    callBack(false, {
      data: null,
      msg: "Error",
      type: "ERROR",
    });
  }
};

export const FC_CreateTrainingCenter = async (
  trainingCenter: TrainingCenter,
  callBack: (
    loading: boolean,
    res: {
      id: string;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/trainingcenter `, {
      training_centers_name: trainingCenter.training_centers_name,
      district_code: trainingCenter.district_code,
      district_name: trainingCenter.district_name,
    });
    callBack(false, {
      id: res.data.training_centers_id,
      msg: `Updated ${res}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, { id: "", msg: error.message, type: "ERROR" });
  }
};

export const FC_AssignTrainingCenter = async (
  data: { trainingId: string; trainingCenterId: string },
  callBack: (
    loading: boolean,
    res: {
      id: string;
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.put(`${API_URL}/trainingcenter/assign `, {
      training_id: data.trainingId,
      training_centers_id: data.trainingCenterId,
    });
    callBack(false, {
      id: res.data.id,
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, { id: "", msg: error.message, type: "ERROR" });
  }
};

export const FC_GetTrainingCenterByTraining = async (
  trainingId: string,
  callBack: (
    loading: boolean,
    res: {
      data: any;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(
      `${API_URL}/trainingcenter/training/${trainingId} `
    );
    callBack(false, {
      data: res.data,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      data: error.message,
      type: "ERROR",
    });
  }
};

export const FC_RemoveTrainingCenterByTraining = async (
  data: {
    training_centers_id: string;
    training_id: string;
  },
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.put(`${API_URL}/trainingcenter/unassign `, {
      training_centers_id: data.training_centers_id,
      training_id: data.training_id,
    });
    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export interface GetSchoolConditionData {
  provincecode: string;
  district_code: string;
  sector_code: string;
  school_code: string;
}

export const FC_GetSchoolsByCondition = (
  condition: GetSchoolConditionData,
  callback: (
    loading: boolean,
    res: {
      data: SchoolInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post<SchoolInterface[]>(
        `${API_URL}/cohortcondition/school`,
        condition
      );

      console.log({ schools_list: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export interface CohortConditionData {
  provincecode: string;
  provincename: string;
  district_code: string;
  district_name: string;
  sector_code: string;
  sector_name: string;
  school_code: string;
  school_name: string;
  combination_code: string;
  combination_name: string;
  grade_code: string;
  grade_name: string;
  course_name: string;
  comfirmed: 1 | 0; // Required
  capacity: number; // Limit
  cohortId: string;
  approval_role_id: string;
}

export const FC_SubmitTrainingCohortCondition = (
  condition_data: CohortConditionData,
  callback: (
    loading: boolean,
    res: {
      data: TraineeInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  const urlPath =
    condition_data.comfirmed === 1
      ? `${API_URL}/cohortcondition/create/1`
      : `${API_URL}/cohortcondition/condition`;
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.post<TraineeInterface[]>(urlPath, condition_data);

      console.log({ schools_list: res.data });

      callback(false, {
        data: res.data,
        msg: "",
        type: "SUCCESS",
      });
    } catch (error: any) {
      dispatch<SetSystemErrorMessageAction>({
        type: ActionTypes.SET_SYSTEM_ERROR_MESSAGE,
        payload: errorToText(error),
      });
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
        data: [],
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetAllTrainingCategories = (
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<TrainingCategory[]>(
        `${API_URL}/trainingtype`
      );
      dispatch<GetTrainingCategoriesAction>({
        type: ActionTypes.GET_TRAINING_CATEGORIES,
        payload: res.data,
      });
      console.log({ trainingCategories: res.data });
    } catch (error: any) {
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};

export const FC_GetTrainingTrainerTraining = async (
  trainingId: string,
  callBack: (
    loading: boolean,
    res: {
      data: any;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(`${API_URL}/trainer/training/${trainingId} `);
    callBack(false, {
      data: res.data,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      data: error.message,
      type: "ERROR",
    });
  }
};

export const FC_AssignTrainingTrainer = async (
  data: {
    user_id: string | undefined;
    training_id: string;
    role_id: string;
  },
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/trainer `, {
      user_id: data.user_id,
      training_id: data.training_id,
      role_id: data.role_id,
    });

    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_RemoveTrainerToTraining = async (
  data: {
    trainers_id: string;
    training_id: string;
  },
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.put(`${API_URL}/trainer/${data.trainers_id}`, {
      status: "0",
      training_id: data.training_id,
    });
    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export interface TrainingCategoryData {
  training_type_name: string;
  description: string;
}

export const FC_CreateTrainingCategory = async (
  data: TrainingCategoryData,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post(`${API_URL}/trainingtype`, data);
    callback(false, {
      msg: "Category created successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
    console.log("err: ", { ...error });
  }
};

export const FC_ConnectToElearning = async (
  course_id: string,
  cohort_id: string,
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      data: any;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(
      `${API_URL}/elearning/connectCourse/${course_id}/${cohort_id} `
    );
    console.log("Course: ", res.data);
    callBack(false, {
      data: res.data,
      msg: "",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      data: null,
      type: "ERROR",
    });
  }
};

export const FC_GetTrainingCohortInvitations = async (
  cohortId: string,
  callBack: (
    loading: boolean,
    res: {
      data: TrainingInvitationInterface[];
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get<TrainingInvitationInterface[]>(
      `${API_URL}/invitation/training/${cohortId}`
    );
    console.log("Training cohort invitations: ", res.data);
    callBack(false, {
      data: res.data,
      type: "SUCCESS",
      msg: "",
    });
  } catch (error: any) {
    callBack(false, {
      data: [],
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface CreateInvitationData {
  title: string;
  body: string;
  trainingId: string;
  cohort_id: string;
  letter_type: "Head Teacher" | "Class Teacher" | "DDE";
}

export const FC_CreateTrainingCohortInvitation = async (
  data: CreateInvitationData,
  callBack: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/invitation`, data);
    console.log("Create Training Cohort Invitations: ", res.data);
    callBack(false, {
      type: "SUCCESS",
      msg: "Created successfully",
    });
  } catch (error: any) {
    callBack(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_GetTrainingCohortInvitationDocuments = async (
  cohortId: string,
  callBack: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      downloadUrl: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();
  try {
    const resp = await axios.get(`${API_URL}/invitation/letter/${cohortId}`, {
      responseType: "blob",
    });

    // create temp download url
    const downloadUrl = window.URL.createObjectURL(resp.data);

    // Download the PDF file
    FileSaver.saveAs(downloadUrl, "Invitation letters.pdf");

    // open pdf file on new tab
    // window.open(downloadUrl, "__blank");

    // remove temp url
    // window.URL.revokeObjectURL(downloadUrl);

    callBack(false, {
      type: "success",
      msg: "Invitation letter has been downloaded!",
      downloadUrl: downloadUrl,
    });

    // return resp;
  } catch (e: any) {
    callBack(false, {
      type: "error",
      msg:
        e.response?.status !== undefined && e.response?.status === 404
          ? "No training invitations found! It means that the training is missing some contents"
          : errorToText(e),
      downloadUrl: "",
    });
  }
};

export interface SchoolFocalTeachers {
  schoolCode: string;
  teachers: {
    staff_code: string;
    full_name: string;
    custom_roles: UserAccessList | null;
  }[];
}

export const FC_GetSchoolsFocalTeachers = async (
  schools: {
    cohort_id: string;
    schools: string[];
  },
  callBack: (
    loading: boolean,
    res: {
      data: SchoolFocalTeachers[];
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post<SchoolFocalTeachers[]>(
      `${API_URL}/ictfocalteachers/getCandidates`,
      schools
    );
    console.log("School Focal teachers: ", res.data);
    callBack(false, {
      data: res.data,
      type: "SUCCESS",
      msg: "",
    });
  } catch (error: any) {
    callBack(false, {
      data: [],
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_AssignSchoolFocalTeacher = async (
  data: {
    cohort_id: string;
    school_code: string;
    staff_code: string;
  },
  callBack: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post<SchoolFocalTeachers[]>(
      `${API_URL}/ictfocalteachers/addFocalTeacher`,
      data
    );
    console.log("Assign Focal teacher: ", res.data);
    callBack(false, {
      type: "SUCCESS",
      msg: "Focal Teacher Assigned Successfully!",
    });
  } catch (error: any) {
    callBack(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_AssignSchoolSSL = async (
  data: {
    cohort_id: string;
    school_code: string;
    staff_code: string;
  },
  callBack: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post<SchoolFocalTeachers[]>(
      `${API_URL}/ictfocalteachers/addSSL`,
      data
    );
    console.log("Assign SSL teacher: ", res.data);
    callBack(false, {
      type: "SUCCESS",
      msg: "SSL Assigned Successfully!",
    });
  } catch (error: any) {
    callBack(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_CheckSchoolFocalTeacher = async (
  data: {
    cohort_id: string;
    school_code: string;
    staff_code: string;
  },
  callBack: (
    loading: boolean,
    res: {
      type: "SUCCESS" | "ERROR";
      msg: string;
      data: { custom_role: UserAccessList }[];
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post<{ custom_role: UserAccessList }[]>(
      `${API_URL}/ictfocalteachers/checkFocalTeacher`,
      data
    );
    console.log("Check Focal teacher: ", res.data);
    callBack(false, {
      type: "SUCCESS",
      msg: "Focal Teacher",
      data: res.data,
    });
  } catch (error: any) {
    callBack(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_UploadTeachersBulkEnrolment = async (
  data_validated: {
    cohort_id: string;
    teachers: UploadUserInterface[];
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  console.log("SUBMITTED DATA: ", data_validated);
  try {
    const res = await axios.post(
      `${API_URL}/bulkenroll/bulkenroll`,
      data_validated
    );

    console.log({ uploadTeachersList: res.data });
    callback(false, {
      msg: "Teachers Uploaded Successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_GetTempBulkEnrolment = async (
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: UploadUserInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<UploadUserInterface[] | null>(
      `${API_URL}/bulkenroll/retrievedata`
    );

    console.log({ tempData: res.data });
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data ?? [],
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_TraineeEnroll = async (
  data: {
    course_code: string;
    staff_code: string;
    cohort_name: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: {
        staff_code: string;
        message: "enrolled" | "not_enrolled";
        reason: string;
      } | null;
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.post<{
      staff_code: string;
      message: "enrolled" | "not_enrolled";
      reason: string;
    }>(`${API_URL}/elearning/enrollToCourse`, {
      staff_code: data.staff_code,
      course_id: data.course_code,
      cohort_name: data.cohort_name,
    });

    console.log({ uploadTeachersList: res.data });
    callback(false, {
      msg: "Trainees Enrolled Successfully!",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: null,
    });
  }
};

export interface RegisterUserCohortInterface {
  staff_code: string | null;
  role_id: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  full_name: string;
  phone_numbers: string;
  email: string;
  nid: string;
  gender: GenderEnum;
  username: string;
  resident_district_id: string;
  addToTraining: boolean;
  cohortId: string | null;
  trainingId: string | null;
  cohortconditionId: string | null;
  school_code: string | null;
  dob: string;
}

export const FC_RegisterUserToCohort = async (
  data: RegisterUserCohortInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: { user_id: string } | null;
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  console.log("SUBMITTED DATA: ", data);
  try {
    const res = await axios.post<{
      message: string;
      user_id: string;
      results: number;
    }>(`${API_URL}/user/account/create`, data);

    console.log({ registeredUser: res.data });
    callback(false, {
      msg: "User added Successfully!",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: null,
    });
  }
};

export const FC_GetSchoolsList = async (
  data: {
    province_code: string;
    district_code: string;
    sector_code: string | null;
    cell_code: string | null;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: SchoolInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.post<SchoolInterface[]>(
      `${API_URL}/schoollocation/location`,
      data
    );

    console.log({ schoolsList: res.data });
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data ?? [],
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_CleanUploadedUsersJson = async (
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.delete(`${API_URL}/bulkenroll/deletejson`);

    console.log({ removeJSON: res.data });
    callback(false, {
      msg: "Data cleaned successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface CoPReportDetailsInterface {
  cop_report_details_id: string;
  cop_report_id: string;
  cop_report_details_title: string;
  start_date: string;
  end_date: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

export interface CoPReportInterface {
  cop_report_id: string;
  cop_report_title: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  status: string;
  cop_reports_details: CoPReportDetailsInterface[];
  trainingId: string;
}

export interface SubmittedReportDataInterface {
  cohortsId: string;
  report_id: string;
  cop_report_details_id: string;
  district_code: string;
  sector_code: string;
  school_code: string;
  district_name: string;
  sector_name: string;
  school_name: string;
  meeting_date: string;
  course: string;
  course_summary: string;
  meeting_benefits: string;
  meeting_drawback: string;
  meeting_strategy: string;
  drawback_to_submit_at_school: string;
  next_meeting_date: string;
  next_meeting_superviser: string;
  meeting_attendance: string;
  meeting_supervisor: string;
  meeting_supervisor_occupation: string;
  created_at: string;
  created_by: string;
}

export interface SSLCoPReportDetailsInterface {
  cop_report_details_id: string;
  cop_report_id: string;
  cop_report_details_title: string;
  start_date: string;
  end_date: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  reports: SubmittedReportDataInterface[];
}

export interface SSLCoPReportInterface {
  cop_report_id: string;
  cohortId: string;
  cop_report_title: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  status: string;
  cop_reports_details: SSLCoPReportDetailsInterface[];
}

export const FC_GetCOPReportsTemplates = async (
  cohort_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: CoPReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<CoPReportInterface[]>(
      `${API_URL}/copreports/cohort/${cohort_id}`
    );

    console.log({ schoolsList: res.data });
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data ?? [],
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface SubmitCoPReportDataInterface {
  cohortsId: string;
  cop_report_details_id: string;
  district_code: string;
  sector_code: string;
  school_code: string;
  meeting_date: string; // Itariki igaragaza igihe inama y’ihuriro yabereyeho
  course: string; // Inyingisho
  course_summary: string; // Imbumbanyigisho
  meeting_benefits: string; // Ibyagenze neza mu nama y’ihuriro
  meeting_drawback: string; // Ibikeneye kunozwa cyangwa n’imbogamizi zagaragaye
  meeting_strategy: string; // Ingamba zifashishijwe mu gukemura imbogamizi
  drawback_to_submit_at_school: string; // Ibindi: Ibibazo bitashoboye gukemuka biza gushyikirizwa umuyobozi w’ikigo k’ishuri
  next_meeting_date: string; // Itariki y’inama y’ubutaha,  and can be null
  next_meeting_superviser: string; // Umuyobozi w’inama y’ubutaha,  cam be null, use full_name for now
  meeting_attendance: AssignedTraineeInterface[]; // Urutonde rw’abitabiriye inama y’ihuriro n’umukono
  meeting_supervisor: string; // Amazina y’uwayoboye inama n’icyo akora, use full_name for now // user_id & role
  meeting_supervisor_occupation: string; // icyo akora // Place role
}

export const FC_CreateSubmissionCoPReport = async (
  data: SubmitCoPReportDataInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.post(`${API_URL}/copreports/reports`, data);

    console.log({ CreateCoP: res.data });
    callback(false, {
      msg: "CoP Report Submitted Successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_GetCOPReportsBySSL = async (
  cohort_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: SSLCoPReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<SSLCoPReportInterface[]>(
      `${API_URL}/copreports/user/${cohort_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_CreateCopReportGroup = async (
  data: {
    trainingId: string;
    cop_report_title: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post(`${API_URL}/copreports`, data);
    callback(false, {
      msg: "Report group created successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_CreateCopReportDetails = async (
  data: {
    cop_report_details_title: string;
    cop_report_id: string;
    start_date: string;
    end_date: string;
    cohortId: string;
    trainingId: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post(`${API_URL}/copreports/details`, data);
    callback(false, {
      msg: "Report details created successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface SSLReportInterface {
  cohortsId: string;
  report_id: string;
  cop_report_details_id: string;
  district_code: string;
  sector_code: string;
  school_code: string;
  district_name: string;
  sector_name: string;
  school_name: string;
  meeting_date: string;
  course: string;
  course_summary: string;
  meeting_benefits: string;
  meeting_drawback: string;
  meeting_strategy: string;
  drawback_to_submit_at_school: string;
  next_meeting_date: string;
  next_meeting_superviser: string;
  meeting_attendance: {
    nid: string;
    sex: string;
    email: string;
    gender: GenderEnum;
    status: string;
    userId: string;
    role_id: string;
    cohortId: string;
    course_id: string | null;
    full_name: string;
    staff_code: string;
    traineesId: string;
    trainingId: string;
    conditionId: string;
    school_code: string;
    school_name: string;
    sector_code: string;
    sector_name: string;
    traineeName: string;
    phone_number: string;
    traineePhone: string;
    district_code: string;
    district_name: string;
    position_code: string | null;
    qualification_id: string;
  }[];
  meeting_supervisor: string;
  meeting_supervisor_occupation: string;
  created_at: string;
  created_by: string;
}

export const FC_GetCoPReportsSubmittedBySSLs = async (
  cop_report_details_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: SSLReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<SSLReportInterface[]>(
      `${API_URL}/copreports/reports/${cop_report_details_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface ModuleUnitInterface {
  unit_id: string;
  unit_name: string;
  progress: string;
  grade: string;
  timespent: string;
}

export interface ModuleInterface {
  module_id: string;
  module_name: string;
  progress: string;
  grade: string;
  timespent: string;
  unit: ModuleUnitInterface[];
}

export interface ProgressReportInterface {
  generalReportId: string;
  traineeId: string;
  userId: string;
  traineeName: string;
  traineePhone: string;
  staff_code: string;
  cohortId: string;
  moduleId: string;
  moduleName: string;
  chapterId: string;
  chapterName: string;
  courseNavigation: string;
  endOfChapter: string;
  selfAssesment: string;
  endOfModule: string;
  endOfCourse: string;
  copMarks: string;
  reflectionNotes: string;
  classroomApplication: string;
  selfStudy: string;
  coaching: string;
  age: string | null;
  gender: GenderEnum | null;
  disability: "false" | "true";
  district_code: string;
  district_name: string;
  sector_code: string;
  sector_name: string;
  school_code: string;
  school_name: string;
  trainingId: string;
  created_date: string;
}

export const FC_GetGeneralReportDetails = async (
  trainingId: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: ProgressReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<ProgressReportInterface[]>(
      `${API_URL}/generalreport/getAll`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface CreateMessageDataInterface {
  messages_title: string;
  messages_body: string;
  send_by: string; // organisation name who sent message
  message_type: string; // SMS, WHATSAPP
}

export const FC_CreateMessage = async (
  data: CreateMessageDataInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post(`${API_URL}/notification/sms/create`, data);
    callback(false, {
      msg: "Message created successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface SendMessageInterface {
  messages_id: string;
  receivers: {
    full_name: string;
    email: string | null; // can be null if not exists
    phone_number: string;
  }[];
}

export const FC_SendMessage = async (
  data: SendMessageInterface,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post(`${API_URL}/notification/sms/send`, data);
    callback(false, {
      msg: "Message sent successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface MessageReceiverInterface {
  messages_receivers_id: string;
  messages_id: string;
  full_name: string;
  email: string;
  phone_number: string;
  messages_send_id: string;
  messages_send_success: string;
  messages_send_status: string;
  status: "1" | "0";
  create_at: string;
  updated_at: string;
}

export const FC_ResendMessage = async (
  messages_receivers_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: MessageReceiverInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<MessageReceiverInterface[]>(
      `${API_URL}/notification/sms/resendsms/${messages_receivers_id}`
    );
    callback(false, {
      msg: "Resend Successfully!",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_GetMessageReceivers = async (
  message_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: MessageReceiverInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<MessageReceiverInterface[]>(
      `${API_URL}/notification/sms/receivers/${message_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface MessageInterface {
  messages_id: string;
  messages_title: string;
  messages_body: string;
  send_by: string;
  message_type: string;
  created_by: string;
  status: string;
  created_at: string;
  updated_at: string;
}

export const FC_GetMessagesList = async (
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: MessageInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<MessageInterface[]>(
      `${API_URL}/notification/sms`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_GetHTTraineesGeneralReport = async (
  training_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: ProgressReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<ProgressReportInterface[]>(
      `${API_URL}/generalreport/getPerTraining/${training_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export const FC_AddHTMarks = async (
  data: {
    userId: string;
    cohortId: string;
    chapterId: string;
    markType: GeneralReportTypeEnum;
    marks: string;
  },
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.post<ProgressReportInterface[]>(
      `${API_URL}/generalreport/mark`,
      data
    );
    callback(false, {
      msg: "Marks added successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_DownloadCohortTrainingCertificates = async (
  cohortId: string,
  certificate_name: string,
  callBack: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      downloadUrl: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();
  try {
    const resp = await axios.get(
      `${API_URL}/traineers/certificate/${cohortId}`,
      {
        responseType: "blob",
      }
    );
    // create temp download url
    const downloadUrl = window.URL.createObjectURL(resp.data);
    // Download the PDF file
    FileSaver.saveAs(downloadUrl, `${certificate_name}.pdf`);
    callBack(false, {
      type: "success",
      msg: "Certificates has been downloaded!",
      downloadUrl: downloadUrl,
    });

    // return resp;
  } catch (e: any) {
    callBack(false, {
      type: "error",
      msg:
        e.response?.status !== undefined && e.response?.status === 404
          ? "Certificates not found!"
          : errorToText(e),
      downloadUrl: "",
    });
  }
};

export const FC_DownloadIndividualTrainingCertificates = async (
  staff_code: string,
  cohort_id: string,
  certificate_name: string,
  callBack: (
    loading: boolean,
    res: {
      type: "success" | "error";
      msg: string;
      downloadUrl: string;
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();
  try {
    const resp = await axios.get(
      `${API_URL}/traineers/traineecertificate/${staff_code}/${cohort_id}`,
      {
        responseType: "blob",
      }
    );
    // create temp download url
    const downloadUrl = window.URL.createObjectURL(resp.data);
    // Download the PDF file
    FileSaver.saveAs(downloadUrl, `${certificate_name}.pdf`);
    callBack(false, {
      type: "success",
      msg: "Certificates has been downloaded!",
      downloadUrl: downloadUrl,
    });

    // return resp;
  } catch (error: any) {
    callBack(false, {
      type: "error",
      msg:
        error.response?.status !== undefined && error.response?.status === 404
          ? "Certificates not found!"
          : error.response?.status !== undefined &&
            error.response?.status === 400
          ? "Certificate can not be downloaded, You failed to reach the pass mark of 60%"
          : errorToText(error),
      downloadUrl: "",
    });
  }
};

export const FC_GetReportDetailsForQRCode = async (
  staff_code: string,
  cohort_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: ProgressReportInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<ProgressReportInterface[]>(
      `${API_URL}/generalreport/getPerTrainee/${staff_code}/${cohort_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface TrainingSignatureInterface {
  Signator_id: string;
  user_id: string;
  director_name: string;
  director_role: string;
  director_institution: string;
  director_signature_url: string;
  training_id: string;
  status: string;
  created_at: string;
  updated_at: string;
  position: string;
}

export const FC_GetTrainingSignatures = async (
  training_id: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
      data: TrainingSignatureInterface[];
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    const res = await axios.get<TrainingSignatureInterface[]>(
      `${API_URL}/signature/training/${training_id}`
    );
    callback(false, {
      msg: "",
      type: "SUCCESS",
      data: res.data,
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
      data: [],
    });
  }
};

export interface SignatureUploadData {
  user_id: string;
  director_name: string;
  director_role: string;
  director_institution: string;
  training_id: string;
  director_signature_url: File;
  position: string;
}

export const FC_UploadSignature = async (
  data: SignatureUploadData,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  const formData = new FormData();
  formData.append("user_id", data.user_id);
  formData.append("director_institution", data.director_institution);
  formData.append("director_name", data.director_name);
  formData.append("director_role", data.director_role);
  formData.append("director_signature_url", data.director_signature_url);
  formData.append("training_id", data.training_id);
  formData.append("position", data.position);
  try {
    await axios.post(`${API_URL}/signature`, formData);
    callback(false, {
      msg: "E-Signature added successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export const FC_UpdateSignature = async (
  signator_id: string,
  director_signature_url: File,
  position: string,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  const formData = new FormData();
  formData.append("director_signature_url", director_signature_url);
  formData.append("position", position);
  try {
    await axios.post(`${API_URL}/signature/signature/${signator_id}`, formData);
    callback(false, {
      msg: "E-Signature updated successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface UpdateGeneralReportMarks {
  staff_code: string;
  cohortId: string;
  moduleId: string;
  chapterId: string;
  courseNavigation: number;
  endOfChapter: number;
  selfAssesment: number;
  endOfModule: number;
  endOfCourse: number;
  copMarks: number;
  reflectionNotes: number;
  classroomApplication: number;
  selfStudy: number;
  coaching: number;
}

export const FC_UpdateGeneralReportMarks = async (
  general_report_id: string,
  data: UpdateGeneralReportMarks,
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callback(true, null);
  setAxiosToken();
  try {
    await axios.patch(
      `${API_URL}/generalreport/updatechaptermarks/${general_report_id}`,
      data
    );
    callback(false, {
      msg: "Marks Updated Successfully!",
      type: "SUCCESS",
    });
  } catch (error: any) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};
