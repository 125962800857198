import React, { Component } from "react";
import {
  CohortInterface,
  DistrictLocationItem,
  FC_GetLocationsDetails,
  FC_RegisterUserToCohort,
  GenderEnum,
  LocationAPI,
  UserInfoDataFoundInterface,
  UserRoleInterface,
} from "../../actions";
import Input from "../Fragments/Input";
import Alert, { AlertType } from "../Alert/Alert";
import { MdKeyboardArrowDown, MdOutlineModeEdit } from "react-icons/md";
import Select from "../Fragments/Select";
import SelectCustom from "../SelectCustom/SelectCustom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoPersonAddOutline } from "react-icons/io5";

interface RegisterFormProps {
  onSuccess: (data: {
    user_id: string;
    info: UserInfoDataFoundInterface;
  }) => void;
  role: UserRoleInterface;
  currentData: UserInfoDataFoundInterface | null;
  cohort: CohortInterface;
  cohortconditionId: string;
  training_id: string;
  cohortSchoolCode: string;
  addToTraining: boolean;
}
interface RegisterFormState {
  loading: boolean;
  success: string;
  error: {
    target:
      | "main"
      | "first_name"
      | "last_name"
      | "nid"
      | "phone_numbers"
      | "email"
      | "role"
      | "gender"
      | "district"
      | "username"
      | "resident_district_id"
      | "dob";
    msg: string;
  } | null;
  staff_code: string | null;
  first_name: string;
  last_name: string;
  middle_name: string;
  full_name: string;
  phone_numbers: string;
  email: string;
  nid: string;
  gender: GenderEnum;
  dob: string;
  username: string;
  resident_district_id: {
    district_code: string;
    district_name: string;
  } | null;
  locations: LocationAPI | null;
  openChooseDistrict: boolean;
}

export class RegisterForm extends Component<
  RegisterFormProps,
  RegisterFormState
> {
  constructor(props: RegisterFormProps) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      success: "",
      email:
        this.props.currentData !== null ? this.props.currentData.email : "",
      first_name:
        this.props.currentData !== null
          ? this.props.currentData.first_name
          : "",
      middle_name:
        this.props.currentData !== null
          ? this.props.currentData.middle_name
          : "",
      gender:
        this.props.currentData !== null
          ? this.props.currentData.sex
          : GenderEnum.MALE,
      last_name:
        this.props.currentData !== null ? this.props.currentData.last_name : "",
      nid: this.props.currentData !== null ? this.props.currentData.nid : "",
      phone_numbers:
        this.props.currentData !== null
          ? this.props.currentData.phone_numbers
          : "",
      full_name:
        this.props.currentData !== null ? this.props.currentData.full_name : "",
      resident_district_id:
        this.props.currentData !== null
          ? {
              district_code: this.props.currentData.resident_district_id,
              district_name:
                this.props.currentData.resident_district_name ?? "",
            }
          : null,
      staff_code:
        this.props.currentData !== null
          ? this.props.currentData.staff_code
          : null,
      username:
        this.props.currentData !== null ? this.props.currentData.username : "",
      locations: null,
      openChooseDistrict: false,
      dob:
        this.props.currentData !== null && this.props.currentData.dob !== null
          ? this.props.currentData.dob
          : "",
    };
  }
  GetLocations = () => {
    this.setState({ loading: true });
    FC_GetLocationsDetails(
      (
        loading: boolean,
        res: {
          data: LocationAPI | null;
          type: "Error" | "Success";
          msg: string;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "Success") {
          this.setState({ locations: res.data, loading: false });
          if (this.props.currentData !== null) {
            const selectedDistrict = this.state.locations?.districts.find(
              (itm) =>
                itm.district_code.toString() ===
                this.props.currentData?.resident_district_id.toString()
            );
            if (selectedDistrict !== undefined) {
              this.setState({ resident_district_id: selectedDistrict });
            }
          }
        }
      }
    );
  };
  onSubmitData = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.first_name === "") {
      return this.setState({
        error: { target: "first_name", msg: "First name is required" },
      });
    }
    if (this.state.last_name === "") {
      return this.setState({
        error: { target: "last_name", msg: "Last name is required" },
      });
    }
    if (this.state.gender === null) {
      return this.setState({
        error: { target: "gender", msg: "Gender is required" },
      });
    }
    if (this.state.dob === "") {
      return this.setState({
        error: { target: "dob", msg: "Date of birth is required" },
      });
    }
    if (this.state.nid === "") {
      return this.setState({
        error: { target: "nid", msg: "NID is required" },
      });
    }
    if (this.state.phone_numbers === "") {
      return this.setState({
        error: { target: "phone_numbers", msg: "Phone number is required" },
      });
    }
    if (this.state.email === "") {
      return this.setState({
        error: { target: "email", msg: "Email is required" },
      });
    }
    if (this.state.username === "") {
      return this.setState({
        error: { target: "username", msg: "Username is required" },
      });
    }
    if (this.state.resident_district_id === null) {
      return this.setState({
        error: {
          target: "resident_district_id",
          msg: "Residence district is required",
        },
      });
    }

    this.setState({ loading: true });
    FC_RegisterUserToCohort(
      {
        email: this.state.email,
        first_name: this.state.first_name,
        full_name: `${this.state.first_name} ${
          this.state.middle_name !== "" ? `${this.state.middle_name} ` : ""
        }${this.state.last_name}`,
        gender: this.state.gender,
        last_name: this.state.last_name,
        middle_name: this.state.middle_name,
        nid: this.state.nid,
        phone_numbers: this.state.phone_numbers,
        resident_district_id: this.state.resident_district_id.district_code,
        role_id: this.props.role.role_id,
        staff_code: this.state.staff_code,
        username: this.state.username,
        addToTraining: this.props.addToTraining,
        cohortId:
          this.props.addToTraining === false
            ? null
            : this.props.cohort.cohortId,
        cohortconditionId:
          this.props.addToTraining === false
            ? null
            : this.props.cohortconditionId,
        school_code:
          this.props.addToTraining === false
            ? null
            : this.props.cohortSchoolCode,
        trainingId:
          this.props.addToTraining === false ? null : this.props.training_id,
        dob: this.state.dob,
      },
      (
        loading: boolean,
        res: {
          msg: string;
          type: "SUCCESS" | "ERROR";
          data: { user_id: string } | null;
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS" && res.data !== null) {
          this.setState({ success: res.msg, error: null, loading: false });
          this.props.onSuccess({
            user_id: res.data.user_id,
            info: {
              email: this.state.email,
              first_name: this.state.first_name,
              full_name: `${this.state.first_name}${
                this.state.middle_name !== ""
                  ? ` ${this.state.middle_name} `
                  : ""
              }${this.state.last_name}`,
              sex: this.state.gender,
              last_name: this.state.last_name,
              middle_name: this.state.middle_name,
              nid: this.state.nid,
              phone_numbers: this.state.phone_numbers,
              resident_district_id:
                this.state.resident_district_id !== null
                  ? this.state.resident_district_id.district_code
                  : "",
              role_id: this.props.role.role_id,
              staff_code: this.state.staff_code ?? "",
              username: this.state.username,
              archive: "0",
              archive_at: "",
              archive_by: "",
              bank_account: "",
              bank_id: "",
              cell_code: "",
              contract_type: "",
              created_at: "",
              created_by: "",
              disability: "false",
              disability_surpoting_document: "",
              district_code: "",
              dob: "",
              education_domain_id: 1,
              education_sub_dommain_id: 1,
              graduation_date: "",
              hired_date: "",
              marital_status: "",
              nationality_id: "",
              others: "",
              password_verication_code: "",
              photo_url: "",
              province_code: "",
              resident_district_name:
                this.state.resident_district_id !== null
                  ? this.state.resident_district_id.district_name
                  : "",
              resident_surpoting_document: "",
              rssb_number: "",
              school_code: "",
              school_name: "",
              sector_code: "",
              specialization_id: 1,
              staff_category_id: 1,
              status: "1",
              trainers_id: "",
              updated_at: "",
              updated_by: "",
              user_id: res.data.user_id,
              village: "",
              village_code: "",
              village_id: "",
              position_code: null,
              position_name: null,
              qualification_id: null,
              qualification_name: null,
            },
          });
        }
        if (res?.type === "ERROR") {
          this.setState({
            error: { target: "main", msg: res.msg },
            success: "",
            loading: false,
          });
        }
      }
    );
  };
  componentDidMount(): void {
    this.GetLocations();
  }
  render() {
    return (
      <div>
        <div className="pb-1 pt-2">
          <form
            onSubmit={this.onSubmitData}
            className="grid grid-cols-12 gap-3"
          >
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Input
                title={"First Name"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ first_name: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.first_name}
                error={
                  this.state.error?.target === "first_name"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Input
                title={"Middle Name (Optional)"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ middle_name: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.middle_name}
                error={""}
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6 lg:col-span-4">
              <Input
                title={"Last Name"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ last_name: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.last_name}
                error={
                  this.state.error?.target === "last_name"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <Select
                className="border-gray-300 bg-white font-bold"
                title={"Gender"}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  this.setState({ gender: e.target.value as GenderEnum })
                }
                disabled={this.state.loading}
                value={this.state.gender}
                error={
                  this.state.error?.target === "gender"
                    ? this.state.error.msg
                    : ""
                }
                data={[
                  {
                    value: GenderEnum.MALE,
                    caption: "Male",
                  },
                  {
                    value: GenderEnum.FEMALE,
                    caption: "Female",
                  },
                ]}
              />
            </div>
            <div className="col-span-12 md:col-span-3">
              <Input
                className="border-gray-300 bg-white font-bold"
                title={"Date of Birth"}
                onChange={(e) => this.setState({ dob: e.target.value })}
                disabled={this.state.loading}
                value={this.state.dob}
                error={
                  this.state.error?.target === "dob" ? this.state.error.msg : ""
                }
                type="date"
              />
            </div>

            <div className="col-span-12 md:col-span-6">
              <Input
                title={"NID"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ nid: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.nid}
                error={
                  this.state.error?.target === "nid" ? this.state.error.msg : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={"Phone Number"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ phone_numbers: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.phone_numbers}
                error={
                  this.state.error?.target === "phone_numbers"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={"Email"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ email: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.email}
                error={
                  this.state.error?.target === "email"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={"Staff Code"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ staff_code: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.staff_code ?? ""}
                error={""}
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6 relative">
              <div className="text-sm">Residence District</div>
              <div
                onClick={() =>
                  this.setState({
                    openChooseDistrict: !this.state.openChooseDistrict,
                  })
                }
                className="flex flex-row items-center justify-between gap-2 px-3 py-2 text-sm w-full border border-gray-300 rounded-md cursor-pointer hover:border-accent-600"
              >
                {this.state.resident_district_id === null ? (
                  <span></span>
                ) : (
                  <span className="font-bold">
                    {this.state.resident_district_id.district_name}
                  </span>
                )}
                <div className="">
                  <MdKeyboardArrowDown className="text-xl" />
                </div>
              </div>
              {this.state.error?.target === "resident_district_id" && (
                <div>
                  <Alert
                    alertType={AlertType.DANGER}
                    title={""}
                    description={this.state.error.msg}
                    close={() => this.setState({ error: null })}
                  />
                </div>
              )}
              {this.state.openChooseDistrict === true && (
                <div className="absolute left-0 right-0">
                  <SelectCustom
                    loading={this.state.loading}
                    id={"district_code"}
                    title={"district_name"}
                    close={() => this.setState({ openChooseDistrict: false })}
                    data={
                      this.state.locations === null
                        ? []
                        : this.state.locations.districts
                    }
                    click={(data: DistrictLocationItem) => {
                      this.setState({
                        resident_district_id: {
                          district_code: data.district_code,
                          district_name: data.district_name,
                        },
                        openChooseDistrict: false,
                      });
                    }}
                  />
                </div>
              )}
            </div>
            <div className="col-span-12 md:col-span-6">
              <Input
                title={"Username"}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ username: e.target.value, error: null })
                }
                disabled={this.state.loading}
                value={this.state.username}
                error={
                  this.state.error?.target === "username"
                    ? this.state.error.msg
                    : ""
                }
                type={"text"}
                className="border-gray-300 bg-white font-bold"
              />
            </div>
            <div className="col-span-12 md:col-span-6 pt-5">
              <button
                type="submit"
                disabled={this.state.loading}
                className="flex flex-row items-center text-sm justify-center gap-2 px-4 pr-6 py-2 w-max rounded-md bg-primary-700 hover:bg-primary-800 text-white cursor-pointer"
              >
                {this.state.loading === true ? (
                  <div>
                    <AiOutlineLoading3Quarters className="text-lg text-yellow-200 animate-spin" />
                  </div>
                ) : (
                  <div>
                    {this.props.currentData === null ? (
                      <IoPersonAddOutline className="text-xl" />
                    ) : (
                      <MdOutlineModeEdit className="text-xl" />
                    )}
                  </div>
                )}
                {this.state.loading === true ? (
                  <span className="animate__animated animate__fadeIn animate__infinite">
                    Saving changes...
                  </span>
                ) : (
                  <div>
                    {this.props.currentData === null ? (
                      <span>Update Details</span>
                    ) : (
                      <span>Update Details</span>
                    )}
                  </div>
                )}
              </button>
            </div>

            {this.state.error?.target === "main" && (
              <div className="col-span-12">
                <Alert
                  alertType={AlertType.DANGER}
                  title={this.state.error.msg}
                  close={() => this.setState({ error: null, success: "" })}
                />
              </div>
            )}
            {this.state.success && (
              <div className="col-span-12">
                <Alert
                  alertType={AlertType.SUCCESS}
                  title={this.state.success}
                  close={() => this.setState({ error: null, success: "" })}
                />
              </div>
            )}
          </form>
        </div>
      </div>
    );
  }
}
