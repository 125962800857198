import {
  CleanUserDetails,
  LoginSuccessDetails,
  LogoutUser,
  UpdateProfileAction,
} from "./auth.action";
import {
  GetAllPositionsAction,
  GetSchoolHierarchyAction,
  GetSystemRolesAction,
} from "./system.action";
import {
  GetAllTrainingProvidersAction,
  GetTrainingCategoriesAction,
} from "./training.action";

export enum ActionTypes {
  LOGIN_DETAILS = "LOGIN_DETAILS",
  USER_LOGIN_SUCCESS_DATA = "USER_LOGIN_SUCCESS_DATA",
  CLEAN_USER_DETAILS = "CLEAN_USER_DETAILS",
  LOGOUT = "LOGOUT",
  GET_SYSTEM_BASIC_INFO = "GET_SYSTEM_BASIC_INFO",
  SET_SYSTEM_ERROR_MESSAGE = "SET_SYSTEM_ERROR_MESSAGE",
  SET_SYSTEM_SUCCESS_MESSAGE = "SET_SYSTEM_SUCCESS_MESSAGE",
  GET_SYSTEM_ROLES = "GET_SYSTEM_ROLES",
  GET_SCHOOL_HIERARCHY = "GET_SCHOOL_HIERARCHY",
  GET_ALL_TRAINING_PROVIDERS = "GET_ALL_TRAINING_PROVIDERS",
  GET_TRAINING_CATEGORIES = "GET_TRAINING_CATEGORIES",
  GET_ALL_POSITIONS = "GET_ALL_POSITIONS",
  UPDATE_PROFILE = "UPDATE_PROFILE",
}

export type Action =
  | CleanUserDetails
  | LoginSuccessDetails
  | LogoutUser
  | GetSystemRolesAction
  | GetSchoolHierarchyAction
  | GetAllTrainingProvidersAction
  | GetTrainingCategoriesAction
  | GetAllPositionsAction
  | UpdateProfileAction;
