import React, { Component, Fragment } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  FC_GetReportDetailsForQRCode,
  ProgressReportInterface,
} from "../../actions";
import LoaderComponent from "../../components/Loading/LoaderComponent";
import { NoResultFound } from "../../components/Fragments/NoResultFound";
import { MdClose } from "react-icons/md";
import { GeneralReportTypeEnum } from "../../components/ElearningReport/ElearningReport";
import LOGOS from "../../assets/logos.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { isMobile } from "../../utils/functions";

interface RouteParams {
  staff_code: string;
  cohort_id: string;
}

interface TraineeCertificateVerificationProps
  extends RouteComponentProps<RouteParams> {}

interface TraineeCertificateVerificationState {
  loading: boolean;
  details: ProgressReportInterface[] | null;
  error: string;
}

export class TraineeCertificateVerification extends Component<
  TraineeCertificateVerificationProps,
  TraineeCertificateVerificationState
> {
  constructor(props: TraineeCertificateVerificationProps) {
    super(props);
    this.state = {
      loading: false,
      details: null,
      error: "",
    };
  }
  loadReport = () => {
    const { match } = this.props;
    const { staff_code, cohort_id } = match.params;
    this.setState({ loading: true });
    FC_GetReportDetailsForQRCode(
      staff_code,
      cohort_id,
      (
        loading: boolean,
        res: {
          msg: string;
          type: "SUCCESS" | "ERROR";
          data: ProgressReportInterface[];
        } | null
      ) => {
        this.setState({ loading: loading });
        if (res?.type === "SUCCESS") {
          this.setState({ details: res.data, loading: false, error: "" });
          // if (isMobile() === true) {
          //   this.downloadCertificatePDF(res.data);
          // }
        }
        if (res?.type === "ERROR") {
          this.setState({ error: res.msg, details: [], loading: false });
        }
      }
    );
  };
  getItemAverage = (data: ProgressReportInterface) =>
    (
      (parseFloat(data.courseNavigation) +
        parseFloat(data.endOfChapter) +
        parseFloat(data.selfAssesment) +
        parseFloat(
          (this.getTotalModuleMarks() / this.getModules().length).toFixed(1)
        ) +
        (this.state.details === null
          ? 0
          : this.state.details.length === 0
          ? 0
          : parseFloat(this.state.details[0].endOfCourse)) +
        this.getTeacherPractice(data)) /
      6
    ).toFixed(1);
  getAverageUserReport = () => {
    return parseFloat(
      (
        (this.state.details !== null && this.state.details.length > 0
          ? (parseFloat(this.state.details[0].endOfCourse) * 20) / 100
          : 0) +
        ((this.getTotalModuleMarks() / this.getModules().length) * 30) / 100 +
        ((this.getTotal(GeneralReportTypeEnum.endOfChapter) /
          (this.state.details ?? []).length) *
          10) /
          100 +
        +(
          ((this.getTotal(GeneralReportTypeEnum.courseNavigation) /
            (this.state.details ?? []).length) *
            20) /
          100
        ) +
        ((this.getTotal(GeneralReportTypeEnum.selfAssesment) /
          (this.state.details ?? []).length) *
          10) /
          100 +
        (parseFloat(this.getTotalTeacherPractice()) * 10) / 100
      ).toFixed(1)
    );
  };
  getTeacherPractice = (data: ProgressReportInterface) =>
    parseFloat(
      (
        (parseFloat(data.copMarks) +
          parseFloat(data.classroomApplication) +
          parseFloat(data.reflectionNotes) +
          parseFloat(data.selfStudy) +
          parseFloat(data.coaching)) /
        5
      ).toFixed(1)
    );
  getTotal = (type: GeneralReportTypeEnum) => {
    var total: number = 0;
    for (const item of this.state.details ?? []) {
      switch (type) {
        case GeneralReportTypeEnum.courseNavigation:
          total += parseFloat(item.courseNavigation);
          break;
        case GeneralReportTypeEnum.copMarks:
          total += parseFloat(item.copMarks);
          break;
        case GeneralReportTypeEnum.selfAssesment:
          total += parseFloat(item.selfAssesment);
          break;
        case GeneralReportTypeEnum.classroomApplication:
          total += parseFloat(item.classroomApplication);
          break;
        case GeneralReportTypeEnum.reflectionNotes:
          total += parseFloat(item.reflectionNotes);
          break;
        case GeneralReportTypeEnum.endOfChapter:
          total += parseFloat(item.endOfChapter);
          break;
        case GeneralReportTypeEnum.endOfModule:
          total += parseFloat(item.endOfModule);
          break;
        default:
          total += parseFloat(item.endOfCourse);
      }
    }
    return total;
  };

  getTotalTeacherPractice = () => {
    var total: number = 0;
    for (const item of this.state.details ?? []) {
      total += this.getTeacherPractice(item);
    }
    return (total / (this.state.details ?? []).length).toFixed(1);
  };
  getModules = () => {
    var moduleIds: { moduleId: string; moduleName: string }[] = [];
    for (const item of this.state.details ?? []) {
      if (
        moduleIds.find((itm) => item.moduleId === itm.moduleId) === undefined
      ) {
        moduleIds = [
          ...moduleIds,
          {
            moduleId: item.moduleId,
            moduleName: item.moduleName,
          },
        ];
      }
    }
    return moduleIds;
  };

  getModuleTotals = (moduleId: string) => {
    const selected = (this.state.details ?? []).find(
      (itm) => itm.moduleId === moduleId
    );
    if (selected !== undefined) {
      return parseFloat(selected.endOfModule);
    }
    return 0;
  };
  getTotalModuleMarks = () => {
    var total: number = 0;
    for (const item of this.getModules()) {
      total += this.getModuleTotals(item.moduleId);
    }
    return total;
  };
  componentDidMount(): void {
    this.loadReport();
  }

  downloadCertificatePDF = (data: ProgressReportInterface[]) => {
    const input = document.getElementById("pdf-content");
    if (input !== null) {
      // input.className = `${input === null ? "" : input.className} watermark`;
      html2canvas(input).then((canvas) => {
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, 210, 297); // A4 size
        pdf.save(
          `${
            data !== null && data.length > 0 ? data[0].traineeName : "Trainee"
          } Transcript.pdf`
        );
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="bg-white pb-10 rounded-b-xl rounded-t-none">
          <div
            className={`pt-2 px-2 md:pt-24 container mx-auto md:px-10 overflow-x-auto  flex items-center flex-col justify-center`}
          >
            <div
              id="pdf-content"
              className={`border rounded-lg p-2 md:p-6 ${
                this.state.details !== null && this.state.details.length > 0
                  ? "watermark"
                  : ""
              }`}
              style={{ width: isMobile() === true ? "100%" : "700px" }}
            >
              <div className="px-0 md:px-4 pb-0 md:pb-6">
                <img src={LOGOS} className="w-full" alt="LOGO" />
              </div>
              <div className="flex flex-col items-center justify-center mb-0 md:mb-7">
                <div className="font-bold text-3xl md:text-4xl text-center mt-6 mb-3 uppercase">
                  Transcript
                </div>
              </div>
              <div className="w-full">
                {this.state.loading === true || this.state.details === null ? (
                  <LoaderComponent />
                ) : this.state.details.length === 0 ? (
                  <div className="flex flex-col items-center justify-center gap-2 w-full">
                    <div>
                      <MdClose className="text-7xl text-yellow-600" />
                    </div>
                    <NoResultFound
                      title="Certificate not verified"
                      description="Please contact the admin because certificate is not verified"
                      className="w-full bg-gray-100"
                    />
                  </div>
                ) : (
                  <div>
                    <div className="bg-white rounded-md p-3 shadow-xl-custom text-black">
                      <div className="flex flex-row items-center justify-between gap-3">
                        <div className="mb-5">
                          {this.state.details.length > 0 && (
                            <>
                              <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>FULL NAME: </span>
                                <span className="font-bold">
                                  {this.state.details[0].traineeName}
                                </span>
                              </div>
                              {/* <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>GENDER: </span>
                                <span className="font-bold">
                                  {this.state.details[0].gender}
                                </span>
                              </div> */}
                              <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>SDMS CODE: </span>
                                <span className="font-bold">
                                  {this.state.details[0].staff_code}
                                </span>
                              </div>
                              <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>PROGRAM: </span>
                                <span className="font-bold">
                                  {
                                    "Blended Learning Continuous Professional Development"
                                  }
                                </span>
                              </div>
                              <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>COURSE NAME: </span>
                                <span className="font-bold">
                                  {"Literacy Acquisition in Lower Primary"}
                                </span>
                              </div>
                              <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>COURSE CODE: </span>
                                <span className="font-bold">
                                  LALP00{this.state.details[0].trainingId}
                                </span>
                              </div>
                              {/* <div className="font-light text-sm mb-2 flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>TRAINING CODE: </span>
                                <span className="font-bold">
                                  LALP00{this.state.details[0].trainingId}
                                </span>
                              </div> */}
                              <div className="font-light text-sm flex flex-col md:flex-row md:items-center md:gap-2">
                                <span>PERIOD: </span>
                                <span className="font-bold">
                                  {"December 2022 - December 2023"}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                        {/* <div className="mb-3 hidden md:block">
                          <MdVerified className="text-7xl text-green-500  animate__animated animate__bounceIn animate__faster" />
                        </div> */}
                      </div>
                      <div className="w-full">
                        <div className="w-full overflow-x-auto">
                          {/* <table
                            className="min-w-full text-xs text-left"
                            style={{ zIndex: 9 }}
                          >
                            <thead>
                              <tr className="text-xs">
                                <th className="border p-2 truncate">Course</th>
                                <th className="border p-2 truncate">Module</th>
                                <th className="border p-2 truncate">Chapter</th>
                                <th className="border p-2 truncate">
                                  End of Course
                                </th>
                                <th className="border p-2 truncate">
                                  End of Module
                                </th>
                                <th className="border p-2 truncate">
                                  End of Chapter
                                </th>
                                <th className="border p-2 truncate">
                                  Course Navigation
                                </th>
                                <th className="border p-2 truncate">
                                  Self Assessment
                                </th>
                                <th className="border p-2 truncate">
                                  Teacher Practice
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="bg-gray-50">
                                <td className="border p-2 truncate font-bold">
                                  {"Literacy Acquisition"}
                                </td>
                                <td colSpan={2} className="border p-2"></td>
                                <td className="border p-2 font-bold">
                                  {this.state.details.length > 0 &&
                                    this.state.details[0].endOfCourse}
                                </td>
                                <td colSpan={5} className="border p-2"></td>
                              </tr>
                              <tr>
                                <td
                                  rowSpan={
                                    (this.state.details ?? []).length +
                                    this.getModules().length * 2 +
                                    1
                                  }
                                  className="border p-2"
                                ></td>
                              </tr>
                              {this.getModules().map((module, m) => (
                                <>
                                  <tr>
                                    <td className="border p-2 text-primary-800">
                                      {module.moduleName}
                                    </td>
                                    <td colSpan={2} className="border p-2"></td>
                                    <td className="border p-2 text-primary-800">
                                      {this.getModuleTotals(module.moduleId)}
                                    </td>
                                    <td colSpan={4} className="border p-2"></td>
                                  </tr>
                                  <tr key={m + 1}>
                                    <td
                                      rowSpan={
                                        (this.state.details ?? []).filter(
                                          (itm) =>
                                            itm.moduleId === module.moduleId
                                        ).length + 1
                                      }
                                      className="border p-2 truncate"
                                    ></td>
                                  </tr>
                                  {(this.state.details ?? [])
                                    .filter(
                                      (itm) => itm.moduleId === module.moduleId
                                    )
                                    .map((item, i) => (
                                      <tr key={(i = 1)}>
                                        <td className="border p-2 truncate">
                                          {item.chapterName}
                                        </td>
                                        <td className="border p-2"></td>
                                        <td className="border p-2"></td>
                                        <td className="border p-2">
                                          {item.endOfChapter}
                                        </td>
                                        <td className="border p-2">
                                          {item.courseNavigation}
                                        </td>
                                        <td className="border p-2">
                                          {item.selfAssesment}
                                        </td>
                                        <td className="border p-2">
                                          {this.getTeacherPractice(item)}
                                        </td>
                                      </tr>
                                    ))}
                                </>
                              ))}

                              <tr className="font-bold">
                                <td
                                  colSpan={3}
                                  className="border p-2 font-bold"
                                >
                                  Total (%)
                                </td>
                                <td className="border p-2">
                                  {this.state.details !== null &&
                                    this.state.details.length > 0 &&
                                    this.state.details[0].endOfCourse}
                                </td>
                                <td className="border p-2">
                                  {" "}
                                  {(
                                    this.getTotalModuleMarks() /
                                    this.getModules().length
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    this.getTotal(
                                      GeneralReportTypeEnum.endOfChapter
                                    ) / (this.state.details ?? []).length
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    this.getTotal(
                                      GeneralReportTypeEnum.courseNavigation
                                    ) / (this.state.details ?? []).length
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    this.getTotal(
                                      GeneralReportTypeEnum.selfAssesment
                                    ) / (this.state.details ?? []).length
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {this.getTotalTeacherPractice()}
                                </td>
                              </tr>
                              <tr className="font-bold text-primary-800">
                                <td
                                  colSpan={3}
                                  className="border p-2 font-bold"
                                >
                                  Points
                                </td>
                                <td className="border p-2">
                                  {this.state.details !== null &&
                                    this.state.details.length > 0 &&
                                    (
                                      (parseFloat(
                                        this.state.details[0].endOfCourse
                                      ) *
                                        20) /
                                      100
                                    ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {" "}
                                  {(
                                    ((this.getTotalModuleMarks() /
                                      this.getModules().length) *
                                      30) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.endOfChapter
                                    ) /
                                      (this.state.details ?? []).length) *
                                      10) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.courseNavigation
                                    ) /
                                      (this.state.details ?? []).length) *
                                      20) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.selfAssesment
                                    ) /
                                      (this.state.details ?? []).length) *
                                      10) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">
                                  {(parseFloat(this.getTotalTeacherPractice()) *
                                    10) /
                                    100}
                                </td>
                              </tr>
                              <tr className="text-primary-800 bg-primary-50">
                                <td
                                  colSpan={8}
                                  className="border p-2 font-bold text-right text-sm"
                                >
                                  Total Marks
                                </td>
                                <td className="border p-2 font-bold text-sm">
                                  {this.getAverageUserReport()}%
                                </td>
                              </tr>
                            </tbody>
                          </table> */}
                          <table className="min-w-full text-sm text-left">
                            <thead>
                              <tr>
                                <th className="border p-2">Designation</th>
                                <th className="border p-2">Marks</th>
                                <th className="border p-2">Max</th>
                              </tr>
                            </thead>
                            <tbody className="">
                              <tr>
                                <td className="border p-2">
                                  Course Navigation
                                </td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.courseNavigation
                                    ) /
                                      (this.state.details ?? []).length) *
                                      20) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">20</td>
                              </tr>
                              <tr>
                                <td className="border p-2">End of Chapter</td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.endOfChapter
                                    ) /
                                      (this.state.details ?? []).length) *
                                      10) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">10</td>
                              </tr>
                              <tr>
                                <td className="border p-2">Self Assessment</td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotal(
                                      GeneralReportTypeEnum.selfAssesment
                                    ) /
                                      (this.state.details ?? []).length) *
                                      10) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">10</td>
                              </tr>
                              <tr>
                                <td className="border p-2">End of Module</td>
                                <td className="border p-2">
                                  {(
                                    ((this.getTotalModuleMarks() /
                                      this.getModules().length) *
                                      30) /
                                    100
                                  ).toFixed(1)}
                                </td>
                                <td className="border p-2">30</td>
                              </tr>
                              <tr>
                                <td className="border p-2">End of Course</td>
                                <td className="border p-2">
                                  {this.state.details !== null &&
                                    this.state.details.length > 0 &&
                                    (
                                      (parseFloat(
                                        this.state.details[0].endOfCourse
                                      ) *
                                        20) /
                                      100
                                    ).toFixed(1)}
                                </td>
                                <td className="border p-2">20</td>
                              </tr>
                              <tr>
                                <td className="border p-2">Teacher Practice</td>
                                <td className="border p-2">
                                  {(parseFloat(this.getTotalTeacherPractice()) *
                                    10) /
                                    100}
                                </td>
                                <td className="border p-2">10</td>
                              </tr>
                              <tr className="font-bold">
                                <td className="border p-2 text-right">
                                  Total Marks
                                </td>
                                <td colSpan={2} className="border p-2">
                                  {this.getAverageUserReport()}%
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="flex flex-row items-center gap-2 pt-16 italic text-sm">
                          <span>REMARKS:</span>
                          <span className="font-bold">
                            {this.getAverageUserReport() >= 60 &&
                              this.getAverageUserReport() < 70 &&
                              "Passed with Satisfaction"}
                            {this.getAverageUserReport() >= 70 &&
                              this.getAverageUserReport() <= 100 &&
                              "Passed with Distinction"}
                          </span>
                        </div>
                        <div className="pt-20 text-sm text-black pb-6">
                          This transcript is verified by the authority in
                          capacity, it should only be presented via scanning a
                          QrCode on the certificate.
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {this.state.details !== null && this.state.details.length > 0 && (
                <div className="text-xs flex justify-end gap-2">
                  <span>Transcript Number</span>
                  <span className="font-bold">
                    {this.state.details[0].cohortId}
                    {this.state.details[0].staff_code}
                  </span>
                </div>
              )}
            </div>
            {this.state.details !== null && (
              <div
                onClick={() =>
                  this.state.details !== null &&
                  this.downloadCertificatePDF(this.state.details)
                }
                className="bg-primary-700 text-white rounded-md text-sm cursor-pointer hover:bg-primary-800 px-5 py-2 w-max mt-4"
              >
                Download Transcript
              </div>
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
