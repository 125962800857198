import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { SiMicrosoftexcel } from "react-icons/si";

interface ExportToExcelProps {
  fileData: any[];
  fileName: string;
  btnName?: string;
  className?: string;
  showIcon?: boolean;
  iconClassName?: string;
}

const ExportToExcel: React.FC<ExportToExcelProps> = ({
  fileData,
  fileName,
  btnName = "Excel",
  className = "flex flex-row gap-2 items-center bg-white hover:bg-green-50 text-green-600 p-1 px-4 pl-2 rounded-md border border-green-500 hover:border-green-600",
  showIcon = true,
  iconClassName = "",
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData: any[], fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      onClick={(e) => exportToCSV(fileData, fileName)}
      className={className}
    >
      {showIcon && (
        <div>
          <SiMicrosoftexcel
            className={iconClassName ?? "text-xl"}
            style={{ marginTop: "4px", marginBottom: "4px" }}
          />
        </div>
      )}
      <div className="truncate text-sm">{btnName}</div>
    </button>
  );
};

export default React.memo(ExportToExcel);
