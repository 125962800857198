import React, { Component, Fragment } from "react";
import { Auth } from "../../actions";
import Modal, { ModalSize, Themes } from "../Modal/Modal";
import { IoWarningOutline } from "react-icons/io5";

interface NoRoleFoundProps {
  auth: Auth;
  onContinue: () => void;
}

interface NoRoleFoundState {}

export class NoRoleFound extends Component<NoRoleFoundProps, NoRoleFoundState> {
  render() {
    return (
      <Fragment>
        <Modal
          backDrop={true}
          theme={Themes.default}
          close={() => {}}
          backDropClose={false}
          widthSizeClass={ModalSize.large}
          displayClose={false}
          padding={{
            title: undefined,
            body: undefined,
            footer: undefined,
          }}
        >
          <div className="p-6 bg-yellow-200 flex flex-col items-center justify-center rounded-xl">
            <div>
              <IoWarningOutline className="text-9xl animate__animated animate__bounceIn animate__infinite animate__slow" />
            </div>
            <div className="text-center">
              <div className="text-3xl font-bold">No Access Found!</div>
              <div className="text-sm mt-2 md:px-10">
                Dear {this.props.auth.user?.user_info.full_name}, you do not
                have any access to this system. Please contact the administrator
                to grant you the access to the system
              </div>
            </div>
            <div
              onClick={this.props.onContinue}
              className="px-8 py-2 rounded-md flex flex-row items-center justify-center bg-white cursor-pointer hover:bg-yellow-100 mt-4"
            >
              Yes & Continue
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
