import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";

export interface BrandInterface {
    id: string,
    name: string,
    created_by?: string,
    created_at?: string,
    updated_by?: string | null,
    updated_at?: string | null,
    status?: string
}

export const FC_CreateBrand = async (
    name: string,
    callBack: (
      loading: boolean,
      res: {
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.post(`${API_URL}/brands`, {
        name: name,
      });
  
      callBack(false, {
        msg: `Updated ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_UpdateBrand = async (
    data:{
        name: string,
        status: string,
        id: string
    },
    callBack: (
      loading: boolean,
      res: {
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.put(`${API_URL}/brands/${data.id}`, {
        name: data.name,
        status: data.status,
      });
  
      callBack(false, {
        msg: `Updated ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        msg: error.message,
        type: "ERROR",
      });
    }
  };

  export const FC_GetAllBrands = async (
    callBack: (
      loading: boolean,
      res: {
        data: BrandInterface[];
        msg: string;
        type: "SUCCESS" | "ERROR";
      } | null
    ) => void
  ) => {
    callBack(true, null);
    setAxiosToken();
  
    try {
      const res = await axios.get(`${API_URL}/brands`);
  
      callBack(false, {
        data: res.data,
        msg: `Updated ${res.data}`,
        type: "SUCCESS",
      });
    } catch (error: any) {
      callBack(false, {
        data: [],
        msg: error.message,
        type: "ERROR",
      });
    }
  };