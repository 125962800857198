import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";
import { Dispatch } from "redux";
import { GetAllTrainingProvidersAction } from "./training.action";
import { ActionTypes } from "./types";
import { errorToText } from "../utils/functions";
import { TrainingProviderInterface } from "./auth.action";

export interface TrainingProvider {
  trainingProviderName: string;
  description: string;
  email: string;
  address: string;
  phone_number: string;
  supporting_documents: FileList;
}

export const FC_CreateTrainingProvider = async (
  data: TrainingProvider,
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/trainings/provider`, {
      data,
    });

    callBack(false, { msg: `Updated ${res.data}`, type: "SUCCESS" });
  } catch (error: any) {
    callBack(false, { msg: error.message, type: "ERROR" });
  }
};

export const FC_GetAllTrainingProviders = (
  callback: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  return async (dispatch: Dispatch) => {
    callback(true, null);
    setAxiosToken();
    try {
      const res = await axios.get<TrainingProviderInterface[]>(
        `${API_URL}/trainings/provider`
      );
      dispatch<GetAllTrainingProvidersAction>({
        type: ActionTypes.GET_ALL_TRAINING_PROVIDERS,
        payload: res.data,
      });
      console.log({ trainingProviders: res.data });
    } catch (error: any) {
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
      console.log("err: ", { ...error });
    }
  };
};
