import { Action, ActionTypes, TrainingStore } from "../actions";
// default state
const defaultState: TrainingStore = {
  trainingProviders: null,
  trainingCategories: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const trainingReducer = (
  state: TrainingStore = defaultState,
  action: Action
) => {
  switch (action.type) {
    case ActionTypes.GET_ALL_TRAINING_PROVIDERS:
      return {
        ...state,
        trainingProviders: action.payload,
      };
    case ActionTypes.GET_TRAINING_CATEGORIES:
      return {
        ...state,
        trainingCategories: action.payload,
      };
    default:
      return state;
  }
};
