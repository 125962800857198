import { Action, ActionTypes, System } from "../actions";
// default state
const defaultState: System = {
  side_nav: false,
  basic_info: null,
  access_details: null,
  error: "",
  success: "",
  roles: null,
  school_hierarchy: null,
  positions: null,
};

/**
 * this is the action
 * @param state
 * @param action
 * @returns
 */
export const systemReducer = (state: System = defaultState, action: Action) => {
  switch (action.type) {
    case ActionTypes.GET_SYSTEM_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case ActionTypes.GET_SCHOOL_HIERARCHY:
      return {
        ...state,
        school_hierarchy: action.payload,
      };
    case ActionTypes.GET_ALL_POSITIONS:
      return {
        ...state,
        positions: action.payload,
      };
    default:
      return state;
  }
};
