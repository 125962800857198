import axios from "axios";
import { Dispatch } from "redux";
import { ActionTypes } from "./types";
import { API_URL, DISTRICTS_LOCATION } from "../utils/api";
import { APP_TOKEN_NAME, setAxiosToken } from "../utils/AxiosToken";
import { errorToText } from "../utils/functions";
import { UserRoleInterface } from "./system.action";
import { UserAccessList } from "../config/userAccess";

/**
 * * ****************************** INTERFACES *****************************
 */

export enum BooleanEnum {
  TRUE = "TRUE",
  FALSE = "FALSE",
}

export enum UserActiveStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
}

export enum GenderEnum {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum MartialStatusEnum {
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
  DIVORCED = "DIVORCED",
}

export interface PermissionInterface {
  view: boolean;
  create: boolean;
  update: boolean;
  delete: boolean;
  export: boolean;
}

export interface DistrictLocationItem {
  district_code: string;
  district_name: string;
  province_id: string;
  province_code: string;
  _id: string;
}
export interface ProvinceLocationItem {
  province_code: string;
  province_name: string;
  _id: string;
}
export interface SectorLocationItem {
  district_id: string;
  sector_code: string;
  sector_name: string;
  district_code: string;
  _id: string;
}
export interface CountryLocationItem {
  country_code: string;
  country_name: string;
  code: string;
}
export interface LocationAPI {
  districts: DistrictLocationItem[];
  provinces: ProvinceLocationItem[];
  sectors: SectorLocationItem[];
  countries: CountryLocationItem[];
}

export interface SchoolInterface {
  school_id: string;
  school_code: string;
  school_name: string;
  school_category: string;
  school_status: string;
  region_code: string;
}

const token = localStorage.getItem(APP_TOKEN_NAME);

export interface UserItemInterface {
  staff_code: string;
  staff_category_id: string;
  full_name: string;
  sex: GenderEnum;
  dob: string | null;
  marital_status: MartialStatusEnum | null;
  nid: string;
  email: string;
  phone_numbers: string;
  rssb_number: string | null;
  education_domain_id: string | null;
  education_sub_dommain_id: string | null;
  graduation_date: string | null;
  highest_qualification_id: string;
  hired_date: string | null;
  contract_type: string | null;
  bank_account: string | null;
  nationality_id: string;
  province_code: string | null;
  district_code: string | null;
  sector_code: string | null;
  cell_code: string | null;
  village_id: string | null;
  village: string | null;
  school_code: string | null;
  school_name: string | null;
  user_id: string;
  username: string;
  bank_id: string | null;
  disability: boolean;
  resident_district_id: string | null;
  resident_district_name: string | null;
  disability_surpoting_document: string | null;
  resident_surpoting_document: string | null;
  photo_url: string | null;
  created_by: string | null;
  created_at: string;
  updated_by: string;
  updated_at: string;
  archive: "0" | "1";
  archive_by: string | null;
  archive_at: string | null;
  status: 1 | 0;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  specialization_id: string | null;
  village_code: string | null;
  others: string | null;
}

export interface TrainingProviderInterface {
  trainingProviderId: string;
  trainingProviderName: string;
  description: string;
  email: string;
  address: string;
  phone_number: string;
  TrainingProviderlogo: string;
  supporting_documents: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string | null;
  updatedDate: null;
  archive: string;
  archivedBy: null;
  archivedDate: null;
  user_to_trainingprovider_id: string;
  user_id: string;
  status: string;
}

export interface UserInterface {
  jwt: string;
  user_info: UserItemInterface;
  role: UserRoleInterface | undefined;
  country: CountryLocationItem | null;
  district: DistrictLocationItem | null;
  sector: SectorLocationItem | null;
  school: SchoolInterface | null;
  stakeholder: any | null;
  trainingProvider: TrainingProviderInterface;
}

export interface Auth {
  loading: boolean;
  isAuthenticated: boolean;
  token: string;
  user: UserInterface | null;
}

//* ********************** ACTION TYPE INTERCACES ********************** */
export interface FetchLoginDetails {
  type: ActionTypes.LOGIN_DETAILS;
  payload: Auth;
}

export interface LoginSuccessDetails {
  type: ActionTypes.USER_LOGIN_SUCCESS_DATA;
  payload: {
    data: UserInterface;
    token: string;
  };
}

export interface CleanUserDetails {
  type: ActionTypes.CLEAN_USER_DETAILS;
}

export interface LogoutUser {
  type: ActionTypes.LOGOUT;
}

export interface FetchLoginDetails {
  type: ActionTypes.LOGIN_DETAILS;
  payload: Auth;
}

/**
 * * ****************************** ACTIONS *****************************
 */

export const FC_CleanUserDetails = () => {
  return (dispatch: Dispatch) => {
    dispatch<CleanUserDetails>({
      type: ActionTypes.CLEAN_USER_DETAILS,
    });
  };
};

/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */
export const FC_Login = (
  data: {
    username: string;
    password: string;
  },
  CallbackFunc: Function
) => {
  return async (dispatch: Dispatch) => {
    try {
      const res = await axios.post<UserInterface>(
        `${API_URL}/user/account/login`,
        data
      );

      console.log({ data_after_login: res.data });
      console.log("Parsed: ", {
        data: res.data,
      });
      localStorage.setItem(APP_TOKEN_NAME, res.data.jwt);
      dispatch<LoginSuccessDetails>({
        type: ActionTypes.USER_LOGIN_SUCCESS_DATA,
        payload: {
          data: {
            country: res.data.country,
            district: res.data.district,
            jwt: res.data.jwt,
            role:
              res.data.role === undefined
                ? undefined
                : {
                    access: res.data.role.access
                      .toString()
                      .split(",") as UserAccessList[],
                    role_id: res.data.role?.role_id,
                    role: res.data.role?.role,
                  },
            school: res.data.school,
            sector: res.data.sector,
            stakeholder: res.data.stakeholder,
            user_info: res.data.user_info,
            trainingProvider: res.data.trainingProvider,
          },
          token: res.data.jwt,
        },
      });
      CallbackFunc(true, "");
    } catch (error: any) {
      CallbackFunc(false, errorToText(error));
    }
  };
};

/**
 * @description Check if the user is logged in based on the logged in account
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */

export const FC_CheckLoggedIn = (callBack: (status: boolean) => void) => {
  callBack(false);
  return async (dispatch: Dispatch) => {
    if (token === null) {
      dispatch<LogoutUser>({
        type: ActionTypes.LOGOUT,
      });
      callBack(true);
      return false;
    }
    try {
      setAxiosToken();
      const res = await axios.get<UserInterface>(
        `${API_URL}/user/current/info`
      );
      console.log({ logged_user_details: res.data });
      dispatch<LoginSuccessDetails>({
        type: ActionTypes.USER_LOGIN_SUCCESS_DATA,
        payload: {
          data: {
            country: res.data.country,
            district: res.data.district,
            jwt: res.data.jwt,
            role:
              res.data.role === undefined
                ? undefined
                : {
                    access: res.data.role.access
                      .toString()
                      .split(",") as UserAccessList[],
                    role_id: res.data.role?.role_id,
                    role: res.data.role?.role,
                  },
            school: res.data.school,
            sector: res.data.sector,
            stakeholder: res.data.stakeholder,
            user_info: res.data.user_info,
            trainingProvider: res.data.trainingProvider,
          },
          token: token!,
        },
      });
      callBack(true);
    } catch (error: any) {
      callBack(true);
      console.log("User not: ", { ...error });
      dispatch<LogoutUser>({
        type: ActionTypes.LOGOUT,
      });
    }
  };
};

/**
 * @description Logout the user into the system
 * @returns null
 */
export const FC_Logout = () => {
  return (dispatch: Dispatch) => {
    dispatch<LogoutUser>({
      type: ActionTypes.LOGOUT,
    });
  };
};

/**
 * @description Register the account to the api
 * @param account
 * @param MsgHandler return the error from the API
 * @returns
 */

export interface FC_ChangePassword_Interface {
  old_password: string;
  new_password: string;
}

export const FC_ChangePassword = async (
  data: FC_ChangePassword_Interface,
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  try {
    setAxiosToken();

    await axios.post(`${API_URL}/changepassword/new`, {
      current_password: data.old_password,
      new_password: data.new_password,
    });

    callback(false, {
      type: "SUCCESS",
      msg: "Password changed successfully!",
    });
  } catch (error) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

/**
 * Edit users documents
 * @param data
 * @param user_id
 * @param callback
 * @returns
 */

export interface FC_ForgetPassword_Interface {
  phone_number: string;
}
/**
 * Send the reset password
 * @param data
 * @param callback
 * @returns
 */
export const FC_ForgetPassword = async (
  data: FC_ForgetPassword_Interface,
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string; code: string } | null
  ) => void
) => {
  callback(true, null);
  try {
    setAxiosToken();
    const res = await axios.post(`${API_URL}/changepassword/forget`, data);

    console.log({ CODE: res.data });

    callback(false, {
      code: res.data.code,
      msg: "Verification code is sent to your phone mnumber",
      type: "SUCCESS",
    });
  } catch (error) {
    callback(false, {
      code: "",
      msg: errorToText(error),
      type: "ERROR",
    });
  }
};

export interface FC_ForgetPassword_Check_Interface {
  phone_number: string;
  verification_code: string;
  new_password: string;
}
/**
 * Send the new password and update that
 * @param data
 * @param callback
 * @returns
 */
export const FC_ForgetPassword_Check = async (
  data: FC_ForgetPassword_Check_Interface,
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  try {
    setAxiosToken();
    await axios.post(`${API_URL}/changepassword/verify`, data);
    callback(false, {
      msg: "Password reset successfully!",
      type: "SUCCESS",
    });
  } catch (error) {
    callback(false, {
      msg: errorToText(error),
      type: "ERROR",
    });
  }
};

export const FC_GetLocationsDetails = async (
  callBack: (
    loading: boolean,
    res: {
      data: LocationAPI | null;
      type: "Error" | "Success";
      msg: string;
    } | null
  ) => void
) => {
  // callBack(true, null);
  try {
    const res = await axios.get<LocationAPI>(`${DISTRICTS_LOCATION}`);
    callBack(false, {
      data: res.data,
      msg: "",
      type: "Success",
    });
  } catch (error: any) {
    console.log("Locations error: ", error);
    callBack(false, {
      data: {
        countries: [],
        districts: [],
        provinces: [],
        sectors: [],
      },
      msg: errorToText(error),
      type: "Error",
    });
  }
};

export const FC_ResetUserPassword = async (
  data: {
    focal_teacher_staff_code: string;
    teacher_staff_code: string;
    new_password: string;
  },
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  try {
    setAxiosToken();

    await axios.post(`${API_URL}/ictfocalteachers/resetTeacherPwd`, data);

    callback(false, {
      type: "SUCCESS",
      msg: "Password Reset successfully!",
    });
  } catch (error) {
    callback(false, {
      type: "ERROR",
      msg: errorToText(error),
    });
  }
};

export interface UpdateProfileAction {
  type: ActionTypes.UPDATE_PROFILE;
  payload: UpdateProfileData;
}

export interface UpdateProfileData {
  staff_code: string;
  full_name: string;
  sex: string | null;
  dob: string | null;
  marital_status: string | null;
  nid: string;
  email: string | null;
  phone_numbers: string;
  rssb_number: string | null;
  education_domain_id: string | null;
  education_sub_dommain_id: string | null;
  graduation_date: string | null;
  highest_qualification_id: string | null;
  hired_date: string;
  bank_account: string | null;
  province_code: string | null;
  district_code: string | null;
  sector_code: string | null;
  cell_code: string | null;
  village_id: string | null;
  village: string | null;
  school_code: string | null;
  school_name: string | null;
  user_id: string;
  username: string;
  bank_id: string | null;
  disability: boolean;
  resident_district_id: string;
  resident_district_name: string | null;
  disability_surpoting_document: string | null;
  resident_surpoting_document: string | null;
  photo_url: string | null;
  first_name: string;
  middle_name: string;
  last_name: string;
  village_code: string | null;
}

export const FC_UpdateProfileInfo = (
  data: UpdateProfileData,
  callback: (
    loading: boolean,
    res: { type: "SUCCESS" | "ERROR"; msg: string } | null
  ) => void
) => {
  callback(true, null);
  return async (dispatch: Dispatch) => {
    alert("Alert");
    setAxiosToken();
    try {
      await axios.patch(
        `${API_URL}/user/updateinfo/profile/${data.user_id}`,
        data
      );
      dispatch<UpdateProfileAction>({
        type: ActionTypes.UPDATE_PROFILE,
        payload: data,
      });
      callback(false, {
        type: "SUCCESS",
        msg: "Personal Info successfully!",
      });
    } catch (error) {
      callback(false, {
        type: "ERROR",
        msg: errorToText(error),
      });
    }
  };
};
