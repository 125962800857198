import { setAxiosToken } from "../utils/AxiosToken";
import axios from "axios";
import { API_URL } from "../utils/api";

export interface AttributeInterface {
  name: string;
  values: string[];
  required: boolean;
}

export interface CategoryInterface {
  assets_categories_id: number;
  assets_categories_name: string;
  created_by?: string;
  created_at?: string;
  updated_by?: string | null;
  updated_at?: string | null;
  status?: string | null;
  attributes?: AttributeInterface[];
}

export interface NewCategoryInterface {
  name: string;
  status: string;
  attributes: AttributeInterface[];
}

export interface SubCategoryInterface {
  name: string;
  assets_categories_id: number;
  created_by?: string;
  created_at?: string;
  updated_by?: string | null;
  updated_at?: string | null;
  status?: string | null;
  id: string;
}

export const FC_CreateCategory = async (
  newCategory: NewCategoryInterface,
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/assetscategory `, {
      assets_categories_name: newCategory.name,
      attributes: newCategory.attributes,
    });

    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_CreateSubCategory = async (
  name: string,
  assets_categories_id: string,
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.post(`${API_URL}/assetssubcategory `, {
      name: name,
      assets_categories_id: assets_categories_id,
    });

    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_UpdateCategory = async (
  data: {
    name: string;
    status: string;
    id: number;
  },
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.put(`${API_URL}/assetscategory/${data.id}`, {
      assets_categories_name: data.name,
      status: data.status,
    });

    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_UpdateSubCategory = async (
  data: {
    name: string;
    status: string;
    sub_categories_id: string;
    assets_categories_id: number;
  },
  callBack: (
    loading: boolean,
    res: {
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.put(
      `${API_URL}/assetssubcategory/${data.sub_categories_id}`,
      {
        name: data.name,
        assets_categories_id: data.assets_categories_id,
        status: data.status,
      }
    );

    callBack(false, {
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_GetAllCategories = async (
  callBack: (
    loading: boolean,
    res: {
      data: CategoryInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(`${API_URL}/assetscategory `);

    callBack(false, {
      data: res.data,
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      data: [],
      msg: error.message,
      type: "ERROR",
    });
  }
};

export const FC_GetAllSubCategories = async (
  category_id: number | string,
  callBack: (
    loading: boolean,
    res: {
      data: SubCategoryInterface[];
      msg: string;
      type: "SUCCESS" | "ERROR";
    } | null
  ) => void
) => {
  callBack(true, null);
  setAxiosToken();

  try {
    const res = await axios.get(`${API_URL}/assetssubcategory/${category_id} `);

    callBack(false, {
      data: res.data,
      msg: `Updated ${res.data}`,
      type: "SUCCESS",
    });
  } catch (error: any) {
    callBack(false, {
      data: [],
      msg: error.message,
      type: "ERROR",
    });
  }
};
